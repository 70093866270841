import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import {
  ChalkboardTeacher,
  ChatsCircle,
  Graph,
  NotePencil,
} from "phosphor-react";
import { useHistory } from "react-router-dom";
import { useState } from "react";

type RailwayItemProps = {
  height?: string;
  width?: string;
  heading: string;
  text: string;
  icon: "graph" | "chat" | "note" | "teacher" | "";
  hover?: string;
};

export default function RailwayItem(props: RailwayItemProps) {
  const history = useHistory();

  const goToIconPage = () => {
    if (props.icon === "graph") history.push("/services");
    else if (props.icon === "chat") history.push("/clarifychat");
    else if (props.icon === "note") history.push("/assignments");
    else if (props.icon === "teacher") history.push("/tutoring");
  };

  const [isHover, setIsHover] = useState(false);

  return (
    <Wrapper height={props.height} width={props.width}>
      <Icon
        onClick={goToIconPage}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {props.icon === "graph" ? (
          <Graph size={32} />
        ) : props.icon === "chat" ? (
          <ChatsCircle size={32} />
        ) : props.icon === "note" ? (
          <NotePencil size={32} />
        ) : props.icon === "teacher" ? (
          <ChalkboardTeacher size={32} />
        ) : (
          ""
        )}
      </Icon>
      <HeadingWrapper
        color={isHover && props.hover ? props.hover : ""}
        onClick={goToIconPage}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <Heading>{props.heading}</Heading>
      </HeadingWrapper>

      <Text>{props.text}</Text>
    </Wrapper>
  );
}

type WrapperProps = {
  height?: string;
  width?: string;
};

const Wrapper = styled.div`
  width: ${(props: WrapperProps) => (props.width ? props.width : "auto")};
  height: ${(props: WrapperProps) => (props.height ? props.height : "auto")};
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 5;
`;

const Icon = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: 300ms ease;
`;

type HeadingProps = {
  color: string;
};

const HeadingWrapper = styled.div`
  transition: 300ms ease;
  max-width: max-content;
  cursor: ${(props: HeadingProps) =>
    props.color !== "" ? "pointer" : "default"};
  color: ${(props: HeadingProps) => props.color};
`;

const Heading = styled(ReactMarkdown)`
  margin: 0;
  font-family: "OpenSans", sans-serif;
  font-size: 30px;
  & > p {
    margin: 0;
  }
`;

const Text = styled(ReactMarkdown)`
  margin: 0;
  margin-top: 25px;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  color: #000000;
  & > p {
    margin: 0;
  }
`;
