import styled from "styled-components";
import { ScreenHeading, ScreenSubText } from "../../utilities/ScreenText";
import dots from "../../../assets/images/shapes/pricing-shape.svg";

export default function PricingLanding() {
  return (
    <PricingLandingContainer>
      <Content>
        <ScreenHeading>PRICING</ScreenHeading>
      </Content>
      <Text>
        <ScreenSubText>
          Check out our prices for our range of services below.
        </ScreenSubText>
      </Text>
    </PricingLandingContainer>
  );
}

const PricingLandingContainer = styled.div`
  width: 100%;
  height: 80vh;
  background-color: #e1ecf9;
  min-height: 600px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px 100px;
  cursor: default;

  background-image: url(${dots});
  background-repeat: no-repeat;
  background-position: 200px 85%;

  @media (max-width: 1000px) {
    padding: 20px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px;
  }
  @media (max-width: 600px) {
    background-position: 100px 55%;
  }
  @media (max-width: 500px) {
    padding: 35px;
  }
`;

const Content = styled.div`
  padding-top: 150px;
  width: 80%;
  gap: 25px;

  @media (max-width: 800px) {
    width: 95%;
  }
  @media (max-width: 600px) {
    width: 460px;
  }
  @media (max-width: 500px) {
    width: 370px;
  }
  @media (max-width: 400px) {
    width: 310px;
  }
  @media (max-width: 350px) {
    width: 300px;
  }
`;

const Text = styled.div`
  position: absolute;
  width: 350px;
  right: 200px;
  top: 65%;

  & > ${ScreenSubText} {
    color: black;
  }
  @media (max-width: 1200px) {
    right: 100px;
  }
  @media (max-width: 1075px) {
    right: 200px;
    top: 94%;
  }
  @media (max-width: 900px) {
    right: 75px;
  }
  @media (max-width: 600px) {
    top: 79%;
  }
  @media (max-width: 500px) {
    right: auto;
    left: 50px;
    width: 300px;
  }
  @media (max-width: 370px) {
    width: 250px;
  }
`;
