import styled from "styled-components";

export const PopupContainer = styled.div`
  width: 80vw;
  height: 80vh;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 25px;
  box-shadow: 0 4px 20px 5px rgba(54, 110, 192, 0.25);
  display: flex;
`;
