export const userImages = [
  "https://images.unsplash.com/photo-1551085254-e96b210db58a", //tuncan
  "https://images.unsplash.com/photo-1497206365907-f5e630693df0", //flamingo
  "https://images.unsplash.com/photo-1518992028580-6d57bd80f2dd", //small bird
  "https://images.unsplash.com/photo-1462888210965-cdf193fb74de", //penguin
  "https://images.unsplash.com/photo-1570481662006-a3a1374699e8", //dolphin
  "https://images.unsplash.com/photo-1550089479-fe0e48e7d788", //yellow small bird
  "https://images.unsplash.com/photo-1574068468668-a05a11f871da", //blue small bird
  "https://images.unsplash.com/photo-1551946581-f7a62cd2f00b", //front lizard
  "https://images.unsplash.com/photo-1425082661705-1834bfd09dca", //hamster
  "https://images.unsplash.com/photo-1585110396000-c9ffd4e4b308", //rabbit
  "https://images.unsplash.com/photo-1545286796-2ec7f880a911", //side lizard
  "https://images.unsplash.com/photo-1589648751789-c8ecb7a88bd5", //polar bear
  "https://images.unsplash.com/photo-1546272989-40c92939c6c2", //chicks
  "https://images.unsplash.com/photo-1622879867898-587ab8c5b071", //duck
  "https://images.unsplash.com/photo-1470093851219-69951fcbb533", //wolf
  "https://images.unsplash.com/photo-1463436755683-3f805a9d1192", //fox
  "https://images.unsplash.com/photo-1565413294262-fa587c396965", //seal
  "https://images.unsplash.com/photo-1619961443292-16f133daa0f2", //guine pig
  "https://images.unsplash.com/photo-1622227056993-6e7f88420855", //pocupine
  "https://images.unsplash.com/photo-1502403421222-2ae1f0a65fe2", //frog
];

export const chooseUserImage = () => {
  const index = Math.floor(Math.random() * userImages.length);
  return userImages[index];
};
