import styled from "styled-components";

type ServicesInfoItemProps = {
  name: string;
  text: string;
};

export default function ServicesInfoItem(props: ServicesInfoItemProps) {
  return (
    <Container>
      <Dot />
      <Content>
        <Heading>{props.name}</Heading>
        <Text>{props.text}</Text>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  padding: 136px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  gap: 10px;
  cursor: default;

  @media (max-width: 1000px) {
    padding: 87px 0;
  }
  @media (max-width: 800px) {
    padding: 0 0 0 108px;
    flex-direction: row;
    width: 350px;
  }
  @media (max-width: 425px) {
    padding: 0 0 0 58px;
  }
  @media (max-width: 370px) {
    width: 300px;
  }
  @media (max-width: 320px) {
    width: 250px;
  }
`;

const Dot = styled.div`
  min-height: 30px;
  min-width: 30px;
  border-radius: 50%;
  background-color: #15375b;
  margin-bottom: 50px;

  @media (max-width: 1000px) {
    min-height: 15px;
    min-width: 15px;
    margin-bottom: 30px;
  }
  @media (max-width: 800px) {
    margin-bottom: 0;
    margin-right: 30px;
    min-height: 12px;
    min-width: 12px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (max-width: 800px) {
    align-items: flex-start;
  }
`;

const Heading = styled.p`
  margin: 0;
  font-family: "OpenSans", sans-serif;
  font-weight: bold;
  font-size: 30px;
  text-align: center;

  @media (max-width: 1000px) {
    font-size: 25px;
  }
`;

const Text = styled.p`
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  text-align: center;
  color: #245785;

  @media (max-width: 1000px) {
    font-size: 18px;
  }
  @media (max-width: 800px) {
    text-align: left;
  }
`;
