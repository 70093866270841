import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { RootState } from "../../../redux/store";
import Button from "../../utilities/button/Button";
import { ScreenSubText } from "../../utilities/ScreenText";

export default function ServicesSignUpFreeSection() {
  const userId = useSelector((state: RootState) => state.userData.uid);
  const history = useHistory();

  return (
    <Wrapper>
      <Content>
        <ScreenSubText>
          Kickstart your studies with Clarify today!
        </ScreenSubText>
        {userId === "-1" && (
          <Button
            name={"Sign Up For Free"}
            color={"#71BCFF"}
            hover={"#5fb3ff"}
            textColor="#FFF"
            width={"220px"}
            onClick={() => history.push("/signin")}
          />
        )}
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 100px 100px 100px;
  cursor: default;

  @media (max-width: 1000px) {
    padding: 20px 60px 100px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px 100px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px 35px 100px 35px;
  }
`;

const Content = styled.div`
  display: flex;
  width: 45%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;

  & > p {
    text-align: center;
  }
  @media (max-width: 900px) {
    width: 85%;
  }
  @media (max-width: 450px) {
    width: 100%;
    & > p {
      font-size: 18px;
    }
  }
`;
