import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { closeError } from "../../../redux/errorPopupSlice";
import { CloseButton } from "../../../components/utilities/button/CloseButton";
import closeIcon from "../../../assets/images/utilities/close.svg";

export default function ErrorPopup() {
  const dispatch = useDispatch();

  const errorPopupData = useSelector(
    (state: RootState) => state.errorPopupData
  );
  const open = errorPopupData.open;
  const message = errorPopupData.errorMessage;

  const close = () => {
    dispatch(closeError());
  };

  return (
    <DarkBackground
      style={{
        opacity: open ? 1 : 0,
        pointerEvents: open ? "initial" : "none",
      }}
    >
      <PageContainer style={{ top: open ? 0 : "100vh" }}>
        <ErrorPopupContainer>
          <Message>{message}</Message>
          <CloseButton onClick={close}>
            <img src={closeIcon} alt="Close Button" />
          </CloseButton>
        </ErrorPopupContainer>
      </PageContainer>
    </DarkBackground>
  );
}

const DarkBackground = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.3s ease;
`;

const PageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.3s cubic-bezier(0, 0, 0.39, 0.98);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorPopupContainer = styled.div`
  width: 400px;
  background-color: #fff;
  border-radius: 35px;
  box-shadow: 0 4px 20px 5px rgba(0, 0, 0, 0.25);
  display: flex;
  transition: opacity 200ms linear;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 70px 35px;

  @media (max-width: 550px) {
    width: 300px;
  }
`;

const Message = styled.p`
  font-size: 20px;
  font-family: "Cabin", sans-serif;
`;
