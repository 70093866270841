import styled from "styled-components";
import Product from "../../utilities/product/Product";
import { SellableProduct } from "../../../data/databaseTypes";
import { ScreenSubHeading, ScreenSubText } from "../../utilities/ScreenText";
import { useDispatch, useSelector } from "react-redux";
import { openError } from "../../../redux/errorPopupSlice";
import { RootState } from "../../../redux/store";
import { openPopup } from "../../../redux/assignmentUploadSlice";

type ProductSectionProps = {
  sellable: false | SellableProduct[];
};

export default function ProductSection(props: ProductSectionProps) {
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.userData.uid);
  return (
    <ProductSectionStyled>
      <ScreenSubHeading>Pricing</ScreenSubHeading>
      <ScreenSubText>
        Access our innovative assignment feedback and unique perks at a
        discounted price! All payments are securely processed through Stripe.
      </ScreenSubText>
      <ProductContainer>
        <Product
          product={props.sellable && props.sellable[0]}
          buttonColor={"#FFDDAA"}
          buttonHover={"#fbd7a0"}
          bgColor={"#FFF7EB"}
          minHeight="585px"
          onClick={
            userId === "-1"
              ? () =>
                  dispatch(
                    openError(
                      "You need to be signed into your Clarify account to be able to upload files. If you do not have an account you can make one by clicking on Log in on the top right"
                    )
                  )
              : () => dispatch(openPopup())
          }
        />
      </ProductContainer>
    </ProductSectionStyled>
  );
}

const ProductSectionStyled = styled.div`
  width: 100%;
  padding: 20px 100px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  cursor: default;

  @media (max-width: 1000px) {
    padding: 20px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px;
  }
`;

const ProductContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 200px;
  padding: 60px 100px;

  @media (max-width: 1150px) {
    gap: 150px;
  }
  @media (max-width: 1100px) {
    gap: 100px;
  }
  @media (max-width: 1050px) {
    gap: 75px;
    padding: 60px 50px;
  }
  @media (max-width: 500px) {
    padding: 60px 10px;
  }
  @media (max-width: 370px) {
    padding: 60px 0;
  }
`;
