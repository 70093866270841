import styled from "styled-components";
import dayjs from "dayjs";
import seenTick from "../../../assets/images/utilities/seenTick.svg";
import { isOnlyThreeEmojisOrLess } from "../../../scripts/emojiMethods";

type ChatMessageProps = {
  name: string;
  type: "sent" | "received";
  message: string;
  image: string;
  time: Date;
  firstInGroup: boolean;
  lastInGroup: boolean;
  seen: boolean;
};

export default function ChatMessage(props: ChatMessageProps) {
  const isLeft = props.type !== "sent";

  const image =
    props.image.includes("unsplash") && !props.image.includes("?")
      ? props.image + "?w=300"
      : props.image;

  return (
    <MessageContainer
      isLeft={isLeft}
      style={{ marginBottom: props.lastInGroup ? "15px" : 0 }}
    >
      {props.firstInGroup && (
        <MessageImage
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
      )}
      <MessageWrapper
        isLeft={isLeft}
        style={{
          paddingTop: props.firstInGroup ? "15px" : "5px",
          paddingBottom: props.lastInGroup ? "20px" : "5px",
          marginRight: isLeft ? "auto" : !props.firstInGroup ? "35px" : 0,
          marginLeft: isLeft ? (!props.firstInGroup ? "35px" : 0) : "auto",
        }}
      >
        {props.firstInGroup && (
          <MessageSubText
            style={{
              top: 0,
              color: "#3D5165",
              right: isLeft ? "auto" : "10px",
            }}
          >
            {props.name}
          </MessageSubText>
        )}
        <MessageBase
          isLeft={isLeft}
          emoji={isOnlyThreeEmojisOrLess(props.message)}
        >
          {props.message}
        </MessageBase>
        {props.lastInGroup && (
          <BottomWrapper isLeft={isLeft}>
            {props.type === "sent" && props.seen && (
              <p style={{ position: "relative" }}>
                <img
                  src={seenTick}
                  alt=""
                  style={{ position: "relative", top: "2px", right: "2px" }}
                />
                <b>Seen</b>
              </p>
            )}
            <p>
              {props.time.valueOf() > 0
                ? getDateText(props.time)
                : "Sending..."}
            </p>
          </BottomWrapper>
        )}
      </MessageWrapper>
    </MessageContainer>
  );
}

const getDateText = (date: Date): string => {
  const now = new Date();
  const daysAgo = dayjs(now).diff(date, "days");
  if (daysAgo === 0) return dayjs(date).format("h:mm A");
  else if (daysAgo === 1) return "Yesterday at " + dayjs(date).format("h:mm A");
  else if (daysAgo < 7)
    return dayjs(date).format("dddd") + ", " + dayjs(date).format("h:mm A");
  else return dayjs(date).format("DD/MM/YYYY");
};

type MessageContainerProps = {
  isLeft: boolean;
};

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: ${(props: MessageContainerProps) =>
    props.isLeft ? "row" : "row-reverse"};
`;

type MessageWrapperProps = {
  isLeft: boolean;
};

const MessageWrapper = styled.div`
  position: relative;
  padding: 15px 10px 20px 10px;
  margin: ${(props: MessageWrapperProps) =>
    props.isLeft ? "0 auto 0 0" : "0 0 0 auto"};
`;

const MessageImage = styled.div`
  height: 35px;
  width: 35px;
  border-radius: 100%;
  background-size: cover;
  background-position: center center;
`;

type MessageBaseProps = {
  isLeft: boolean;
  emoji?: boolean;
};

const MessageBase = styled.div`
  font-family: "Cabin", sans-serif;
  border-radius: 10px;
  padding: ${(props: MessageBaseProps) => (props.emoji ? "0" : "5px 20px")};
  font-size: ${(props: MessageBaseProps) => (props.emoji ? "40px" : "15px")};
  color: ${(props: MessageBaseProps) => (props.isLeft ? "#000000" : "#ffffff")};
  background-color: ${(props) =>
    props.emoji ? "transparent" : props.isLeft ? "#ffffff" : "#71BCFF"};
  box-shadow: ${(props: MessageBaseProps) =>
    props.emoji ? "none" : "0px 4px 4px rgba(0, 0, 0, 0.12)"};
  max-width: 20vw;
  letter-spacing: ${(props: MessageBaseProps) => (props.emoji ? "5px" : "")};
`;

type BottomWrapperProps = {
  isLeft: boolean;
};

const BottomWrapper = styled.div`
  min-width: 100%;
  display: flex;
  justify-content: ${(props: BottomWrapperProps) =>
    props.isLeft ? "flex-start" : "flex-end"};
  align-items: flex-start;
  padding: 0 7px;
  padding-top: 7px;
  position: absolute;
  right: ${(props: BottomWrapperProps) => (props.isLeft ? "auto" : "0")};
  left: ${(props: BottomWrapperProps) => (props.isLeft ? "0" : "auto")};
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  color: #888888;
  white-space: nowrap;
  flex-wrap: wrap-reverse;
  gap: 2px;
  & p {
    margin: 0;
    padding: 0 5px;
  }
`;

const MessageSubText = styled.p`
  position: absolute;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  color: #888888;
  padding: 0 7px;
  white-space: nowrap;
`;
