import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import styled from "styled-components";
import ProfileImage from "../../../utilities/ProfileImage";
import TutorChatBubble from "../../../utilities/chat_bubble/TutorChatBubble";
// import { useFirestore } from "reactfire";
// import { startDirectMessage } from "../../../../data/database";
// import { openChat, selectChatroom } from "../../../../redux/chatDataSlice";
// import { closePopup } from "../../../../redux/tutorProfilePopupSlice";
import { openError } from "../../../../redux/errorPopupSlice";

export default function SidePanel() {
  const tutorProfilePopupData = useSelector(
    (state: RootState) => state.tutorProfilePopupData
  );

  // const firestore = useFirestore();
  const dispatch = useDispatch();
  // const user = useSelector((state: RootState) => state.userData);

  const startChat = () => {
    dispatch(
      openError(
        "Thanks for your interest! Chatting to an individual tutor is not implemented yet. In the meantime, contact us on messenger through the messenger button on the bottom right"
      )
    );

    // startDirectMessage(firestore, user, tutorProfilePopupData.tutor)
    //   .then((chatroomId) => {
    //     dispatch(selectChatroom(chatroomId));
    //     dispatch(closePopup());
    //     dispatch(openChat());
    //   })
    //   .catch((err) => console.error("Error creating chatroom: ", err));
  };

  const unavailable = tutorProfilePopupData.tutor.availability === 0;

  return (
    <SidePanelstyled>
      <ProfileSection unavailable={unavailable}>
        <ProfileImage
          url={tutorProfilePopupData.tutor.profileImage}
          size={150}
        />
      </ProfileSection>

      <ChatButtonHolder>
        <div onClick={startChat}>
          <TutorChatBubble size={"50px"} />
        </div>
        <p>Chat with {tutorProfilePopupData.tutor.firstName}</p>
      </ChatButtonHolder>

      <MidSection>
        <CircleContainer>
          <Circle color={"#71BCFF"}>{tutorProfilePopupData.tutor.atar}</Circle>
          ATAR
        </CircleContainer>
        <CircleContainer>
          <Circle color={!unavailable ? "#ceefac" : "#FF8270"}>
            {tutorProfilePopupData.tutor.availability}
          </Circle>
          Availabilities
        </CircleContainer>
      </MidSection>
    </SidePanelstyled>
  );
}

const SidePanelstyled = styled.div`
  height: 100%;
  width: 275px;
  background-color: #f3f3f3;
  border-radius: 35px 0 0 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 5px;
  justify-content: center;
  overflow: scroll;
  gap: 20px;
  padding-top: 200px;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1100px) {
    width: 200px;
  }
  @media (max-width: 800px) {
    border-radius: 0;
  }
  @media (max-width: 550px) {
    min-height: 200px;
    height: 20%;
    width: 100%;
    flex-direction: row;
    padding: 0;
    background-color: #fff;
    padding: 30px 30px 0 30px;
  }
`;

type ProfileSectionProps = {
  unavailable: boolean;
};

const ProfileSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  color: #15375b;
  position: absolute;
  top: 35px;
  filter: ${(props: ProfileSectionProps) =>
    props.unavailable ? "grayscale(95%)" : ""};

  @media (max-width: 550px) {
    left: 20px;
  }
`;

const MidSection = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
  @media (max-width: 550px) {
    display: none;
  }
`;

const CircleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 86px;

  font-family: "Cabin", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #000000;
  user-select: none;
`;

type CircleProps = {
  color: string;
};

const Circle = styled.div`
  border-radius: 50%;
  background: ${(props: CircleProps) => props.color};
  box-shadow: 0px 0px 10px rgba(206, 239, 172, 0.25);
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Cabin", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #000000;
  user-select: none;
`;

const ChatButtonHolder = styled.div`
  display: flex;
  flex-direction: column;
  right: 0;
  top: 20%;
  align-items: center;
  visibility: hidden;

  @media (max-width: 800px) {
    visibility: visible;
  }

  @media (max-width: 550px) {
    position: absolute;
    right: 35px;
    top: 40%;
  }

  & > p {
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    margin-top: 5px;
    text-align: center;
    color: #15375b;
    user-select: none;
  }
`;
