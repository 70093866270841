import { RotateSpinner } from "react-spinners-kit";

type SpinnerProps = {
  loading: boolean;
  size?: number;
  color: string;
};

export default function Spinner(props: SpinnerProps) {
  return (
    <RotateSpinner
      size={props.size}
      loading={props.loading}
      color={props.color}
    />
  );
}
