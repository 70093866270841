import styled from "styled-components";

type ButtonProps = {
  name: string;
  color?: string;
  hover?: string;
  textColor?: string;
  width?: string;
  height?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  noShadow?: boolean;
  disabled?: boolean;
};

export default function Button(props: ButtonProps) {
  return (
    <ButtonStyle
      color={props.color} //color
      hover={!props.disabled ? props.hover : props.color} //hover if not disabled
      textColor={props.textColor}
      onClick={!props.disabled ? props.onClick : () => {}} //onClick if not disabled
      width={props.width}
      height={props.height}
      noShadow={props.disabled || props.noShadow} // no shadow if disabled or no shadow
      disabled={props.disabled}
      opacity={props.disabled ? 0.5 : 1}
    >
      {props.name}
    </ButtonStyle>
  );
}

type ButtonStyleProps = {
  color?: string;
  hover?: string;
  textColor?: string;
  width?: string;
  height?: string;
  noShadow?: boolean;
  disabled?: boolean;
  opacity: number;
};

const ButtonStyle = styled.button`
  background-color: ${(props: ButtonStyleProps) =>
    props.color ? props.color : "#FFF"};
  border: none;
  opacity: ${(props: ButtonStyleProps) => props.opacity};
  padding: 12px 20px;
  border-radius: 25px;
  font-family: "Cabin", sans-serif;
  font-size: 20px;
  outline: none;
  color: ${(props) => `${props.textColor ? props.textColor : "#FFF"}`};
  transition: 300ms ease;
  cursor: ${(props: ButtonStyleProps) =>
    !props.disabled ? "pointer" : "default"};
  pointer-events: all;

  box-shadow: ${(props: ButtonStyleProps) =>
    props.noShadow ? "" : "0px 0px 10px rgba(0, 0, 0, 0.25)"};
  width: ${(props: ButtonStyleProps) =>
    `${props.width ? props.width : "auto"}`};
  height: ${(props: ButtonStyleProps) =>
    `${props.height ? props.height : "auto"}`};
  user-select: none;

  &:hover {
    background-color: ${(props) => (props.hover ? props.hover : "#FFF")};
  }
`;
