import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserData, emptyUser } from "../data/databaseTypes";

const initialState: UserData = emptyUser;

export const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    removeUser: (state) => {
      state.uid = "-1";
      state.email = "";
      state.firstName = "";
      state.lastName = "";
      state.profileImage = "";
      state.joined = 0;
      state.type = "student";
    },
    addUser: (state, action: PayloadAction<UserData>) => {
      state.uid = action.payload.uid;
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.profileImage = action.payload.profileImage;
      state.joined = action.payload.joined;
      state.type = action.payload.type;
    },
  },
});

// Action creators are generated for each case reducer function
export const { removeUser, addUser } = userSlice.actions;

export default userSlice.reducer;
