import dayjs from "dayjs";
import { doc, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { useFirestore } from "reactfire";
import styled from "styled-components";
import AssignmentDownload from "./AssignmentDownload";

type AssignmentProps = {
  filePaths: string[];
  status: "unmarked" | "marked";
  uploaded: any;
  userId: string;
  id: string;
  name: string;
};

export default function Assignment(props: AssignmentProps) {
  const firestore = useFirestore();

  const tempdate = props.uploaded.toDate().valueOf();
  const dataUploaded = new Date(tempdate);

  const [marked, setMarked] = useState(props.status === "marked");

  const handleMarked = () => {
    const change = !marked;
    const assignmentDoc = doc(firestore, "assignments", props.id);
    updateDoc(assignmentDoc, {
      status: change ? "marked" : "unmarked",
    }).catch((error) => console.error(error));
    setMarked(change);
  };

  return (
    <AssignmentStyled key={props.userId + props.uploaded}>
      <form>
        <input
          type="checkbox"
          id={"marked"}
          checked={marked}
          onChange={handleMarked}
        />
        <label htmlFor="marked"> marked </label>
      </form>

      <p>Id: {props.id}</p>
      <p>userId: {props.userId}</p>
      <p>name: {props.name}</p>
      <p>status: {props.status}</p>
      <p>{dayjs(dataUploaded).format("D MMMM YYYY")}</p>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {props.filePaths.map((path) => (
          <AssignmentDownload key={path} filePath={path} />
        ))}
      </div>
    </AssignmentStyled>
  );
}

const AssignmentStyled = styled.div`
  border: solid black 1px;
  padding: 5px;
`;
