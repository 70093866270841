import styled from "styled-components";
import { CloseButton } from "../../../utilities/button/CloseButton";
import closeIcon from "../../../../assets/images/utilities/close.svg";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { closePopup } from "../../../../redux/tutorProfilePopupSlice";
import SidePanel from "./SidePanel";
import MainPanel from "./MainPanel";

export default function TutorProfilePopup() {
  const dispatch = useDispatch();

  const tutorProfilePopupData = useSelector(
    (state: RootState) => state.tutorProfilePopupData
  );

  const open = tutorProfilePopupData.open;

  const close = () => {
    dispatch(closePopup());
  };

  return (
    <DarkBackground
      style={{
        opacity: open ? 1 : 0,
        pointerEvents: open ? "initial" : "none",
      }}
    >
      <PageContainer style={{ top: open ? 0 : "100vw" }}>
        <TutorProfilePopupContainer>
          <SidePanel />
          <MainPanel />
          <CloseButton onClick={close}>
            <img src={closeIcon} alt="Close Button" />
          </CloseButton>
        </TutorProfilePopupContainer>
      </PageContainer>
    </DarkBackground>
  );
}

const DarkBackground = styled.div`
  position: fixed;
  z-index: 100;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.3s ease;

  @media (max-width: 800px) {
    background-color: transparent;
  }
`;

const PageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.3s cubic-bezier(0, 0, 0.39, 0.98);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TutorProfilePopupContainer = styled.div`
  max-width: 1100px;
  width: 70%;
  min-width: 750px;
  height: 80%;
  max-height: 700px;
  background-color: #fff;
  border-radius: 35px;
  box-shadow: 0 4px 20px 5px rgba(0, 0, 0, 0.25);
  display: flex;
  transition: opacity 200ms linear;
  align-items: center;
  position: relative;
  overflow: scroll;
  cursor: default;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 800px) {
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    min-width: 0;
    border-radius: 0;
  }

  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
