import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Button from "../utilities/button/Button";
import Logo from "../utilities/Logo";

type AdminNavProps = {
  page: string;
};

export default function AdminNav(props: AdminNavProps) {
  const history = useHistory();
  return (
    <NavContainer>
      <div
        style={{
          zIndex: "3",
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Logo />
        <p>{props.page} Panel</p>
      </div>
      <Button
        name={"Back to Home"}
        textColor="black"
        onClick={() => {
          history.replace("/");
        }}
      />
    </NavContainer>
  );
}

const NavContainer = styled.div`
  width: 100%;
  height: 100px;
  border-bottom: solid black 2px;
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 20px;
  justify-content: space-between;

  & > p {
    margin: 0;
    font-size: 20px;
  }
`;
