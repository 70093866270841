import styled from "styled-components";
import { TutorPayData, UserData } from "../../../../data/databaseTypes";
import AdminTutorPayItem from "./AdminTutorPayItem";

type AdminTutorPayProps = {
  section: "pending" | "success" | "invoicing";
  payRequests: TutorPayData[];
  employees: UserData[];
};

export default function AdminTutorPay(props: AdminTutorPayProps) {
  const filteredRequests =
    props.payRequests &&
    props.payRequests.filter((request) => request.status === props.section);

    const sortPayRequests = (
      payRequestA: TutorPayData,
      payRequestB: TutorPayData
    ) => payRequestB.date - payRequestA.date;
  
    const sortedRequests =
    filteredRequests && filteredRequests.sort(sortPayRequests);
    

  return (
    <Section>
      <b>{props.section} requests</b>
      <PayItemHolder>
        {sortedRequests &&
          sortedRequests.map((request) => (
            <AdminTutorPayItem
              payRequest={request}
              employees={props.employees}
              key={request.id}
            ></AdminTutorPayItem>
          ))}
      </PayItemHolder>
    </Section>
  );
}

const Section = styled.div`
  width: 100%;
  padding: 20px;
`;

const PayItemHolder = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px;
`;
