import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { openPopup } from "../../../redux/assignmentUploadSlice";
import { openError } from "../../../redux/errorPopupSlice";
import { RootState } from "../../../redux/store";
import Button from "../../utilities/button/Button";
import Dot from "../../utilities/Dot";
import { ScreenHeading, ScreenSubText } from "../../utilities/ScreenText";

export default function ProductLanding() {
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.userData.uid);
  return (
    <ProductLandingContainer>
      <Content>
        <span>
          <ScreenHeading>
            Assignment{" "}
            <span style={{ whiteSpace: "nowrap" }}>
              Feedback
              <Dot color={"#71BCFF"} />
            </span>
          </ScreenHeading>
        </span>
        <ScreenSubText>
          Shine the brightest amongst your peers. Whether you need your work
          shaped, buffed or polished, we're here to provide the{" "}
          <b> perfect touch-up </b>
          you'll need.
        </ScreenSubText>
        <div style={{ position: "relative", top: "25px" }}>
          <Button
            name={"Upload now!"}
            color={"#FFF7EB"}
            hover={"#fbf0de"}
            width={"180px"}
            textColor="black"
            onClick={
              userId === "-1"
                ? () =>
                    dispatch(
                      openError(
                        "You need to be signed into your Clarify account to be able to upload files. If you do not have an account you can make one by clicking on Log in on the top right"
                      )
                    )
                : () => dispatch(openPopup())
            }
          />
        </div>
      </Content>
    </ProductLandingContainer>
  );
}

const ProductLandingContainer = styled.div`
  width: 100%;
  height: 80vh;
  position: relative;
  min-height: 600px;
  display: flex;
  align-items: center;
  padding: 20px 100px;
  cursor: default;

  @media (max-width: 1000px) {
    padding: 20px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px;
  }
`;

const Content = styled.div`
  padding-top: 150px;
  width: 80%;
  gap: 25px;

  @media (max-width: 800px) {
    width: 95%;
  }
  @media (max-width: 600px) {
    width: 460px;
  }
  @media (max-width: 500px) {
    width: 370px;
  }
  @media (max-width: 400px) {
    width: 310px;
  }
  @media (max-width: 350px) {
    width: 300px;
  }
`;
