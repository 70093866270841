import dayjs from "dayjs";
import styled from "styled-components";

type UserComponentProps = {
  firstName: string;
  lastName: string;
  joined: any;
  email: string;
  uid: string;
};

export default function UserComponent(props: UserComponentProps) {
  const tempdate = props.joined.toDate().valueOf();
  const dateJoined = new Date(tempdate);
  return (
    <User>
      <p>userId: {props.uid}</p>
      <p>
        {props.firstName} {props.lastName}
      </p>
      <p>{props.email}</p>
      <p>{dayjs(dateJoined).format("D MMMM YYYY")}</p>
    </User>
  );
}

const User = styled.div`
  border: solid black 1px;
  padding: 5px;
  display: flex;
  flex-direction: column;

  & > p {
    margin: 0;
  }
`;
