import { configureStore } from "@reduxjs/toolkit";
import userDataReducer from "./userDataSlice";
import chatDataReducer from "./chatDataSlice";
import accountPopupReducer from "./accountPopupSlice";
import tutorProfilePopupReducer from "./tutorProfilePopupSlice";
import errorPopupReducer from "./errorPopupSlice";
import assignmentUploadReducer from "./assignmentUploadSlice";

export const store = configureStore({
  reducer: {
    userData: userDataReducer,
    chatData: chatDataReducer,
    accountPopupData: accountPopupReducer,
    tutorProfilePopupData: tutorProfilePopupReducer,
    errorPopupData: errorPopupReducer,
    assignmentUploadData: assignmentUploadReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
