import styled from "styled-components";
import { ScreenSubHeading } from "../../../utilities/ScreenText";
import ProductHowToItem from "./ProductHowToItem";

export default function ProductHowToSection() {
  return (
    <ProductHowToSectionStyled>
      <ScreenSubHeading style={{ marginBottom: "50px" }}>
        How to Access our Products: 4 Easy Steps
      </ScreenSubHeading>
      <ProductHowToItem
        heading={"Step 1: Consult"}
        text={
          "Feel free to look through our sample feedback to see if we are the right fit for you. If you have any queries, feel free to chat with us by using the messenger icon on the bottom right. Once you've decided we're the right fit for you, contact us via the messenger icon, and we'll confirm if we can process your request."
        }
      />
      <ProductHowToItem
        heading={"Step 2: Upload"}
        text={
          "Once you've contacted us about getting your assignment marked, and your request has been approved by us, feel free to upload all relevant files by using the upload button below."
        }
      />
      <ProductHowToItem
        heading={"Step 3: Pay"}
        text={
          "After we receive your documents, we will send you an invoice for the assignment marking, through which the payment is processed securely via Stripe. You will receive comprehensive feedback and a marked assignment within 3 days of the payment. If you would like to fast-track this process, please discuss this with our tutors via the messenger icon on the bottom right."
        }
      />
      <ProductHowToItem
        heading={"Step 4: Chat"}
        text={
          "If you would like, you may request a FREE 10-minute video consultation with a tutor to go through the feedback and marks they gave."
        }
      />
    </ProductHowToSectionStyled>
  );
}

const ProductHowToSectionStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 100px 100px 100px;
  gap: 40px;
  cursor: default;

  @media (max-width: 1000px) {
    padding: 20px 60px 100px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px 100px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px 35px 100px 35px;
  }
`;
