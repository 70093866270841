import { useDispatch } from "react-redux";
import styled from "styled-components";
import { ChatroomData, UserData } from "../../../data/databaseTypes";
import { closeEmoji, selectChatroom } from "../../../redux/chatDataSlice";

type ChatUserButtonProps = {
  index: number;
  chatroom: ChatroomData;
  isSelected: boolean;
  user: UserData;
  unreadMessages: number;
};

export default function ChatUserButton(props: ChatUserButtonProps) {
  const dispatch = useDispatch();

  let subtext = props.chatroom.userNames.join(", ");
  if (props.chatroom.type === "direct") subtext = "Direct Message";

  // Find the name of the chatroom
  let chatroomName = props.chatroom.name;
  if (props.chatroom.type === "direct") {
    const otherName = props.chatroom.userNames.find(
      (name, index) => props.chatroom.userIds[index] !== props.user.uid
    );
    if (otherName) chatroomName = otherName;
  }

  // Find Image for chat
  let chatImage =
    "https://images.unsplash.com/photo-1599508704512-2f19efd1e35f?w=300";
  if (props.chatroom.userImages.length > 0) {
    const otherImage = props.chatroom.userImages.find(
      (image, index) => props.chatroom.userIds[index] !== props.user.uid
    );

    if (otherImage) chatImage = otherImage;
    chatImage = chatImage.includes("unsplash")
      ? chatImage + "?w=300"
      : chatImage;
  }

  const chooseChatroom = () => {
    dispatch(closeEmoji());
    dispatch(selectChatroom(props.chatroom.id));
  };

  return (
    <UserButton isSelected={props.isSelected} onClick={chooseChatroom}>
      <Row>
        <div
          style={{
            backgroundImage: `url(${chatImage})`,
            backgroundSize: "cover",
            width: 50,
            height: 50,
            borderRadius: "100%",
            backgroundPosition: "center",
          }}
        ></div>
        <Column style={{ alignItems: "flex-start", padding: "0 0 0 15px" }}>
          <TextMd>{chatroomName}</TextMd>
          <TextSm>{subtext}</TextSm>
        </Column>
      </Row>
      {props.unreadMessages > 0 && (
        <MessageUnreadDot>
          {props.unreadMessages > 9 ? "9+" : props.unreadMessages}
        </MessageUnreadDot>
      )}
    </UserButton>
  );
}

type UserButtonProps = {
  isSelected: boolean;
};

const UserButton = styled.div`
  width: 100%;
  border-radius: 15px;
  padding: 15px 20px;
  margin: 10px 0;
  position: relative;
  cursor: pointer;
  background-color: ${(props: UserButtonProps) =>
    props.isSelected ? "#E9E9E9" : "#F5F5F5"};
  transition: 0.2s ease;
  &:hover {
    background-color: ${(props: UserButtonProps) =>
      props.isSelected ? "#e0e0e0" : "#eeeeee"};
  }
`;

const MessageUnreadDot = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: #e18282;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextMd = styled.p`
  font-size: 24px;
  margin: 0;
  color: #3d5165;
`;

const TextSm = styled.p`
  font-size: 14px;
  margin: 0;
  color: 3d5165;
  font-weight: 400;
`;
