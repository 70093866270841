import dayjs from "dayjs";
import { doc, updateDoc } from "firebase/firestore";
import { useFirestore } from "reactfire";
import styled from "styled-components";
import { TutorPayData, UserData } from "../../../../data/databaseTypes";

type AdminTutorPayItemProps = {
  payRequest: TutorPayData;
  employees: UserData[];
};

export default function AdminTutorPayItem(props: AdminTutorPayItemProps) {
  const date = new Date(props.payRequest.date.toDate().valueOf());

  const firestore = useFirestore();
  const payRequestDoc = doc(firestore, "tutor_pay", props.payRequest.id);

  const invoiceRequest = () => {
    updateDoc(payRequestDoc, { status: "invoicing" });
  };

  const successRequest = () => {
    updateDoc(payRequestDoc, { status: "success" });
  };

  return (
    <RequestItem>
      <p>{props.payRequest.status}</p>
      <p>
        {props.employees && props.employees.find((e) => e.uid === props.payRequest.uid)?.firstName}
      </p>
      <p>{props.payRequest.studentName}</p>
      <p>{props.payRequest.studentEmail}</p>
      <p>{dayjs(date).format("D MMMM YYYY")}</p>
      <p>
        {props.payRequest.duration.hours > 1
          ? `${props.payRequest.duration.hours}hrs`
          : `${props.payRequest.duration.hours}hr`}{" "}
        {props.payRequest.duration.minutes
          ? `${props.payRequest.duration.minutes}mins`
          : ""}
      </p>
      <p>{props.payRequest.notes ? props.payRequest.notes : "no notes"}</p>
      {props.payRequest.status === "pending" ? (
        <button onClick={invoiceRequest}>Invoice sent</button>
      ) : props.payRequest.status === "invoicing" ? (
        <button onClick={successRequest}>Sent Tutor Money</button>
      ) : (
        ""
      )}
    </RequestItem>
  );
}

const RequestItem = styled.div`
  display: flex;
  flex-direction: column;
  border: solid black 1px;
  gap: 2px;
  padding: 5px;
`;
