import { Link } from "react-router-dom";
import styled from "styled-components";

type NavBarDropDownprops = {
  shown: boolean;
};

export default function NavBarDropDown(props: NavBarDropDownprops) {
  return (
    <DropDownContainer shown={props.shown}>
      <StyledLink to="/clarifychat">Clarify Chat</StyledLink>
      <StyledLink to="/tutoring">Tutoring</StyledLink>
      <StyledLink to="/assignments">Assignments</StyledLink>
    </DropDownContainer>
  );
}

type DropDownContainerProps = {
  shown: boolean;
};

const DropDownContainer = styled.div`
  pointer-events: all;
  position: absolute;
  visibility: ${(props: DropDownContainerProps) =>
    props.shown ? "visible" : "hidden"};
  opacity: ${(props: DropDownContainerProps) => (props.shown ? "1" : "0")};
  transition: opacity 300ms ease;
  overflow: hidden;

  left: -32%;
  top: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 25px;
  width: 180px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
`;

const StyledLink = styled(Link)`
  color: #272725b3;
  font-family: "Raleway", sans-serif;
  font-size: 22px;
  text-decoration: none;

  margin: 0;
  user-select: none;
  width: 100%;
  padding: 7px 25px;
  transition: background-color 100ms ease;

  &:hover {
    background-color: lightgrey;
  }
  &:first-child {
    height: 50px;
    padding: 17px 25px 7px 25px;
  }
  &:last-child {
    height: 50px;
  }
`;
