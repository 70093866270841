import styled from "styled-components";
import { ReactComponent as ClarifyLogo } from "../../assets/images/clarify/logo.svg";
import clarifyName from "../../assets/images/clarify/clarify_text_grey.svg";

type LogoProps = {
  isText?: boolean;
  direction?: "bottom" | "right";
};

export default function Logo(props: LogoProps) {
  return (
    <LogoContainer>
      <LogoImage width={props.isText ? "120px" : "70px"} />
      <LogoText hide={!props.isText}>
        <img src={clarifyName} alt="Clarify" />
      </LogoText>
    </LogoContainer>
  );
}

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  user-select: none;
`;

const LogoImage = styled(ClarifyLogo)`
  height: 100px;
  transition: 300ms linear;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.175));
  z-index: -1;
`;

interface LogoTextProps {
  hide: boolean;
}

const LogoText = styled.div`
  margin: 0;
  position: absolute;
  bottom: -15px;
  transition: 100ms linear;
  visibility: ${(props: LogoTextProps) => (props.hide ? "hidden" : "initial")};
  opacity: ${(props: LogoTextProps) => (props.hide ? 0 : 1)};

  & img {
    width: 120px;
  }
  @media (max-width: 700px) {
    display: none;
  }
`;
