import styled from "styled-components";
import React, { useState } from "react";
import { useHistory } from "react-router";

import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";

import { useFirestore, useUser } from "reactfire";
import { setDoc, doc, serverTimestamp, getDoc } from "firebase/firestore";

import { UserData } from "../../../data/databaseTypes";
import { chooseUserImage } from "../../../data/userImages";

import logo from "../../../assets/images/clarify/logo_with_text.svg";
import Textbox from "../../utilities/textbox/Textbox";
import Dropdown from "../../utilities/Dropdown";
import SecondaryButton from "../../utilities/button/SecondaryButton";
import WelcomeSection from "./WelcomeSection";
import BackButton from "../../utilities/button/BackButton";
import { useDispatch } from "react-redux";
import { addUser } from "../../../redux/userDataSlice";
import { isValidEmail } from "../../../scripts/isValidEmail";
import { errorCodeToText } from "../../../scripts/errorCodeToText";

type LoginProps = {
  openResetPassword: () => void;
};

export default function Login(props: LoginProps) {
  const history = useHistory();
  const auth = getAuth();
  const user = useUser();
  const firestore = useFirestore();
  const dispatch = useDispatch();

  // Sign-in Data
  type SignInData = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    type: "student" | "parent";
  };
  const [signInData, setSignInData] = useState<SignInData>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    type: "student",
  });
  type signInField =
    | "firstName"
    | "lastName"
    | "email"
    | "password"
    | "confirmPassword";

  type userType = "student" | "parent";

  // UI Data
  const [tabIndex, setTabIndex] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const selected = {
    color: "#15375B",
  };

  //UI events
  const handleChange = (key: signInField, value: string) => {
    setSignInData((oldData) => {
      const newData: SignInData = { ...oldData };
      newData[key] = value;
      return newData;
    });
  };

  const handleType = (value: userType) => {
    setSignInData((oldData) => {
      const newData: SignInData = { ...oldData };
      newData["type"] = value;
      return newData;
    });
  };

  // Validity Checks
  const [checkingForValidity, setCheckingForValidity] = useState(false);
  const loginValidCheck = () => {
    return (
      signInData.email && signInData.password && isValidEmail(signInData.email)
    );
  };

  const signupValidCheck = () => {
    return (
      signInData.firstName &&
      signInData.lastName &&
      signInData.email &&
      signInData.password &&
      signInData.confirmPassword &&
      signInData.type &&
      isValidEmail(signInData.email) &&
      signInData.password === signInData.confirmPassword
    );
  };

  //firebase methods
  const login = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setCheckingForValidity(true);

    if (loginValidCheck()) {
      setCheckingForValidity(false);
      setIsLoading(true);
      signInWithEmailAndPassword(auth, signInData.email, signInData.password)
        .then((data) => {
          getDoc(doc(firestore, "users", data.user.uid))
            .then((user) => {
              if (user.exists()) {
                const tempUserData = user.data() as UserData;
                tempUserData.joined = tempUserData.joined.toDate().valueOf();
                dispatch(addUser(tempUserData));
              }
            })
            .catch((error) => console.log(error));
          if (user) {
            setIsLoading(false);
            history.push("/");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setError(error.code);
        });
    }
  };

  const signUp = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setCheckingForValidity(true);

    if (signupValidCheck()) {
      setCheckingForValidity(false);
      setIsLoading(true);
      createUserWithEmailAndPassword(
        auth,
        signInData.email,
        signInData.password
      )
        .then((data) => {
          if (data.user) {
            const newUserData: UserData = {
              uid: data.user.uid,
              email: signInData.email,
              firstName: signInData.firstName,
              lastName: signInData.lastName,
              profileImage: chooseUserImage(),
              joined: serverTimestamp(),
              type: signInData.type,
            };
            const usersRef = doc(firestore, "users", data.user.uid);
            setDoc(usersRef, newUserData)
              .then(() => {
                history.push("/");
              })
              .catch((error) => {
                setIsLoading(false);
                console.log(error);
              });
          }
          if (auth.currentUser) {
            updateProfile(auth.currentUser, {
              displayName: `${signInData.firstName} ${signInData.lastName}`,
              photoURL: "",
            }).catch((error) => {
              console.log(error);
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setError(error.code);
        });
    }
  };

  return (
    <SignInHolder>
      <BackButton onClick={() => history.goBack()} />
      {!isLoading && (
        <SignInBox
          style={tabIndex === 0 ? { height: "560px" } : { height: "465px" }}
        >
          <img src={logo} alt={"logo"} width={"225px"} />
          <Swapper onClick={() => setError("")}>
            <SwapperSegment
              style={tabIndex === 1 ? selected : {}}
              onClick={() => setTabIndex(1)}
            >
              Login
            </SwapperSegment>
            |
            <SwapperSegment
              style={tabIndex === 0 ? selected : {}}
              onClick={() => setTabIndex(0)}
            >
              Create Account
            </SwapperSegment>
          </Swapper>
          <form>
            {tabIndex === 0 ? (
              <div>
                <TextBoxesStyled
                  onClick={() => {
                    setCheckingForValidity(false);
                  }}
                >
                  <NameBox>
                    <Textbox
                      value={signInData.firstName}
                      onChange={(e) =>
                        handleChange("firstName", e.target?.value)
                      }
                      width={"calc(50% - 5px)"}
                      placeholder="First Name"
                      autocomplete="given-name"
                      name="fname"
                      id="fname"
                      required
                      handlingValidation={checkingForValidity}
                    />
                    <Textbox
                      value={signInData.lastName}
                      onChange={(e) =>
                        handleChange("lastName", e.target?.value)
                      }
                      width={"calc(50% - 5px)"}
                      placeholder="Last Name"
                      autocomplete="family-name"
                      name="lname"
                      id="lname"
                      required
                      handlingValidation={checkingForValidity}
                    />
                  </NameBox>
                  <Textbox
                    placeholder="Email"
                    type="email"
                    onChange={(e) => handleChange("email", e.target?.value)}
                    value={signInData.email}
                    autocomplete="username"
                    name="email"
                    id="new-email"
                    required
                    handlingValidation={checkingForValidity}
                  />
                  <Textbox
                    type="password"
                    placeholder="Password"
                    onChange={(e) => handleChange("password", e.target?.value)}
                    value={signInData.password}
                    autocomplete="new-password"
                    name="password"
                    id="new-password"
                    required
                    handlingValidation={checkingForValidity}
                  />
                  <Textbox
                    type="password"
                    placeholder="Confirm Password"
                    onChange={(e) =>
                      handleChange("confirmPassword", e.target?.value)
                    }
                    value={signInData.confirmPassword}
                    noEye
                    autocomplete="new-password"
                    name="confirm-password"
                    id="confirm-password"
                    required
                    handlingValidation={checkingForValidity}
                  />
                </TextBoxesStyled>
                <Subtext style={{ marginTop: "10px" }}>
                  {errorCodeToText(error)}
                </Subtext>
              </div>
            ) : (
              <div style={{ width: "100%" }}>
                <TextBoxesStyled
                  onClick={() => {
                    setCheckingForValidity(false);
                  }}
                >
                  <Textbox
                    value={signInData.email}
                    placeholder="Email"
                    type="email"
                    onChange={(e) => handleChange("email", e.target?.value)}
                    autocomplete="username"
                    name="email"
                    id="current-email"
                    required
                    handlingValidation={checkingForValidity}
                  />
                  <Textbox
                    value={signInData.password}
                    onChange={(e) => handleChange("password", e.target?.value)}
                    type="password"
                    placeholder="Password"
                    autocomplete="current-password"
                    name="password"
                    id="current-password"
                    required
                    handlingValidation={checkingForValidity}
                  />
                </TextBoxesStyled>
                <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                  Forgot Password?{" "}
                  <span
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                    onClick={props.openResetPassword}
                  >
                    Reset
                  </span>
                </p>
                <Subtext>{errorCodeToText(error)}</Subtext>
              </div>
            )}
            <BottomSection
              style={
                tabIndex === 0
                  ? { justifyContent: "space-between" }
                  : { justifyContent: "center" }
              }
            >
              {tabIndex === 0 ? (
                <Dropdown
                  options={["student", "parent"]}
                  handleType={handleType}
                />
              ) : (
                <></>
              )}
              <SecondaryButton
                name={tabIndex === 0 ? "Sign up" : "Login"}
                color={"#6AA6CC"}
                hover={"#5c9bc2"}
                onClick={tabIndex === 0 ? signUp : login}
                width={tabIndex === 0 ? "100%" : "160px"}
                height={"47px"}
                type={"submit"}
              />
            </BottomSection>
          </form>
        </SignInBox>
      )}
      <WelcomeSection isLoading={isLoading} />
    </SignInHolder>
  );
}

const SignInHolder = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;

  @media (max-width: 950px) {
    flex-direction: column-reverse;
  }
`;

const SignInBox = styled.div`
  background-color: #ffffff;
  width: 450px;
  border-radius: 35px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 20px rgba(54, 110, 192, 0.25);
  gap: 25px;
  padding: 50px 70px;

  transition: height ease 150ms;

  & > div > p {
    margin: 5px;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #5d646c;
    cursor: default;
    user-select: none;
    margin-top: 20px;
  }

  & > form {
    width: 100%;
  }

  & > img {
    user-select: none;
  }

  @media (max-width: 550px) {
    position: absolute;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    height: 100% !important;
    padding: 50px 30px;
  }
`;

const Swapper = styled.div`
  font-family: "Raleway", sans-serif;
  font-size: 28px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #15375b;
  cursor: default;
  user-select: none;
  gap: 7px;
`;

const SwapperSegment = styled.div`
  border-radius: 23px;
  color: #cccccc;
  user-select: none;
  cursor: pointer;
`;

const TextBoxesStyled = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

const NameBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
`;

const BottomSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 25px;
  margin-top: 25px;
`;

const Subtext = styled.p`
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: red;
  cursor: default;
  user-select: none;
  text-align: center;
`;
