import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ChatData = {
  open: boolean;
  emojiOpen: boolean;
  unreads: UnreadData[];
  selectedChatroom: string;
};

type UnreadData = {
  chatroomId: string;
  unreads: number;
};

const initialState: ChatData = {
  open: false,
  emojiOpen: false,
  unreads: [],
  selectedChatroom: "-1",
};

export const chatData = createSlice({
  name: "chatData",
  initialState,
  reducers: {
    openChat: (state) => {
      document.body.classList.add("modal-open");
      state.open = true;
    },
    closeChat: (state) => {
      document.body.classList.remove("modal-open");
      state.emojiOpen = false;
      state.open = false;
    },
    openEmoji: (state) => {
      state.emojiOpen = true;
    },
    closeEmoji: (state) => {
      state.emojiOpen = false;
    },
    selectChatroom: (state, action: PayloadAction<string>) => {
      state.selectedChatroom = action.payload;
    },
    setChatroomUnreads: (state, action: PayloadAction<UnreadData>) => {
      const newUnreads = state.unreads.filter(
        (unread) => action.payload.chatroomId !== unread.chatroomId
      );
      newUnreads.push(action.payload);
      state.unreads = newUnreads;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  openChat,
  closeChat,
  openEmoji,
  closeEmoji,
  selectChatroom,
  setChatroomUnreads,
} = chatData.actions;

export default chatData.reducer;
