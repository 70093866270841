import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebookF,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import waves from "../../assets/images/waves/footer_waves.svg";
import clarify from "../../assets/images/clarify/clarify_text_white.svg";
import { useHistory } from "react-router-dom";

type FooterProps = {
  background: string;
};

export default function Footer(props: FooterProps) {
  const history = useHistory();
  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const onClickUrl = (url: string) => {
    return () => openInNewTab(url);
  };

  return (
    <FooterStyled background={props.background}>
      <Content>
        <img src={clarify} alt="company name" />
        <SocialIcons>
          <div
            onClick={onClickUrl("https://www.instagram.com/clarifytutoring/")}
          >
            <FontAwesomeIcon icon={faInstagram} />
          </div>
          <div
            onClick={onClickUrl("https://www.facebook.com/clarifytutoring/")}
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </div>
          <div
            onClick={onClickUrl(
              "https://www.linkedin.com/company/clarifytutoring"
            )}
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </div>
        </SocialIcons>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <p>
            {" "}
            © Clarify Tutoring 2022. <br /> ABN 49 210 895 289
          </p>
          <p>
            <span onClick={() => history.push("/terms")}>Terms of use</span> |{" "}
            <span onClick={() => history.push("/privacy")}>Privacy</span> |{" "}
            <span onClick={() => history.push("/contactus")}>Contact Us</span>
          </p>
        </div>
      </Content>
    </FooterStyled>
  );
}

type FooterStyledProps = {
  background: string;
};

const FooterStyled = styled.div`
  background: url(${waves}) repeat-x;
  background-color: ${(props: FooterStyledProps) => props.background};
  background-position: center bottom;
  overflow: visible;
  overflow-x: hidden;
  position: relative;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 17px;
  padding: 30px;
  padding-bottom: 15px;
  width: 100%;
  z-index: 5;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding-top: 50px;

  & > img {
    width: 130px;
    user-select: none;
  }

  & p {
    margin: 0;
    padding: 0;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
  }

  & > div > p > span {
    cursor: pointer;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 30px;

  & div {
    width: 45px;
    height: 45px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    color: #272725;
    justify-content: center;
    align-items: center;
    transition: 200ms ease;
    cursor: pointer;
    font-size: 22px;
  }

  & div:hover {
    transform: translateY(-1px);
  }
`;
