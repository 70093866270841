import styled from "styled-components";

type ProfileImageProps = {
  url: string;
  size: number;
  onClick?: (() => void) | ((e: any) => void) | false;
};

export default function ProfileImage(props: ProfileImageProps) {
  const url =
    props.url.includes("unsplash") && !props.url.includes("?")
      ? props.url + "?w=300"
      : props.url;

  return (
    <StyledProfileImage
      onClick={props.onClick ? props.onClick : () => {}}
      url={url}
      height={props.size}
      width={props.size}
      pointer={props.onClick ? "pointer" : "default"}
    ></StyledProfileImage>
  );
}

type StyledProfileImageProps = {
  url: string;
  height: number;
  width: number;
  pointer: string;
};

const StyledProfileImage = styled.div`
  background-image: url(${(props: StyledProfileImageProps) => props.url});
  border-radius: 100%;
  min-width: ${(props: StyledProfileImageProps) => `${props.width}px`};
  min-height: ${(props: StyledProfileImageProps) => `${props.height}px`};
  width: ${(props: StyledProfileImageProps) => `${props.width}px`};
  height: ${(props: StyledProfileImageProps) => `${props.height}px`};
  background-size: cover;
  background-position: center;
  border: 5px solid rgb(112, 155, 189);
  cursor: ${(props: StyledProfileImageProps) => props.pointer};
  transition: 0.3s ease;
`;
