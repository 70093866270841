import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styled from "styled-components";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

type CheckBoxProps = {
  color?: string;
  outline?: string;
  handleChange: (value: boolean) => void;
};

export default function CheckBox(props: CheckBoxProps) {
  const [checked, setChecked] = useState(false);

  const toggleCheck = () => {
    props.handleChange(!checked);
    setChecked(!checked);
  };

  return (
    <Box onClick={toggleCheck}>
      {checked && <FontAwesomeIcon icon={faCheck} />}
    </Box>
  );
}

const Box = styled.div`
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;
  background: rgba(255, 221, 170, 0.3);
  border: 3px solid #ffc770;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #272725;
`;
