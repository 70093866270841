import styled from "styled-components";
import { ScreenSubHeading, ScreenSubText } from "../../utilities/ScreenText";
import TutorProfileComponent from "./TutorProfileComponent";

import { collection, query, where } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

import { TutorData } from "../../../data/databaseTypes";
import Spinner from "../../utilities/Spinner";

export default function TutorProfileSection() {
  const firestore = useFirestore();
  const tutorsCollection = collection(firestore, "tutors");

  const adminsQuery = query(
    tutorsCollection,
    where("type", "==", "admin"),
    where("shown", "==", true)
  );
  const tutorsQuery = query(
    tutorsCollection,
    where("type", "==", "tutor"),
    where("shown", "==", true)
  );

  const { data: dbAdmin } = useFirestoreCollectionData(adminsQuery);
  const { data: dbTutor } = useFirestoreCollectionData(tutorsQuery);

  const orderingFromFirstName = (a: TutorData, b: TutorData) => {
    const aName = a.firstName;
    const bName = b.firstName;

    if (aName < bName) return -1;
    if (bName > aName) return 1;
    else return 0;
  };

  const adminTutors = dbAdmin as TutorData[];
  const normalTutors = dbTutor as TutorData[];

  const adminTutorsSorted =
    adminTutors &&
    adminTutors
      .sort(orderingFromFirstName)
      .filter((tutor) => tutor.availability !== 0);
  const normalTutorsSorted =
    normalTutors &&
    normalTutors
      .sort(orderingFromFirstName)
      .filter((tutor) => tutor.availability !== 0);

  const unavailableTutors =
    adminTutors &&
    normalTutors &&
    [...adminTutors, ...normalTutors].filter(
      (tutor) => tutor.availability === 0
    );

  return (
    <TutorProfileSectionStyled>
      <ScreenSubHeading id="tutorsSection">Meet our Tutors</ScreenSubHeading>
      <ScreenSubText>
        Times have changed, and so does our teaching. We're entirely committed
        to a 100% online tutoring experience. Our experience with remote
        learning in high school and university have forged strong expertise in
        online tutoring.
      </ScreenSubText>
      <ProfileContainer>
        {!adminTutorsSorted && !normalTutorsSorted ? (
          <LoadingHolder>
            <Spinner loading={true} size={65} color={"#FF8270"} />
          </LoadingHolder>
        ) : (
          ""
        )}
        {/* Admin tutors first   */}
        {adminTutorsSorted &&
          adminTutorsSorted.map((tutorInfo) => (
            <TutorProfileComponent tutorInfo={tutorInfo} key={tutorInfo.uid} />
          ))}
        {/* Non-Admin tutors */}
        {normalTutorsSorted &&
          normalTutorsSorted.map((tutorInfo) => (
            <TutorProfileComponent tutorInfo={tutorInfo} key={tutorInfo.uid} />
          ))}
        {/* Unavailable tutors */}
        {unavailableTutors &&
          unavailableTutors.map((tutorInfo) => (
            <TutorProfileComponent
              tutorInfo={tutorInfo}
              key={tutorInfo.uid}
              unavailable
            />
          ))}
      </ProfileContainer>
    </TutorProfileSectionStyled>
  );
}

const TutorProfileSectionStyled = styled.div`
  width: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  padding: 20px 100px;
  gap: 15px;
  cursor: default;

  @media (max-width: 1000px) {
    padding: 20px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  min-height: 567px;
  flex-wrap: wrap;
  gap: 75px;
  justify-content: center;
  position: relative;
  padding: 50px 0;
`;

const LoadingHolder = styled.div`
  align-self: center;
`;
