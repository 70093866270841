import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { SellableProduct } from "../../../data/databaseTypes";
import Button from "../button/Button";
import Stepper from "../Stepper";
import ProductList from "./ProductList";
import ProductPrice from "./ProductPrice";

type ProductProps = {
  width?: string;
  minHeight?: string;
  buttonColor: string;
  buttonHover: string;
  bgColor: string;
  stroke?: string;
  onClick?: () => void;

  stepper?: {
    min: number;
    max: number;
  };
  product?: SellableProduct | false;
  onlyShowIcons?: boolean;
  dontShowBadDetails?: boolean;
};

export default function Product(props: ProductProps) {
  const [price, setPrice] = useState(0);
  const defaultPrice = useRef(0);

  useEffect(() => {
    setPrice(
      props.product ? (props.product.price ? props.product.price.value : 0) : 0
    );
    defaultPrice.current = props.product
      ? props.product.price
        ? props.product.price.value
        : 0
      : 0;
  }, [props.product]);

  const handleChange = (value: number) => {
    setPrice(defaultPrice.current * value);
  };

  return props.product ? (
    <ProductStyled
      bgColor={props.bgColor}
      width={props.width}
      minHeight={props.minHeight}
      stroke={props.stroke}
    >
      <TopSection>
        <ProductPrice price={props.product.price} number={price} />
      </TopSection>
      <SubSection>
        {props.stepper ? (
          <Stepper
            text={props.product.subtext}
            color={props.buttonColor}
            hover={props.buttonHover}
            default={1}
            min={props.stepper.min}
            max={props.stepper.max}
            handleChange={handleChange}
          />
        ) : (
          props.product.subtext
        )}
      </SubSection>
      <MidSection>
        <ProductList
          details={props.product.details}
          onlyShowIcons={props.onlyShowIcons}
          dontShowBadDetails={props.dontShowBadDetails}
        />
      </MidSection>
      {props.product.button && (
        <ButtonHolder>
          <Button
            name={props.product.button}
            color={props.buttonColor}
            hover={props.buttonHover}
            textColor="#000"
            width="200px"
            noShadow
            onClick={props.onClick}
          />
        </ButtonHolder>
      )}
    </ProductStyled>
  ) : (
    <ProductStyled
      bgColor={props.bgColor}
      width={props.width}
      minHeight={props.minHeight}
      style={{ justifyContent: "center", padding: "20px" }}
      stroke={props.stroke}
    >
      <PlaceHolder>More Products Coming Soon</PlaceHolder>
    </ProductStyled>
  );
}

type ProductStyledProps = {
  width?: string;
  minHeight?: string;
  bgColor: string;
  stroke?: string;
};

const ProductStyled = styled.div`
  width: ${(props: ProductStyledProps) =>
    props.width ? props.width : "375px"};
  min-height: ${(props: ProductStyledProps) =>
    props.minHeight ? props.minHeight : "auto"};
  background-color: ${(props: ProductStyledProps) => props.bgColor};
  border: ${(props: ProductStyledProps) =>
    props.stroke ? `solid ${props.stroke} 4px` : ""};

  border-radius: 50px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 50px 100px 50px;
  position: relative;
  gap: 7px;

  @media (max-width: 450px) {
    width: 350px;
    padding: 20px 30px 100px 30px;
  }
  @media (max-width: 400px) {
    width: 320px;
  }

  @media (max-width: 375px) {
    width: 100%;
    padding: 20px 20px 100px 20px;
  }
`;

const PlaceHolder = styled.p`
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  width: 200px;
  text-align: center;
  color: #888888;
  margin: 0;
  user-select: none;
`;

const TopSection = styled.div`
  margin-top: 20px;
`;

const SubSection = styled.div`
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #000000b2;
  user-select: none;
`;

const MidSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 5px;
`;

const ButtonHolder = styled.div`
  position: absolute;
  bottom: 40px;
`;
