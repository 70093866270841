import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../redux/store";

export default function QuickChatButton() {
  const userType = useSelector((state: RootState) => state.userData.type);

  const isTutor = userType === "tutor" || userType === "admin";

  return (
    <QuickChatDiv>{isTutor ? "group chats" : "clarify for me"}</QuickChatDiv>
  );
}

const QuickChatDiv = styled.div`
  width: 100%;
  border-radius: 20px;
  padding: 20px 20px;
  margin: 20px 0;
  cursor: pointer;
  font-size: 30px;
  text-align: center;
  font-family: "raleway";
  font-weight: 600;
  font-style: italic;
  color: white;
  background-color: #81bbf9;
  box-shadow: 0px 0px 20px rgba(36, 87, 133, 0.4);
  transition: 0.35s ease;
  &:hover {
    box-shadow: 0px 0px 20px rgba(36, 87, 133, 0.2);
    border-radius: 15px;
  }
`;
