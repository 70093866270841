import styled from "styled-components";
import NavBar from "../components/navbar/NavBar";
import Footer from "../components/footer/Footer";
// import ChatBubble from "../components/utilities/chat_bubble/ChatBubble";
import TutorLanding from "../components/screen_components/tutor/TutorLanding";
import TutorProductSection from "../components/screen_components/tutor/TutorProductSection";
import Waves from "../assets/images/waves/red_waves.svg";
import TutorProfileSection from "../components/screen_components/tutor/TutorProfileSection";
import TutorProfilePopup from "../components/screen_components/tutor/tutor_profile_popup/TutorProfilePopup";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { collection, query, where } from "firebase/firestore";
import { PageData, SellableProduct } from "../data/databaseTypes";
import TutorInfoSection from "../components/screen_components/tutor/TutorInfoSection";
import TutoringToChat from "../components/screen_components/tutor/TutoringToChat";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useEffect } from "react";

type TutoringScreenProps = {
  isScroll: boolean;
  scrollerContainer: OverlayScrollbarsComponent | null;
};

export default function TutoringScreen(props: TutoringScreenProps) {
  useEffect(() => {
    props.scrollerContainer?.osInstance()?.scroll(0, 0);
  }, [props.scrollerContainer]);

  const firestore = useFirestore();
  const productsInfoCollection = collection(firestore, "products_info");

  const tutoringProductsQuery = query(
    productsInfoCollection,
    where("page", "==", "tutoring")
  );
  const { data: dbTutoringInfo } = useFirestoreCollectionData(
    tutoringProductsQuery
  );

  const tutoringInfo = dbTutoringInfo as PageData[];
  const tutoringSellable: false | SellableProduct[] =
    tutoringInfo && tutoringInfo.length === 1 && tutoringInfo[0].sellable;

  return (
    <>
      <TutorProfilePopup />
      <PageContainer>
        <Background>
          <NavBar scroll={props.isScroll} />
          <div>
            <TutorLanding />
            <TutorInfoSection />
            <TutorProfileSection />
            <TutorProductSection sellable={tutoringSellable} />
            <TutoringToChat />
          </div>
          {/* <ChatBubble /> */}
          <Footer background={"#FFF"} />
        </Background>
      </PageContainer>
    </>
  );
}

const PageContainer = styled.div`
  width: 100%;
  background-color: #fff;
  position: relative;
`;

const Background = styled.div`
  background-color: #fff;
  background-image: url(${Waves});
  background-repeat: repeat-x;
  background-position: center top;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
