import { useEffect, useState } from "react";
import styled from "styled-components";
import Background from "../assets/images/background/line-background.svg";
import ResetPassword from "../components/screen_components/sign_in/ResetPassword";
import Login from "../components/screen_components/sign_in/Login";

export default function SignIn() {
  const [pageType, setPageType] = useState<"login" | "resetPassword">("login");

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList.remove("modal-open");
  }, []);

  const windowReloadStop = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    window.addEventListener("beforeunload", windowReloadStop);
    return () => {
      window.removeEventListener("beforeunload", windowReloadStop);
    };
  }, []);

  return (
    <BackgroundImg>
      {pageType === "login" ? (
        <Login openResetPassword={() => setPageType("resetPassword")} />
      ) : pageType === "resetPassword" ? (
        <ResetPassword openLogin={() => setPageType("login")}/>
      ) : (
        <></>
      )}
    </BackgroundImg>
  );
}

const BackgroundImg = styled.div`
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  background-image: url(${Background});
  background-color: #fff;
`;
