import styled from "styled-components";

type SecondaryButtonProps = {
  name: string;
  color: string;
  hover: string;
  size?: string;
  width?: string;
  height?: string;
  textColor?: string;
  bold?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  type?: "button" | "submit";
};

export default function SecondaryButton(props: SecondaryButtonProps) {
  return (
    <ButtonStyle
      color={props.color}
      hover={props.hover}
      size={props.size}
      width={props.width}
      onClick={props.onClick}
      textColor={props.textColor}
      bold={props.bold}
      height={props.height}
      type={props.type}
    >
      {props.name}
    </ButtonStyle>
  );
}

type ButtonStyleProps = {
  color: string;
  hover: string;
  size?: string;
  width?: string;
  height?: string;
  textColor?: string;
  bold?: boolean;
};

const ButtonStyle = styled.button`
  background-color: ${(props: ButtonStyleProps) => props.color};
  font-size: ${(props: ButtonStyleProps) => (props.size ? props.size : "20px")};
  color: ${(props: ButtonStyleProps) =>
    props.textColor ? props.textColor : "#FFF"};
  width: ${(props: ButtonStyleProps) => (props.width ? props.width : "auto")};
  height: ${(props: ButtonStyleProps) => (props.height ? props.height : "auto")};
  font-weight: ${(props: ButtonStyleProps) => (props.bold ? "800" : "600")};
  
  border: none;
  padding: 7px 20px;
  border-radius: 10px;
  font-family: "Cabin", sans-serif;
  outline: none;
  cursor: pointer;
  pointer-events: all;
  user-select: none;
  transition: background-color 300ms ease;

  &:hover {
    background-color: ${(props) => props.hover};
  }
`;
