import { LaxElement } from "use-lax";

export const elements: LaxElement[] = [
  {
    selector: ".info-item-lax-1",
    animationData: {
      scrollY: {
        opacity: [
          [
            "screenHeight",
            "screenHeight+800",
            "screenHeight+1100",
            "screenHeight+1600",
          ],
          {
            619: [1, 1, 1, 1],
            910: [1, 0.5, 0, 0],
            1400: [1, 0, 0, 0],
          },
        ],
        scale: [
          [
            "screenHeight",
            "screenHeight+800",
            "screenHeight+1100",
            "screenHeight+2000",
          ],
          {
            619: [1, 1, 1, 1],
            910: [1, 0.8, 0.7, 0],
            1400: [1, 0.8, 0.8, 0],
          },
        ],
      },
    },
  },
  {
    selector: ".info-item-lax-2",
    animationData: {
      scrollY: {
        opacity: [
          [
            "screenHeight",
            "screenHeight+800",
            "screenHeight+1100",
            "screenHeight+1600",
          ],
          {
            619: [1, 1, 1, 1],
            910: [1, 0.5, 0, 0],
            1400: [1, 0, 0, 0],
          },
        ],
        scale: [
          [
            "screenHeight",
            "screenHeight+600",
            "screenHeight+1100",
            "screenHeight+2000",
          ],
          {
            619: [1, 1, 1, 1],
            910: [1, 0.8, 0.7, 0],
            1400: [1, 0.8, 0.8, 0],
          },
        ],
      },
    },
  },
  {
    selector: ".info-item-lax-3",
    animationData: {
      scrollY: {
        opacity: [
          [
            "screenHeight",
            "screenHeight+800",
            "screenHeight+1100",
            "screenHeight+1600",
          ],
          {
            619: [1, 1, 1, 1],
            910: [1, 0.6, 0, 0],
            1400: [1, 0, 0, 0],
          },
        ],
        scale: [
          [
            "screenHeight",
            "screenHeight+800",
            "screenHeight+1100",
            "screenHeight+2000",
          ],
          {
            619: [1, 1, 1, 1],
            910: [1, 0.85, 0.8, 0],
            1400: [1, 0.8, 0.8, 0],
          },
        ],
      },
    },
  },
];
