import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { SellableProduct } from "../../../data/databaseTypes";
import { openPopup } from "../../../redux/assignmentUploadSlice";
import { openError } from "../../../redux/errorPopupSlice";
import { RootState } from "../../../redux/store";
import Product from "../../utilities/product/Product";
import { ScreenSubHeading } from "../../utilities/ScreenText";

type PricingProductSectionProps = {
  sellable: false | SellableProduct[];
};

export default function PricingProductSection(
  props: PricingProductSectionProps
) {
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.userData.uid);

  const [isMobile, setIsMobile] = useState(false);
  const updateWindowWidth = () => {
    setIsMobile(window.innerWidth < 900);
  };

  useEffect(() => {
    setIsMobile(window.innerWidth < 900);
    window.addEventListener("resize", updateWindowWidth);
    return () => {
      window.removeEventListener("resize", updateWindowWidth);
    };
  }, []);

  return (
    <PricingProductSectionStyled>
      <ScreenSubHeading>Transparent, crystal clear pricing</ScreenSubHeading>
      <ProductContainer>
        <LeftProduct style={isMobile ? { display: "none" } : {}}>
          {/* style={isMobile ? { order: "1" } : {}} */}
          <Product
            buttonColor={"#B8D8FB"}
            buttonHover={"#accaeb"}
            bgColor={"#FFF"}
            stroke={"#B8D8FB"}
            product={props.sellable && props.sellable[90]} // change to 0
            onlyShowIcons={!isMobile ? true : false}
            minHeight={!isMobile ? "719px" : "451px"}
            dontShowBadDetails={!isMobile ? false : true}
          />
        </LeftProduct>
        <MainProduct>
          {/* style={isMobile ? { order: "3" } : {}} */}
          <Product
            buttonColor={"#FFB6B6"}
            buttonHover={"#faa8a8"}
            bgColor={"#FFE9E9"}
            product={props.sellable && props.sellable[1]}
            minHeight={!isMobile ? "753px" : "753px"}
            onClick={() =>
              dispatch(
                openError(
                  "Thanks for your interest! Currently, we are working on getting website payments setup. In the meantime, contact us on messenger through the messenger button on the bottom right"
                )
              )
            }
          />
        </MainProduct>
        <RightProduct style={isMobile ? { display: "none" } : {}}>
          {/* style={isMobile ? { order: "2" } : {}} */}
          <Product
            buttonColor={"#B8D8FB"}
            buttonHover={"#accaeb"}
            bgColor={"#FFF"}
            stroke={"#B8D8FB"}
            product={props.sellable && props.sellable[92]} // change to 2
            onlyShowIcons={!isMobile ? true : false}
            minHeight={!isMobile ? "719px" : "575px"}
            dontShowBadDetails={!isMobile ? false : true}
          />
        </RightProduct>
      </ProductContainer>
      <ScreenSubHeading>
        Access our more targeted set of products
      </ScreenSubHeading>
      <ProductContainer>
        <LeftProduct style={isMobile ? { display: "none" } : {}}>
          <Product
            buttonColor={"#FFDDAA"}
            buttonHover={"#fbd7a0"}
            bgColor={"#FFF"}
            stroke={"#FFDDAA"}
            product={props.sellable && props.sellable[3]}
            minHeight={!isMobile ? "550px" : ""}
          />
        </LeftProduct>
        <MainProduct>
          <Product
            buttonColor={"#FFDDAA"}
            buttonHover={"#fbd7a0"}
            bgColor={"#FFF7EB"}
            product={props.sellable && props.sellable[4]}
            minHeight={!isMobile ? "588px" : "588px"}
            onClick={
              userId === "-1"
                ? () =>
                    dispatch(
                      openError(
                        "You need to be signed into your Clarify account to be able to upload files. If you do not have an account you can make one by clicking on Log in on the top right"
                      )
                    )
                : () => dispatch(openPopup())
            }
          />
        </MainProduct>
        <RightProduct style={isMobile ? { display: "none" } : {}}>
          <Product
            buttonColor={"#FFDDAA"}
            buttonHover={"#fbd7a0"}
            bgColor={"#FFF"}
            stroke={"#FFDDAA"}
            product={props.sellable && props.sellable[5]}
            minHeight={!isMobile ? "550px" : ""}
          />
        </RightProduct>
      </ProductContainer>
    </PricingProductSectionStyled>
  );
}

const PricingProductSectionStyled = styled.div`
  width: 100%;
  background-color: #f3f7fd;
  padding: 20px 100px;
  display: flex;
  flex-direction: column;
  gap: 100px;
  cursor: default;

  @media (max-width: 1000px) {
    padding: 20px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px;
  }
`;

const ProductContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

const MainProduct = styled.div`
  z-index: 3;

  @media (max-width: 1100px) {
    transform: scale(0.9);
  }
  @media (max-width: 900px) {
    transform: scale(1);
  }
`;

const LeftProduct = styled.div`
  z-index: 1;
  transform: scale(0.875) translate(100px, 3%);

  @media (max-width: 1100px) {
    transform: scale(0.75) translate(180px, 3%);
  }
  @media (max-width: 900px) {
    transform: scale(1) translate(0);
  }
`;

const RightProduct = styled.div`
  z-index: 1;
  transform: scale(0.875) translate(-100px, 3%);

  @media (max-width: 1100px) {
    transform: scale(0.75) translate(-180px, 3%);
  }
  @media (max-width: 900px) {
    transform: scale(1) translate(0);
  }
`;
