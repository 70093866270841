import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import closeIcon from "../../../../assets/images/utilities/close.svg";
import {
  faFileImage,
  faFilePdf,
  faFileWord,
  faFile,
  faClock,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

type AssignmentFileComponentProps = {
  status?: false | "uploading" | "completed";
  type: "PDF" | "DOC" | "IMAGE" | "FILE";
  name: string;
  idx: number;
  removeSelf: (idx: number) => void;
};

export default function AssignmentFileComponent(
  props: AssignmentFileComponentProps
) {
  return (
    <Component>
      <Icon>
        {props.type === "DOC" ? (
          <FontAwesomeIcon icon={faFileWord} />
        ) : props.type === "PDF" ? (
          <FontAwesomeIcon icon={faFilePdf} />
        ) : props.type === "IMAGE" ? (
          <FontAwesomeIcon icon={faFileImage} />
        ) : (
          <FontAwesomeIcon icon={faFile} />
        )}
      </Icon>
      <p>{props.name}</p>
      <Close
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          props.removeSelf(props.idx);
        }}
      >
        <img src={closeIcon} alt="Close Button" />
      </Close>
      {props.status && (
        <Status>
          {props.status === "completed" ? (
            <span style={{ color: "#24b500" }}>
              <FontAwesomeIcon icon={faCheckCircle} />
            </span>
          ) : props.status === "uploading" ? (
            <span style={{ color: "#4C97DA" }}>
              <FontAwesomeIcon icon={faClock} />
            </span>
          ) : (
            ""
          )}
        </Status>
      )}
    </Component>
  );
}

const Component = styled.div`
  width: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  & > p {
    word-wrap: break-word;
    width: 120px;
    text-align: center;
  }
`;

const Icon = styled.div`
  font-size: 85px;
  color: #86847f;
`;

const Close = styled.div`
  position: absolute;
  top: 0;
  right: 15px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease;
  cursor: pointer;
  pointer-events: all;

  & > img {
    width: 12px;
    height: 12px;
  }

  &:hover {
    background-color: #dddddd;
  }
`;

const Status = styled.div`
  position: absolute;
  top: 77px;
  right: 20px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  background-color: white;
  align-items: center;
  font-size: 18px;
`;
