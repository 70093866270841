import styled from "styled-components";
import Button from "../../utilities/button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import "animate.css";
import { useDispatch } from "react-redux";
import { ScreenHeading, ScreenSubText } from "../../utilities/ScreenText";
import Dot from "../../utilities/Dot";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { openError } from "../../../redux/errorPopupSlice";

type LandingSectionProps = {
  scrollerContainer: OverlayScrollbarsComponent | null;
};

export default function LandingSection(props: LandingSectionProps) {
  const dispatch = useDispatch();

  const scrollToNextSection = () => {
    if (!props.scrollerContainer) return;
    if (!props.scrollerContainer.osInstance()) return;
    props.scrollerContainer.osInstance()?.scroll([0, 800], 500);
  };

  const openChatWindow = () => {
    dispatch(
      openError(
        "Thanks for your interest! Currently, we are working on our chat feature to ensure it operates smoothly for new users. In the meantime, contact us on messenger through the messenger button on the bottom right"
      )
    );
  };

  return (
    <LandingContainer>
      <TextContainer>
        <span>
          <ScreenHeading size={"small"}>
            ATAR? We'll <i>clarify</i> it for
            <span style={{ whiteSpace: "nowrap" }}>
              {" "}
              you
              <Dot color={"#71BCFF"} />
            </span>
          </ScreenHeading>
        </span>

        <ScreenSubText>
          Glisten like never before and beam that light onto your success. Get
          in touch with experienced and trustworthy QLD tutors in seconds!
        </ScreenSubText>
      </TextContainer>
      <Button
        name={"Chat with a tutor now!"}
        color={"#70bcff"}
        hover={"#5fb3ff"}
        onClick={openChatWindow}
        width={"235px"}
      />
      <ArrorContainer onClick={scrollToNextSection}>
        <FontAwesomeIcon icon={faAngleDown} />
      </ArrorContainer>
    </LandingContainer>
  );
}

const LandingContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 666px;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px 100px;

  @media (max-width: 1000px) {
    padding: 20px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  gap: 5px;
  padding-top: 8%;
  justify-content: center;
  flex-direction: column;
  font-weight: normal;
  color: rgb(0, 0, 0);
  pointer-events: none;
  margin-bottom: 50px;
  width: 70%;

  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;

const ArrorContainer = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: #245785;
  align-self: center;
  position: absolute;
  bottom: 20px;
  transition: transform 300ms;
  cursor: pointer;

  &:hover {
    transform: translate(0, 3px);
    color: #1e5381;
  }
`;
