import styled from "styled-components";
import dots from "../../../assets/images/shapes/red-dots.svg";
import TutorInfoComponent from "./TutorInfoComponent";

export default function TutorInfoSection() {
  return (
    <TutorInfoSectionStyled>
      <TutorInfoComponent
        title={{
          normal: "Student",
          bold: "Specific",
        }}
        main={
          "We respect our students' individuality, how they learn and what they want to achieve. That's why Clarify gives our students the freedom to learn a range of concepts in a comfortable space or dive deeper through opportunities to think outside the box. It all depends on what they want from our classes!"
        }
      />
      <TutorInfoComponent
        title={{
          normal: "Maximise",
          bold: "Every Hour",
        }}
        main="Our classes aren't pre-recorded. While recorded lessons are more passive forms of learning, our tutors are always present and interacting with the student to encourage questions and active engagement. This helps retain and understand information. Our students are free to ask anything and everything they want in their lesson. What's more, it's never been easier to ask follow-up questions outside lesson times with our flagship Clarify Chat."
      />
      <TutorInfoComponent
        title={{
          normal: "Reach",
          bold: "Full Potential",
        }}
        main={
          "Good marks doesn't necessarily equal good teacher. That's why at Clarify, we value teaching expertise just as much as high scores. Our tutors have everything; a 99+ ATAR, 90+ in their subject and 200+ hours of tutoring experience. Plus, we're equipped with firsthand experience of the updated curriculum. Having graduated in 2020 or later, we can pinpoint problems and suggest a solution better than any teacher can."
        }
      />
    </TutorInfoSectionStyled>
  );
}

const TutorInfoSectionStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: default;
  align-items: center;
  padding: 50px 20px;
  background-image: url(${dots});
  background-repeat: no-repeat round;
  background-position: 85% 0%;
  background-size: contain;
  margin-bottom: 50px;

  @media (max-width: 900px) {
    background-position: 95% 0%;
  }

  @media (max-width: 750px) {
    background-position: 150% 0%;
  }

  @media (max-width: 500px) {
    padding: 0;
  }
`;
