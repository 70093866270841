import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type errorPopupSliceType = {
  open: boolean;
  errorMessage: string;
};

const initialState: errorPopupSliceType = {
  open: false,
  errorMessage: "",
};

export const errorPopupSlice = createSlice({
  name: "errorPopupData",
  initialState,
  reducers: {
    openError: (state, action: PayloadAction<string>) => {
      document.body.classList.add("modal-open");
      state.open = true;
      state.errorMessage = action.payload;
    },
    closeError: (state) => {
      document.body.classList.remove("modal-open");
      state.open = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { openError, closeError } = errorPopupSlice.actions;

export default errorPopupSlice.reducer;
