import styled from "styled-components";
import { UserData } from "../../../data/databaseTypes";
import UserComponent from "./UserComponent";

type AdminUsersProps = {
  students: UserData[];
  parents: UserData[];
  employees: UserData[];
};

export default function AdminUsers(props: AdminUsersProps) {
  const sortUsers = (userA: UserData, userB: UserData) => {
    return userB.joined - userA.joined;
  };

  const sortedStudents = props.students && props.students.sort(sortUsers);
  const sortedParents = props.parents && props.parents.sort(sortUsers);

  return (
    <Section>
      <b>Employees {props.employees ? props.employees.length : 0}</b>
      <UserList>
        {props.employees &&
          props.employees.map((employee: UserData) => (
            <UserComponent
              key={employee.uid}
              uid={employee.uid}
              firstName={employee.firstName}
              lastName={employee.lastName}
              joined={employee.joined}
              email={employee.email}
            />
          ))}
      </UserList>

      <b>Students {sortedStudents ? sortedStudents.length : 0}</b>
      <UserList>
        {sortedStudents &&
          sortedStudents.map((student: UserData) => (
            <UserComponent
              key={student.uid}
              uid={student.uid}
              firstName={student.firstName}
              lastName={student.lastName}
              joined={student.joined}
              email={student.email}
            />
          ))}
      </UserList>
      <b>Parents {sortedParents ? sortedParents.length : 0}</b>
      <UserList>
        {sortedParents &&
          sortedParents.map((parent: UserData) => (
            <UserComponent
              key={parent.uid}
              uid={parent.uid}
              firstName={parent.firstName}
              lastName={parent.lastName}
              joined={parent.joined}
              email={parent.email}
            />
          ))}
      </UserList>
    </Section>
  );
}

const Section = styled.div`
  width: 100%;
  padding: 20px;
`;

const UserList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  gap: 5px;
`;
