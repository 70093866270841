import styled from "styled-components";

export const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  transition: 0.3s ease;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 10;

  &:hover {
    background-color: #dddddd;
  }

  & > img {
    user-select: none;
  }
`;
