import styled from "styled-components";
import { subjectColor } from "../../scripts/subject";

type SubjectProps = {
  subject: string;
  displayName: string;
};

export default function SubjectListItem(props: SubjectProps) {
  return (
    <SubjectContainer>
      <Circle color={subjectColor(props.subject)} />
      <SubjectName>{props.displayName}</SubjectName>
    </SubjectContainer>
  );
}

const SubjectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

type CircleProps = {
  color: string;
};

const Circle = styled.div`
  min-height: 35px;
  min-width: 35px;
  border-radius: 50%;
  background-color: ${(props: CircleProps) => props.color};
`;

const SubjectName = styled.p`
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #000000;
  margin: 0;

  @media (max-width: 450px) {
    font-size: 18px;
  }
`;
