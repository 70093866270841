import { collection, query, where } from "firebase/firestore";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useEffect } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import styled from "styled-components";
import Footer from "../components/footer/Footer";
import NavBar from "../components/navbar/NavBar";
import PricingDivider from "../components/screen_components/pricing/PricingDivider";
import PricingFAQ from "../components/screen_components/pricing/PricingFAQ";
import PricingLanding from "../components/screen_components/pricing/PricingLanding";
import PricingProductSection from "../components/screen_components/pricing/PricingProductSection";
import AssignmentUploadPopup from "../components/screen_components/product/upload/AssignmentUploadPopup";
// import ChatBubble from "../components/utilities/chat_bubble/ChatBubble";
import { PageData, SellableProduct } from "../data/databaseTypes";

type PricingScreenProps = {
  isScroll: boolean;
  scrollerContainer: OverlayScrollbarsComponent | null;
};

export default function PricingScreen(props: PricingScreenProps) {
  useEffect(() => {
    props.scrollerContainer?.osInstance()?.scroll(0, 0);
  }, [props.scrollerContainer]);

  const firestore = useFirestore();
  const productsInfoCollection = collection(firestore, "products_info");

  const pricingQuery = query(
    productsInfoCollection,
    where("page", "==", "pricing")
  );
  const { data: dbPricingInfo } = useFirestoreCollectionData(pricingQuery);

  const pricingInfo = dbPricingInfo as PageData[];

  const pricingSellable: false | SellableProduct[] =
    pricingInfo && pricingInfo.length === 1 && pricingInfo[0].sellable;

  return (
    <>
      <AssignmentUploadPopup />
      <PageContainer>
        <NavBar scroll={props.isScroll} />
        <div>
          <PricingLanding />
          <PricingDivider type={"up"} />
          <PricingProductSection sellable={pricingSellable} />
          <PricingDivider type={"down"} />
          <PricingFAQ />
        </div>
        {/* <ChatBubble /> */}
        <Footer background={"#E1ECF9"} />
      </PageContainer>
    </>
  );
}

const PageContainer = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
`;
