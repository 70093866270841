import styled from "styled-components";
import ChatPricingLanding from "../components/screen_components/chat_pricing/ChatPricingLanding";

import Footer from "../components/footer/Footer";
import NavBar from "../components/navbar/NavBar";
// import ChatBubble from "../components/utilities/chat_bubble/ChatBubble";
import Waves from "../assets/images/waves/blue_waves.svg";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { collection, query, where } from "firebase/firestore";
import { PageData, SellableProduct } from "../data/databaseTypes";
import ChatProductSection from "../components/screen_components/chat_pricing/ChatProductSection";
import ChatToTutoring from "../components/screen_components/chat_pricing/ChatToTutoring";
import ChatInfoSection from "../components/screen_components/chat_pricing/ChatInfoSection";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useEffect } from "react";

type ChatPricingScreenProps = {
  isScroll: boolean;
  scrollerContainer: OverlayScrollbarsComponent | null;
};

export default function ChatPricingScreen(props: ChatPricingScreenProps) {

  useEffect(() => {
    props.scrollerContainer?.osInstance()?.scroll(0, 0);
  }, [props.scrollerContainer]);
  
  const firestore = useFirestore();
  const productsInfoCollection = collection(firestore, "products_info");

  const chatPricingQuery = query(
    productsInfoCollection,
    where("page", "==", "chatpricing")
  );
  const { data: dbChatProductInfo } =
    useFirestoreCollectionData(chatPricingQuery);

  const chatProductInfo = dbChatProductInfo as PageData[];

  const chatProductSellable: false | SellableProduct[] =
    chatProductInfo &&
    chatProductInfo.length === 1 &&
    chatProductInfo[0].sellable;

  return (
    <PageContainer>
      <Background>
        <NavBar scroll={props.isScroll} />
        <div>
          <ChatPricingLanding />
          <ChatInfoSection />
          <ChatProductSection sellable={chatProductSellable} />
          <ChatToTutoring />
        </div>
        {/* <ChatBubble /> */}
        <Footer background={"#FFF"} />
      </Background>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100%;
  position: relative;
  background-color: #fff;
`;

const Background = styled.div`
  background-color: #fff;
  background-image: url(${Waves});
  background-repeat: repeat-x;
  background-position: center top;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
