import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { ChatroomData, TutorData, UserData } from "./databaseTypes";

export const startDirectMessage = (
  firestore: any,
  user: UserData,
  tutorInfo: TutorData
) => {
  return new Promise<string>((resolve, reject) => {
    if (user && user.uid !== "-1" && user.uid !== tutorInfo.uid) {
      const chatroomId =
        user.uid > tutorInfo.uid
          ? tutorInfo.uid + "-" + user.uid
          : user.uid + "-" + tutorInfo.uid;

      const neverTime = new Date(0);
      const currentTime = new Date();
      getDoc(doc(firestore, "chatrooms", chatroomId))
        .then((snapshot) => {
          if (!snapshot.exists()) {
            setDoc(doc(firestore, "chatrooms", chatroomId), {
              name: "",
              userIds: [user.uid, tutorInfo.uid],
              userNames: [user.firstName, tutorInfo.firstName],
              userImages: [user.profileImage, tutorInfo.profileImage],
              lastActive: [currentTime, neverTime],
              type: "direct",
            })
              .then(() => {
                resolve(chatroomId);
              })
              .catch((err) => reject(err));
          } else {
            const chatroom = snapshot.data() as ChatroomData;
            chatroom.id = chatroomId;
            resolve(chatroomId);
          }
        })
        .catch((err) => reject(err));
    } else {
      reject();
    }
  });
};

export const markActiveNow = (
  firestore: any,
  uid: string,
  chatroom: ChatroomData
) => {
  return new Promise<boolean>((resolve, reject) => {
    const newActive = chatroom.lastActive;
    const userIndex = chatroom.userIds.findIndex((id) => id === uid);
    if (userIndex !== -1) {
      const now = new Date();
      newActive[userIndex] = now;
      updateDoc(doc(firestore, "chatrooms", chatroom.id), {
        lastActive: newActive,
      })
        .then(() => resolve(true))
        .catch((err) => reject(err));
    } else {
      reject();
    }
  });
};
