import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

type BackButtonProps = {
  onClick: () => void;
};

export default function BackButton(props: BackButtonProps) {
  return (
    <BackButtonStyled onClick={props.onClick}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </BackButtonStyled>
  );
}

const BackButtonStyled = styled.div`
  display: flex;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  transition: 0.3s ease;
  cursor: pointer;
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 10;
  color: #15375b;
  font-size: 24px;
  padding: 0 13px;

  &:hover {
    background-color: #ebf4fe;
  }
`;
