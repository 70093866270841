import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import styled from "styled-components";

type TextboxToolTipProps = {
  text: string;
}

export default function TextboxToolTip(props: TextboxToolTipProps) {
  return (
    <StyledToolContainer>
      <FontAwesomeIcon icon={faQuestionCircle} />
      <StyledToolTip>{props.text}</StyledToolTip>
    </StyledToolContainer>
  );
}

const StyledToolTip = styled.span`
  visibility: hidden;
  background-color: rgba(188, 218, 248, 0.7);
  color: #5D646C;
  border-radius: 6px;
  font-size: 10px;
  padding: 10px 10px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 1s;

  position: absolute;
  top: -10px;
  left: 250%;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent rgba(188, 218, 248, 0.7) transparent transparent;
  }
`;

const StyledToolContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover ${StyledToolTip} {
    visibility: visible;
    opacity: 1;
  }
`;
