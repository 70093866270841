import styled from "styled-components";
import Sun from "../../../assets/images/shapes/yellow-sun.svg";
import { ScreenSubText } from "../../utilities/ScreenText";

export default function AboutUsFinalSection() {
  return (
    <AboutUsFinalSectionStyled>
      <ScreenSubText style={{ width: "80%" }}>
        Through our platform, students can connect instantly with experienced
        tutors to clarify concerns over our Clarify Chat or start online
        tutoring sessions at competitive rates. Our tutors were some of the best
        performers in the state, recording 99+ ATARs and 95+ subject scores. We
        want to ensure our students are placed in the correct hands.
      </ScreenSubText>
      <ScreenSubText  style={{ width: "80%" }}>
        At Clarify Tutoring, students are also able to access more unique and
        personalised products such as an assignment marking and feedback
        service. We know that Year 12 is different for everyone, so we do our
        best to cater for all.
      </ScreenSubText>
    </AboutUsFinalSectionStyled>
  );
}

const AboutUsFinalSectionStyled = styled.div`
  width: 100%;
  height: 800px;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 30px;
  align-items: center;
  padding: 20px 100px;
  min-height: 600px;
  background: url(${Sun});
  background-repeat: no-repeat;
  background-position: 10% 0;
  background-size: 250px 250px;
  justify-content: center;
  cursor: default;
  @media (max-width: 1000px) {
    padding: 20px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px;
    background-position: -75px 0;
  }
`;
