import styled from "styled-components";
import Select from "react-select";
import { TutorData, UserData } from "../../../../data/databaseTypes";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDoc, collection } from "firebase/firestore";
import { useFirestore } from "reactfire";

type TutorRequestSectionProps = {
  studentsParents: UserData[];
  tutorData: TutorData;
};

export default function TutorRequestSection(props: TutorRequestSectionProps) {
  const [studentSelection, setStudentSelection] = useState<
    { value: string; label: string }[]
  >([]);

  const firestore = useFirestore();

  useEffect(() => {
    // find student name given stuent ID from students
    const findStudentName = (userID: string) => {
      if (!props.studentsParents) return "unknown";
      const student = props.studentsParents.filter(
        (student) => student.uid === userID
      );
      if (student.length === 1)
        return `${student[0].firstName} ${student[0].lastName}`;
      return "unknown";
    };

    if (props.studentsParents && props.tutorData) {
      const newData = [];
      for (const student of props.tutorData.students) {
        const element = { value: student, label: findStudentName(student) };
        newData.push(element);
      }
      setStudentSelection(newData);
    }
  }, [props.studentsParents, props.tutorData]);

  // form data
  const [selectedStudent, setSelectedStudent] = useState<string>();
  const [tutorDate, setTutorDate] = useState(new Date());
  const [duration, setDuration] = useState<{ hours: number; minutes: number }>({
    hours: 1,
    minutes: 0,
  });
  const [notes, setNotes] = useState("");

  const handleSelectChange = (e: any) => {
    setSelectedStudent(e.value);
  };

  const handleDurationChange = (value: number, key: "hours" | "minutes") => {
    if (key === "hours") {
      setDuration((oldData) => {
        const newData = { ...oldData };
        newData.hours = value;
        return newData;
      });
    } else {
      setDuration((oldData) => {
        const newData = { ...oldData };
        newData.minutes = value;
        return newData;
      });
    }
  };

  const [studentSelectionError, setStudentSelectionError] = useState(false);

  const studentName = (uid: string) => {
    for (const student of studentSelection) {
      if (student.value === uid) return student.label;
    }
  };

  const studentEmail = (uid: string) => {
    for (const student of props.studentsParents) {
      if (student.uid === uid) return student.email;
    }
  };

  const submitForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setStudentSelectionError(false);
    if (!selectedStudent) {
      setStudentSelectionError(true);
    } else {
      const payRef = collection(firestore, "tutor_pay");
      addDoc(payRef, {
        uid: props.tutorData.uid,
        studentId: selectedStudent,
        studentName: studentName(selectedStudent),
        studentEmail: studentEmail(selectedStudent),
        date: tutorDate,
        duration: duration,
        notes: notes,
        status: "pending",
      })
        .then(() => {
          // reset values to default
          setDuration({
            hours: 1,
            minutes: 0,
          });
          setTutorDate(new Date());
          setNotes("");
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <Container>
      <b>Tutor pay request form</b>
      <form>
        <label>
          {" "}
          {studentSelectionError
            ? "Select Student before submitting!"
            : "Student:"}{" "}
        </label>
        <Select options={studentSelection} onChange={handleSelectChange} />
        <label>Date: </label>
        <DatePicker
          selected={tutorDate}
          onChange={(date: Date) => setTutorDate(date)}
          dateFormat="dd/MM/yyyy"
        />
        <label>Duration: </label>
        <div>
          <label> hours </label>
          <input
            value={duration.hours}
            type="number"
            min="0"
            max="10"
            onChange={(e) =>
              handleDurationChange(parseInt(e.target?.value), "hours")
            }
          />
          <label> minutes </label>
          <input
            value={duration.minutes}
            type="number"
            min="0"
            max="45"
            step="15"
            onChange={(e) =>
              handleDurationChange(parseInt(e.target?.value), "minutes")
            }
          />
        </div>
        <label>
          Notes: (for different price or something, leave blank if nothing)
        </label>
        <input
          type={"text"}
          value={notes}
          onChange={(e) => setNotes(e.target?.value)}
        />
        <input type={"submit"} width={"100px"} onClick={submitForm} />
      </form>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 15px;

  & form {
    display: flex;
    flex-direction: column;
    gap: 5px;
    border: solid black 1px;
    padding: 10px;
  }
`;
