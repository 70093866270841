import styled from "styled-components";
import { ScreenSubHeading } from "../../utilities/ScreenText";
import FaqItem from "../../utilities/FaqItem";

export default function PricingFAQ() {
  return (
    <PricingFAQStyled>
      <ScreenSubHeading>Frequently Asked Questions</ScreenSubHeading>
      <FAQsContainer>
        <FaqItem
          circleColor={"#FFC770"}
          question={"How do we pay for this service?"}
          answer={
            "Once our tutors have confirmed that we are able to provide you with the service/product you require, we will send you an invoice to pay for the service. This payment is conducted securely, via Stripe. For particular services, confirmation of payment may be required before the service can be conducted."
          }
        />
        <FaqItem
          circleColor={"#245785"}
          question={"Who will be in charge of providing me my desired service?"}
          answer={
            "Our dedicated team of Clarify Tutors are the ones who take care of all of your needs. All of our tutors performed highly, achieving 99+ ATARs and 95+ subject scores. Read more about our tutors under Services >> Tutoring, and learn about our journey by clicking the About Us page."
          }
        />
        <FaqItem
          circleColor={"#6AA6CC"}
          question={"How can we contact the tutors at Clarify Tutoring?"}
          answer={
            "In the near future, we hope to get our chat feature up and running, in which you can directly chat to an individual tutor. In the meantime, make use of our chat service via messenger by clicking the icon in the bottom right."
          }
        />
      </FAQsContainer>
    </PricingFAQStyled>
  );
}

const PricingFAQStyled = styled.div`
  width: 100%;
  cursor: default;
  background-color: #e1ecf9;
  padding: 20px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  @media (max-width: 1000px) {
    padding: 20px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px;
  }
`;

const FAQsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 70px;
  margin: 70px 0;
`;
