import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ScreenSubText } from "../../utilities/ScreenText";

export default function TutoringToChat() {
  const history = useHistory();
  return (
    <PageContainer>
      <Content>
        <ScreenSubText>
          FREE access to our Clarify Chat Pro Plan is included with tutoring.{" "}
          <span
            style={{
              textDecoration: "underline",
              color: "#F46A57",
              cursor: "pointer",
            }}
            onClick={() => history.push("/clarifychat")}
          >
            Learn More.
          </span>
        </ScreenSubText>
      </Content>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100%;
  padding: 0 0 100px 0;
  display: flex;
  justify-content: center;
  cursor: default;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  width: 45%;
  min-width: 250px;
`;
