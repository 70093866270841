import { Quotes } from "phosphor-react";
import styled from "styled-components";

type QuoteItemProps = {
  circleColor: string;
  initials: string;
  text: string;
};

export default function QuoteItem(props: QuoteItemProps) {
  return (
    <QuoteContainer>
      <QuoteCircle circleColor={props.circleColor}>
        <Quotes size={42} color={"white"} />
      </QuoteCircle>
      <Text>{props.text}</Text>
      <Name>{props.initials}</Name>
    </QuoteContainer>
  );
}

const QuoteContainer = styled.div`
  display: flex;
  position: relative;
  background-color: #fff;
  padding: 25px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  border-radius: 42px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  width: 500px;
  padding: 75px 50px 50px 50px;
  margin: 0 auto;

  @media (max-width: 1350px) {
    width: 400px;
  }
  @media (max-width: 1150px) {
    width: 600px;
  }
  @media (max-width: 800px) {
    width: 500px;
  }
  @media (max-width: 700px) {
    width: 400px;
  }
  @media (max-width: 600px) {
    width: 350px;
  }
  @media (max-width: 500px) {
    width: 300px;
    padding: 75px 30px 30px 30px;
  }
  @media (max-width: 360px) {
    width: 250px;
  }
`;

type QuoteCircleProps = {
  circleColor: string;
};

const QuoteCircle = styled.div`
  height: 80px;
  width: 80px;
  top: -40px;
  left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${(props: QuoteCircleProps) => props.circleColor};
  position: absolute;
`;

const Name = styled.p`
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 18px;
  align-self: flex-end;
`;

const Text = styled.p`
  font-family: "Raleway", sans-serif;
  font-size: 17px;
`;
