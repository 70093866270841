import styled from "styled-components";
import { SellableProductDetail } from "../../../data/databaseTypes";
import GreenCheckIcon from "../icons/GreenCheckIcon";
import RedCrossIcon from "../icons/RedCrossIcon";

type ProductListProps = {
  details: SellableProductDetail[];
  onlyShowIcons?: boolean;
  dontShowBadDetails?: boolean;
};

export default function ProductList(props: ProductListProps) {
  let details: SellableProductDetail[] = props.details;
  if (props.dontShowBadDetails) {
    details = details.filter(
      (detail: SellableProductDetail) => detail.type === "good"
    );
  }
  return (
    <Details>
      {details.map((detail: SellableProductDetail) => (
        <DetailItem
          key={detail.detail}
          style={props.onlyShowIcons ? { justifyContent: "center" } : {}}
        >
          {detail.type === "good" ? <GreenCheckIcon /> : <RedCrossIcon />}

          {!props.onlyShowIcons && detail.detail}
        </DetailItem>
      ))}
    </Details>
  );
}

const Details = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const DetailItem = styled.div`
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  color: #000;
  user-select: none;
  display: flex;
  align-items: center;

  & > div {
    display: inline-block;
    margin-right: 20px;
    margin-top: 2px;
  }
`;
