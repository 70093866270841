import styled from "styled-components";
import shape from "../../../../assets/images/shapes/yellow-shape.svg";

export default function ProductInfoComponent() {
  return (
    <ProductInfoBox>
      <Image>
        <img src={shape} alt="shape" />
      </Image>

      <TitleCircle>
        <p>
          Assignment <b>Feedback</b>
        </p>
      </TitleCircle>

      <MainCircle>
        <p>
          Whether its a mind boggling PSMT, tedious Research Investigation,
          Student Experiment or Analytical Essay, we've got you. Receive
          detailed and personalised feedback specific to your ISMG within 3
          days.
        </p>
      </MainCircle>
      <SubCircle>
        <p>
          Check out our sample feedback, including annotations and criteria
          marking!
        </p>
      </SubCircle>
    </ProductInfoBox>
  );
}

const ProductInfoBox = styled.div`
  position: relative;
  width: 80%;
  height: 450px;
  padding: 20px;

  @media (max-width: 1150px) {
    width: 85%;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
  @media (max-width: 800px) {
    height: 600px;
  }
  @media (max-width: 600px) {
    height: 650px;
  }
  @media (max-width: 500px) {
    height: 700px;
  }
`;

const Image = styled.div`
  position: absolute;
  user-select: none;
  z-index: 1;
  top: -15px;
  left: 60px;
  & > img {
    width: 450px;
  }
  @media (max-width: 800px) {
    top: 30px;
    & > img {
      width: 500px;
    }
  }
  @media (max-width: 650px) {
    left: 20px;
  }
  @media (max-width: 600px) {
    left: -40px;
    top: 10px;
    & > img {
      width: 520px;
    }
  }
  @media (max-width: 550px) {
    left: -50px;
    & > img {
      width: 480px;
    }
  }
  @media (max-width: 500px) {
    left: -60px;
    top: 15px;
  }
  @media (max-width: 450px) {
    left: -80px;
  }
  @media (max-width: 400px) {
    left: -90px;
  }
  @media (max-width: 375px) {
    left: -100px;
  }
`;

const TitleCircle = styled.div`
  background-color: #ffddaa;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);

  position: absolute;
  z-index: 3;

  & > p {
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    text-align: center;
    color: #000000;
  }
  @media (max-width: 800px) {
    top: 50px;
    left: 50px;
  }
  @media (max-width: 750px) {
    left: 10px;
  }
  @media (max-width: 650px) {
    left: 20px;
  }
  @media (max-width: 600px) {
    left: 0;
    top: 0;
  }
  @media (max-width: 450px) {
    left: -10px;
  }
  @media (max-width: 350px) {
    left: -20px;
  }
`;

const MainCircle = styled.div`
  height: 250px;
  width: 80%;
  padding: 50px 100px;
  border-radius: 200px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  top: 25%;
  left: 100px;
  position: absolute;
  z-index: 2;

  & > p {
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    color: #000000;
  }

  @media (max-width: 800px) {
    height: 300px;
  }
  @media (max-width: 650px) {
    height: 350px;
    left: 70px;
  }
  @media (max-width: 600px) {
    left: 50px;
    top: 110px;
    padding: 50px 70px;
  }
  @media (max-width: 500px) {
    top: 130px;
    min-width: 350px;
  }
  @media (max-width: 350px) {
    left: 15px;
  }
  @media (max-width: 300px) {
    left: 0;
  }
`;

const SubCircle = styled.div`
  background-color: #ffddaa;
  border-radius: 200px;
  width: 35%;
  min-width: 300px;
  position: absolute;
  bottom: 20px;
  right: 50px;
  height: 135px;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  & > p {
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    color: #000000;
  }
  @media (max-width: 800px) {
    bottom: 70px;
  }
  @media (max-width: 650px) {
    bottom: 30px;
  }
  @media (max-width: 600px) {
    height: 200px;
    min-width: 200px;
    padding: 15px;
    bottom: auto;
    top: 370px;
    left: 50%;
  }
  @media (max-width: 550px) {
    left: auto;
    right: 0;
  }
  @media (max-width: 500px) {
    top: 410px;
  }
  @media (max-width: 450px) {
    top: 425px;
  }
`;
