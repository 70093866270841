import { useEffect, useRef } from "react";
import styled from "styled-components";
import { userImages } from "../../../data/userImages";
import ProfileImage from "../../utilities/ProfileImage";

type ProfileImageSelectionProps = {
  shown: boolean;
  handleChange: (
    key: "firstName" | "lastName" | "profileImage",
    value: string
  ) => void;
};

export default function ProfileImageSelection(
  props: ProfileImageSelectionProps
) {
  const ScrollViewRef = useRef<HTMLDivElement>(null);

  // Scroll the div to the top on re mount
  useEffect(() => {
    if (ScrollViewRef.current) ScrollViewRef.current.scrollTop = 0;
  });

  return (
    <ProfileImageSelectionContainer shown={props.shown}>
      <ScrollView ref={ScrollViewRef}>
        {userImages.map((image) => (
          <ProfileImage
            url={image}
            size={65}
            onClick={() => props.handleChange("profileImage", image)}
            key={image}
          />
        ))}
      </ScrollView>
    </ProfileImageSelectionContainer>
  );
}

type ProfileImageSelectionContainerProps = {
  shown: boolean;
};

const ProfileImageSelectionContainer = styled.div`
  visibility: ${(props: ProfileImageSelectionContainerProps) =>
    props.shown ? "visible" : "hidden"};
  opacity: ${(props: ProfileImageSelectionContainerProps) =>
    props.shown ? "1" : "0"};

  transition: opacity ease 300ms;
  width: 250px;
  height: 350px;

  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 25px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);

  position: absolute;
  left: -167px;
  top: 30px;

  @media (max-width: 1100px) {
    width: 180px;
    height: 400px;
    left: -132px;
  }
  @media (max-width: 550px) {
    width: 350px;
    height: 420px;

    top: 25px;
    left: -103px;
  }
  @media (max-width: 389px) {
    width: 300px;
  }
  @media (max-width: 340px) {
    width: 250px;
    left: -110px;
  }
`;

const ScrollView = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  overflow-y: scroll;
  padding: 17px 0;

  &::-webkit-scrollbar {
    display: none;
  }

  &::after {
    content: "";
    width: 95%;
    height: 25px;
    position: absolute;
    bottom: 0;
    border-radius: 0 0 25px 25px;
    background-color: #fff;
    filter: blur(4px);
  }

  &::before {
    content: "";
    width: 95%;
    height: 25px;
    position: absolute;
    top: 0;
    border-radius: 25px 25px 0 0;
    filter: blur(4px);
    background-color: #fff;
  }
`;
