import styled from "styled-components";

type ProductPriceProps = {
  price:
    | {
        value: number;
        top?: string;
        bottom?: string;
      }
    | undefined;
  number: number;
};

export default function ProductPrice(props: ProductPriceProps) {
  return (
    <Container>
      <Price>
        {props.price
          ? props.number !== 0
            ? `$${props.number}`
            : "Free"
          : ""}
      </Price>
      <SideContainer>
        <SideElement>{props.price && props.price.top}</SideElement>
        <SideElement>{props.price && props.price.bottom}</SideElement>
      </SideContainer>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const Price = styled.div`
  font-family: "Cabin", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  color: #272725;
  user-select: none;
  min-height: 60px;
`;

const SideContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  right: -40px;
  top: 0;
  height: 50px;
  justify-content: flex-end;
  user-select: none;
`;

const SideElement = styled.p`
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #000000;
`;
