import styled from "styled-components";
import { ScreenSubText } from "../../../utilities/ScreenText";

type ProductHowToItemProps = {
  heading: string;
  text: string;
};

export default function ProductHowToItem(props: ProductHowToItemProps) {
  return (
    <Container>
      <Heading>{props.heading}</Heading>
      <Text>{props.text}</Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffefd7;
  padding: 15px 30px;
  border-radius: 15px;
  width: 80%;
  gap: 10px;
  align-self: center;

  @media (max-width: 1000px) {
    width: 90%;
  }
  @media (max-width: 500px) {
    width: auto;
  }
`;

const Heading = styled(ScreenSubText)`
  font-size: 25px;
  font-weight: 500;
  text-align: left;
`;

const Text = styled(ScreenSubText)`
  text-align: left;
`;
