import styled from "styled-components";

type ScreenHeadingProps = {
  size?: "small" | "big";
};

export const ScreenHeading = styled.div`
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props: ScreenHeadingProps) =>
    props.size === "small" ? "60px" : "70px"};
  color: #272725;
  display: inline-block;
  margin: 0;

  @media (max-width: 900px) {
    font-size: ${(props: ScreenHeadingProps) =>
    props.size === "small" ? "50px" : "70px"};
  }

  @media (max-width: 600px) {
    font-size: ${(props: ScreenHeadingProps) =>
    props.size === "small" ? "45px" : "65px"};
  }
  @media (max-width: 500px) {
    font-size: ${(props: ScreenHeadingProps) =>
    props.size === "small" ? "45px" : "54px"};
  }
  @media (max-width: 400px) {
    font-size: ${(props: ScreenHeadingProps) =>
    props.size === "small" ? "45px" : "44px"};
  }
  @media (max-width: 350px) {
    font-size: ${(props: ScreenHeadingProps) =>
    props.size === "small" ? "40px" : "44px"};
  }
`;

export const ScreenSubHeading = styled.p`
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  margin: 0;
  color: #272725;
`;

export const ScreenSubText = styled.p`
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  margin: 0;
  opacity: 0.8;
  @media (max-width: 600px) {
    font-size: 20px;
  }
  @media (max-width: 500px) {
    font-size: 18px;
  }
  @media (max-width: 400px) {
    font-size: 16px;
  }
`;
