import styled from "styled-components";
import Footer from "../components/footer/Footer";
import NavBar from "../components/navbar/NavBar";
// import ChatBubble from "../components/utilities/chat_bubble/ChatBubble";
import ProductLanding from "../components/screen_components/product/ProductLanding";
import SignUpNowSection from "../components/screen_components/product/ChatWithUsNowSection";
import ProductSection from "../components/screen_components/product/ProductSection";
import Waves from "../assets/images/waves/orange_waves.svg";
import ProductInfoSection from "../components/screen_components/product/info/ProductInfoSection";
import ProductDownloadLinkSection from "../components/screen_components/product/ProductDownloadLinkSection";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { collection, query, where } from "firebase/firestore";
import {
  DownloadLink,
  PageData,
  SellableProduct,
  Subject,
} from "../data/databaseTypes";
import ProductSubjectSection from "../components/screen_components/product/ProductSubjectSection";
import ProductWhyUsSection from "../components/screen_components/product/ProductWhyUsSection";
import ProductHowToSection from "../components/screen_components/product/howto/ProductHowToSection";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useEffect } from "react";
import ProductFAQ from "../components/screen_components/product/ProductFAQ";
import AssignmentUploadPopup from "../components/screen_components/product/upload/AssignmentUploadPopup";

type TutoringScreenProps = {
  isScroll: boolean;
  scrollerContainer: OverlayScrollbarsComponent | null;
};

export default function ProductScreen(props: TutoringScreenProps) {

  useEffect(() => {
    props.scrollerContainer?.osInstance()?.scroll(0, 0);
  }, [props.scrollerContainer]);
  
  const firestore = useFirestore();
  const productsInfoCollection = collection(firestore, "products_info");

  const productsQuery = query(
    productsInfoCollection,
    where("page", "==", "product")
  );
  const { data: dbProductInfo } = useFirestoreCollectionData(productsQuery);

  const productInfo = dbProductInfo as PageData[];

  const productSellable: false | SellableProduct[] =
    productInfo && productInfo.length === 1 && productInfo[0].sellable;

  const productDownloadable: false | DownloadLink[] =
    productInfo && productInfo.length === 1 && productInfo[0].downloadable;

  const productSubject: false | Subject[] =
    productInfo && productInfo.length === 1 && productInfo[0].subjects;

  return (
    <>
    <AssignmentUploadPopup />
    <PageContainer>
      <Background>
        <NavBar scroll={props.isScroll} />
        <div>
          <ProductLanding />
          <ProductInfoSection />
          <ProductDownloadLinkSection downloadable={productDownloadable} />
          <ProductHowToSection />
          <ProductSection sellable={productSellable} />
          <SignUpNowSection />
          <ProductSubjectSection subjects={productSubject} />
          <ProductWhyUsSection />
          <ProductFAQ />
        </div>
        {/* <ChatBubble /> */}
        <Footer background={"FFF"} />
      </Background>
    </PageContainer>
    </>
  );
}

const PageContainer = styled.div`
  width: 100%;
  background-color: #fff;
  position: relative;
`;

const Background = styled.div`
  background-color: #fff;
  background-image: url(${Waves});
  background-repeat: repeat-x;
  background-position: center top;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
