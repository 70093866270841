import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TutorData, emptyTutor } from "../data/databaseTypes";

type tutorProfilePopupSliceType = {
  open: boolean;
  tutor: TutorData;
};

const initialState: tutorProfilePopupSliceType = {
  open: false,
  tutor: emptyTutor,
};

export const tutorProfilePopupSlice = createSlice({
  name: "tutorProfilePopupData",
  initialState,
  reducers: {
    openPopup: (state, action: PayloadAction<TutorData>) => {
      document.body.classList.add("modal-open");
      state.open = true;
      state.tutor.uid = action.payload.uid;
      state.tutor.role = action.payload.role;
      state.tutor.description = action.payload.description;
      state.tutor.subject = action.payload.subject;
      state.tutor.availability = action.payload.availability;
      state.tutor.achievement = action.payload.achievement;
      state.tutor.atar = action.payload.atar;
      state.tutor.firstName = action.payload.firstName;
      state.tutor.lastName = action.payload.lastName;
      state.tutor.profileImage = action.payload.profileImage;
      state.tutor.type = action.payload.type;
    },
    closePopup: (state) => {
      document.body.classList.remove("modal-open");
      state.open = false;
      state.tutor = emptyTutor;
    },
  },
});

// Action creators are generated for each case reducer function
export const { openPopup, closePopup } = tutorProfilePopupSlice.actions;

export default tutorProfilePopupSlice.reducer;
