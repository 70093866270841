import styled from "styled-components";
import { PopupContainer } from "../components/utilities/Popup";
import ChatUserPanel from "../components/popups/chat/ChatUserPanel";
import ChatMainPanel from "../components/popups/chat/ChatMainPanel";

import { useFirestore, useFirestoreCollectionData, useUser } from "reactfire";
import { collection, query, where } from "firebase/firestore";
import { ChatroomData, emptyChatroom } from "../data/databaseTypes";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";

import { CloseButton } from "../components/utilities/button/CloseButton";
import { closeChat } from "../redux/chatDataSlice";
import closeIcon from "../assets/images/utilities/close.svg";
import { useRef } from "react";

export default function ChatScreen() {
  const dispatch = useDispatch();
  const firestore = useFirestore();

  const userId = useSelector((state: RootState) => state.userData.uid);

  const chatroomCollection = collection(firestore, "chatrooms");
  const chatroomQuery = query(
    chatroomCollection,
    where("userIds", "array-contains", userId)
  );
  const { data: chatrooms } = useFirestoreCollectionData(chatroomQuery, {
    idField: "id",
  });

  const { data: user } = useUser();

  const chatData = useSelector((state: RootState) => state.chatData);

  const open = chatData.open;

  // Reference to main panel function to run on close chat
  const closeChatRef = useRef<any>(null);

  const close = () => {
    if (closeChatRef.current) closeChatRef.current();
    dispatch(closeChat());
  };

  const chosenChatroom = chatrooms
    ? chatrooms.find((c) => c.id === chatData.selectedChatroom)
    : emptyChatroom;

  return (
    <DarkBackground
      style={{
        opacity: open ? 1 : 0,
        pointerEvents: open ? "initial" : "none",
      }}
    >
      <PageContainer style={{ top: open ? 0 : "100vw" }}>
        {user && chatrooms && (
          <PopupContainer>
            <CloseButton onClick={close}>
              <img src={closeIcon} alt="Close Button" />
            </CloseButton>
            <ChatUserPanel chatrooms={chatrooms as ChatroomData[]} />
            {chosenChatroom && (
              <ChatMainPanel
                chatroom={chosenChatroom as ChatroomData}
                closeChat={closeChatRef}
              />
            )}
          </PopupContainer>
        )}
      </PageContainer>
    </DarkBackground>
  );
}

const DarkBackground = styled.div`
  position: fixed;
  z-index: 100;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.3s ease;
`;

const PageContainer = styled.div`
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  transition: 0.3s cubic-bezier(0, 0, 0.39, 0.98);
`;
