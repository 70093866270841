import styled from "styled-components";
import InfoItem from "./InfoItem";
import shape1 from "../../../../assets/images/shapes/shape1.svg";
import shape2 from "../../../../assets/images/shapes/shape2.svg";
import shape3 from "../../../../assets/images/shapes/shape3.svg";

type InfoSectionProps = {
  windowWidth: number;
};

export default function InfoSection(props: InfoSectionProps) {
  return (
    <InfoContainer>
      <InfoCollection>
        <InfoItem
          className={props.windowWidth < 620 ? "" : "info-item-lax-1"}
          shape={shape1}
          heading="CLARITY"
          subtext="Crystal clear explanations, feedback and solutions, personalised to you so that you can excel."
        />
        <InfoItem
          className={props.windowWidth < 620 ? "" : "info-item-lax-2"}
          shape={shape2}
          heading="VALUE"
          subtext="Be 100% satisfied with our comprehensive support, with tutors who will stick with you to the end."
        />
        <InfoItem
          className={props.windowWidth < 620 ? "" : "info-item-lax-3"}
          shape={shape3}
          heading="TRUST"
          subtext="Our tutors are accomplished and approachable; plus, we're completely transparent with everything we do."
        />
      </InfoCollection>
    </InfoContainer>
  );
}

const InfoContainer = styled.div`
  width: 100%;
  min-height: 800px;
  height: 800px;
  padding: 60px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 90px;
  position: relative;

  @media (max-width: 910px) {
    min-height: 1300px;
  }

  @media (max-width: 619px) {
    min-height: 1700px;
  }
`;

const InfoCollection = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 125px;
`;
