import styled from "styled-components";
import Background from "../assets/images/background/background404.svg";
import { useHistory } from "react-router-dom";
import BackButton from "../components/utilities/button/BackButton";
import { useEffect } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

type FooterInfoScreenProps = {
  scrollerContainer: OverlayScrollbarsComponent | null;
  type: "terms" | "privacy" | "contactus";
};

export default function FooterInfoScreen(props: FooterInfoScreenProps) {
  const history = useHistory();

  useEffect(() => {
    props.scrollerContainer?.osInstance()?.scroll(0, 0);
  }, [props.scrollerContainer]);

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const onClickUrl = (url: string) => {
    return () => openInNewTab(url);
  };

  return (
    <PageContainer>
      <BackButton onClick={() => history.goBack()} />
      {props.type === "terms" ? (
        <Content>
          <Title>Clarify Tutoring's Terms of use</Title>
          <Text>Welcome To Clarify Tutoring!</Text>
          <Text>
            These terms and conditions outline the rules and regulations for the
            use of Clarify Tutoring's Website, located at clarifytutoring.com.
          </Text>
          <Text>
            By accessing this website, we assume you accept these terms and
            conditions. Do not continue to use Clarify Tutoring if you do not
            agree to take all of the terms and conditions stated on this page.
          </Text>
          <Text>
            The following terminology applies to these Terms and Conditions,
            Privacy Statement and Disclaimer Notice and all Agreements:
            “Client”, “You” and “Your” refers to you, the person log on this
            website and compliant to The Business's terms and conditions. “The
            Business”, “Ourselves”, “We”, “Our” and “Us”, refers to our
            Business. “Party”, “Parties”, or “Us”, refers to both the Client and
            ourselves. All terms refer to the offer, acceptance and
            consideration of payment necessary to undertake the process of our
            assistance to the Client in the most appropriate manner for the
            express purpose of meeting the Client's needs in respect of
            provision of the Business's stated services, in accordance with and
            subject to, prevailing law of Australia. Any use of the above
            terminology or other words in the singular, plural, capitalization
            and/or he/she or they, are taken as interchangeable and therefore as
            referring to the same.
          </Text>
          <SubTitle>LICENCE</SubTitle>
          <Text>
            Unless otherwise stated, Clarify Tutoring and/or its licensors own
            the intellectual property rights for all material on
            clarifytutoring.com. All intellectual property rights are reserved.
            You may access this from Clarify Tutoring for your own personal use
            subjected to restrictions set in these terms and conditions.
          </Text>
          <Text>You must not:</Text>
          <List>
            <li>Republish material from Clarify Tutoring</li>
            <li>Sell, rent or sub-license material from Clarify Tutoring</li>
            <li>Reproduce, duplicate or copy material from Clarify Tutoring</li>
            <li>Redistribute content from Clarify Tutoring</li>
          </List>
          <Text>This Agreement shall begin on the date hereof.</Text>
          <SubTitle>USER REGISTRATION</SubTitle>
          <Text>
            You may be required to register with the Website. You agree to keep
            your password confidential and will be responsible for all use of
            your account and password. We reserve the right to remove, reclaim,
            or change a username you select if we determine, in our sole
            discretion, that such username is inappropriate, obscene, or
            otherwise objectionable.
          </Text>
          <SubTitle>PROHIBITED ACTIVITIES</SubTitle>
          <Text>
            You may not access or use the Website for any purpose other than
            that for which we make the Website available. The Website may not be
            used in connection with any commercial endeavours except those that
            are specifically endorsed or approved by us.
          </Text>
          <Text>As a user of the Website, you agree not to:</Text>
          <List>
            <li>
              Systematically retrieve data or other content from the Website to
              create or compile, directly or indirectly, a collection, database,
              or directory without written permission from us
            </li>
            <li>
              Trick, defraud, or mislead us and other users, especially in any
              attempt to learn sensitive account information such as user
              passwords.
            </li>
            <li>
              Upload of transmit (or attempt to upload or to transmit) viruses,
              or other material, including excess use of capital letters and
              spamming, that interferes with the functions of the Website.
            </li>
            <li>
              Harass, annoy, intimidate, or threaten any of our employees or
              agents engaged in providing any portion of the Website to you.
            </li>
            <li>
              Copy or adapt the Website's software, including by not limited to
              HTML, TypeScript, or other code.
            </li>
            <li>
              Make improper use of our support services or submit false reports
              of abuse or misconduct
            </li>
          </List>
          <SubTitle>ASSIGNMENT FEEDBACK</SubTitle>
          <Text>
            As the Client, you have legal ownership of your own work. The work
            you submit will not be plagiarised, passed off as the company's own,
            distributed or used for any personal or commercial purposes in any
            circumstances unless directly given consent by the Client to do so,
            to protect privacy and uphold fairness.
          </Text>
          <Text>
            Clarify Tutoring's Assignment Feedback service will not breach
            academic integrity and write the entire, or parts of, the Client's
            work. Feedback, mistake correction and improvement suggestions will
            be performed based on what the Client has provided via upload.
          </Text>
          <Text>
            Clarify Tutoring does not claim responsibility for differences
            between the predicted score as estimated by the tutor and the real
            score awarded by the Client's individual teachers. The score and
            feedback given are purely estimations and suggestions which aim to
            replicate the feedback that one would be given at school.
          </Text>
          <Text>
            Clarify Tutoring is not a part of and does not hold affiliations
            with any school or other educational organisations. Any feedback and
            scores received by the Client from a tutor is unofficial.
          </Text>
          <SubTitle>TUTORING</SubTitle>
          <Text>
            The Client must agree to uphold communications with a Clarify
            Tutoring's tutor once established, including, but not limited to
            arranging meeting times, confirming meeting times, providing
            adequate cancellation notice, and more.
          </Text>
          <Text>
            The Client must agree to act in a manner with their assigned Clarify
            Tutoring tutor that is not harmful, provocative or deceptive,
            including, but not limited to harassment, bullying, and more. A
            tutor who believes the Client is in violation of this term, or for
            any other legitimate reason, may request to cease their operations
            with the Client. Clarify Tutoring executives will be responsible for
            determining the legitimacy of the cessation of tutoring between the
            Client and tutor.
          </Text>
          <Text>
            The Client may raise any concerns regarding their tutoring
            experience, including, but not limited to harassment, bullying, poor
            quality of tutoring and more by contacting Clarify Tutoring, either
            via email or other suitable communication method. Clarify Tutoring
            executives will deliberate to decide the outcome of the raised
            concern.
          </Text>
          <SubTitle>REFUND AND CANCELLATION POLICY</SubTitle>
          <Text>
            Clients (and their parents or guardians alike) are unable to obtain
            full refunds for Clarify Tutoring's Assignment Feedback service.
            However, Students are eligible for a 40% partial refund if a
            legitimate reason for their withdrawal is provided within 12 hours
            of their first submission. This does not apply to Students signed up
            to the Rapid Assignment Tweak (RAT). The legitimacy of the reason
            will be determined by Clarify Tutoring.
          </Text>
          <Text>
            Clients are able to cancel online tutoring sessions, provided that
            notice is given at least 1 hour before the scheduled meeting time.
            In-person sessions must receive at least a 4 hour cancellation
            notice. A cancellation request made after the cancellation notice
            period has passed will not waive the payment for the tutoring
            session.
          </Text>
          <SubTitle>PRIVACY POLICY</SubTitle>
          <Text>
            We care about data privacy and security. Please review our Privacy
            Policy: clarifytutoring.com/privacy. By using the Website, you agree
            to be bound by our Privacy Policy, which is incorporated into these
            Terms and Conditions.
          </Text>
          <SubTitle>REMOVAL OF LINKS FROM OUR WEBSITE</SubTitle>
          <Text>
            If you find any link on our Website that is offensive for any
            reason, you are free to contact and inform us any moment. We will
            consider requests to remove links but we are not obligated to or so
            or to respond to you directly.
          </Text>
          <Text>
            We do not ensure that the information on this website is correct, we
            do not warrant its completeness or accuracy; nor do we promise to
            ensure that the website remains available or that the material on
            the website is kept up to date.
          </Text>
          <SubTitle>DISCLAIMER</SubTitle>
          <Text>
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties and conditions relating to our website
            and the use of this website. Nothing in this disclaimer will:
          </Text>
          <List>
            <li>
              limit or exclude our or your liability for death or personal
              injury;
            </li>
            <li>
              limit or exclude our or your liability for fraud or fraudulent
              misrepresentation;
            </li>
            <li>
              limit any of our or your liabilities in any way that is not
              permitted under applicable law; or
            </li>
            <li>
              exclude any of our or your liabilities that may not be excluded
              under applicable law.
            </li>
          </List>
          <Text>
            The limitations and prohibitions of liability set in this Section
            and elsewhere in this disclaimer: (a) are subject to the preceding
            paragraph; and (b) govern all liabilities arising under the
            disclaimer, including liabilities arising in contract, in tort and
            for breach of statutory duty.
          </Text>
          <Text>
            As long as the website and the information and services on the
            website are provided free of charge, we will not be liable for any
            loss or damage of any nature.
          </Text>
          <SubTitle>HYPERLINKING TO OUR CONTENT</SubTitle>
          <Text>
            The following organisations may link to our Website without prior
            written approval:
          </Text>
          <List>
            <li>Government agencies</li>
            <li>Search engines</li>
            <li>News organisations</li>
            <li>
              Online directory distributors may link to our Website in the same
              manner as they hyperlink to the Websites of other listed
              businesses; and
            </li>
            <li>
              System wide Accredited Businesses except soliciting non-profit
              organisations, charity shopping malls, and charity fundraising
              groups which may not hyperlink to our Website.
            </li>
          </List>
          <Text>
            These organisations may link to our home page, to publications or to
            other Website information so long as the link: (a) is not in any way
            deceptive; (b) does not falsely imply sponsorship, endorsement or
            approval of the linking party and its products and/or services; and
            (c) fits within the context of the linking party's site.
          </Text>
          <Text>
            If you are an organisation interested in linking to our website, you
            must inform us by sending an email to Clarify Tutoring. Please
            include your name, your organisation name, contact information as
            well as the URL of your site, a list of any URLs from which you
            intend to link to our Website, and a list of the URLs on our site to
            which you would like to link.
          </Text>
          <Text>
            Approved organisations may hyperlink to our Website as follows:
          </Text>
          <List>
            <li>By use of our business name; or</li>
            <li>By use of the uniform resource locator being linked to; or</li>
            <li>
              By use of any other description of our Website being linked to
              that makes sense within the context and format of content on the
              linking party's site.
            </li>
          </List>
          <Text>
            We will approve link requests from organisations we decide that: (a)
            the link would not make us look unfavourably to ourselves or to our
            accredited businesses; (b) the organisation does not have any
            negative records with us; (c) the benefit to us from the visibility
            of the hyperlink compensates the absence of Clarify Tutoring; and
            (d) the link is in the context of general resource information.
          </Text>
          <Text>
            Organisations may link to our home page so long as the link: (a) is
            not in any way deceptive; (b) does not falsely imply sponsorship,
            endorsement or approval of the linking party and its products or
            services; and (c) fits within the context of the linking party's
            site.
          </Text>
          <Text>
            No use of Clarify Tutoring's logo or other artwork will be allowed
            for linking absent a trademark licence agreement.
          </Text>
          <SubTitle>RESERVATION OF RIGHTS</SubTitle>
          <Text>
            We reserve the right to request that you remove all links or any
            particular link to our Website. You approve to immediately remove
            all links to our Website upon request. We also reserve the right to
            amend these terms and conditions and it's linking policy at any
            time. By continuously linking to our Website, you agree to be bound
            to and follow these linking terms and conditions.
          </Text>
          <Text>Updated by the Clarify Team on the 11 Mar 2022</Text>
        </Content>
      ) : props.type === "privacy" ? (
        <Content>
          <Title>Clarify Tutoring's Privacy Policy</Title>
          <Text>
            Clarify Tutoring is committed to providing quality services to you
            and this policy outlines our ongoing obligations to you in respect
            of how we manage your Personal Information.
          </Text>
          <Text>
            We have adopted the Australian Privacy Principles (APPs) contained
            in the Privacy Act 1988 (Cth) (the Privacy Act). The NPPs govern the
            way in which we collect, use, disclose, store, secure and dispose of
            your Personal Information.
          </Text>
          <Text>
            A copy of the Australian Privacy Principles may be obtained from the
            website of The Office of the Australian Information Commissioner at
            www.aoic.gov.au
          </Text>
          <SubTitle>
            What is Personal Information and why do we collect it?
          </SubTitle>
          <Text>
            Personal Information is information or an opinion that identifies an
            individual. Examples of Personal Information we collect include:
            names, addresses, email addresses, phone and facsimile numbers.
          </Text>
          <Text>
            This Personal Information is obtained in many ways including
            correspondence, by telephone, by email, via our website
            clarifytutoring.com and from third parties. We don't guarantee
            website links or policy of authorised third parties.
          </Text>
          <Text>
            We collect your Personal Information for the primary purpose of
            providing our services to you, providing information to our clients
            and marketing. We may also use your Personal Information for
            secondary purposes closely related to the primary purpose, in
            circumstances where you would reasonably expect such use or
            disclosure. You may unsubscribe from our mailing/marketing lists at
            any time by contacting us in writing.
          </Text>
          <Text>
            When we collect Personal Information we will, where appropriate and
            where possible, explain to you why we are collecting the information
            and how we plan to use it.
          </Text>
          <SubTitle>Sensitive Information</SubTitle>
          <Text>
            Sensitive information is defined in the Privacy Act to include
            information or opinion about such things as an individual's racial
            or ethnic origin, political opinions, membership of a political
            association, religious or philosophical beliefs, membership of a
            trade union or other professional body, criminal record or health
            information.
          </Text>
          <Text>Sensitive information will be used by us only:</Text>
          <List>
            <li>For the primary purpose for which it was obtained</li>
            <li>
              For a secondary purpose that is directly related to the primary
              purpose
            </li>
            <li>With your consent; or where required or authorised by law.</li>
          </List>
          <SubTitle>Third Parties</SubTitle>
          <Text>
            Where reasonable and practicable to do so, we will collect your
            Personal Information only from you. However, in some circumstances
            we may be provided with information by third parties. In such a case
            we will take reasonable steps to ensure that you are made aware of
            the information provided to us by the third party.
          </Text>
          <SubTitle>Disclosure of Personal Information</SubTitle>
          <Text>
            Your Personal Information may be disclosed in a number of
            circumstances including the following:
          </Text>
          <List>
            <li>
              Third parties where you consent to the use or disclosure; and
            </li>
            <li>Where required or authorised by law.</li>
          </List>
          <SubTitle>Security of Personal Information</SubTitle>
          <Text>
            Your Personal Information is stored in a manner that reasonably
            protects it from misuse and loss and from unauthorized access,
            modification or disclosure.
          </Text>
          <Text>
            When your Personal Information is no longer needed for the purpose
            for which it was obtained, we will take reasonable steps to destroy
            or permanently de-identify your Personal Information.{" "}
          </Text>
          <Text>
            However, most of the Personal Information is or will be stored in
            client files which will be kept by us for a minimum of 7 years.
          </Text>
          <SubTitle>Access to your Personal Information</SubTitle>
          <Text>
            You may access the Personal Information we hold about you and to
            update and/or correct it, subject to certain exceptions. If you wish
            to access your Personal Information, please contact us in writing.
          </Text>
          <Text>
            Clarify Tutoring will not charge any fee for your access request,
            but may charge an administrative fee for providing a copy of your
            Personal Information.
          </Text>
          <Text>
            In order to protect your Personal Information we may require
            identification from you before releasing the requested information.
          </Text>
          <SubTitle>
            Maintaining the Quality of your Personal Information
          </SubTitle>
          <Text>
            It is an important to us that your Personal Information is up to
            date. We will take reasonable steps to make sure that your Personal
            Information is accurate, complete and up-to-date. If you find that
            the information we have is not up to date or is inaccurate, please
            advise us as soon as practicable so we can update our records and
            ensure we can continue to provide quality services to you.
          </Text>
          <SubTitle>Policy Updates</SubTitle>
          <Text>
            This Policy may change from time to time and is available on our
            website.
          </Text>
          <SubTitle>Privacy Policy Complaints and Enquiries</SubTitle>
          <Text>
            If you have any queries or complaints about our Privacy Policy
            please contact us at:
          </Text>
          <Text>clarifytutors@gmail.com</Text>
          <Text>Updated by the Clarify Team on the 11 Mar 2022</Text>
        </Content>
      ) : (
        <Content>
          <Title>Contact us</Title>
          <Text>Clarify's preferred method of contact for the quickest response is through our chat feature</Text>
          <Text>
            For any enquiries you can contact us through any of the following:
          </Text>
          <List>
            <li>
              <span
                style={{ cursor: "pointer" }}
                onClick={onClickUrl(
                  "https://www.instagram.com/clarifytutoring/"
                )}
              >
                Instagram
              </span>
            </li>
            <li>
              <span
                style={{ cursor: "pointer" }}
                onClick={onClickUrl(
                  "https://www.facebook.com/clarifytutoring/"
                )}
              >
                Facebook
              </span>
            </li>
            <li>
              <span
                style={{ cursor: "pointer" }}
                onClick={onClickUrl(
                  "https://www.linkedin.com/company/clarifytutoring"
                )}
              >
                LinkedIn
              </span>
            </li>
          </List>
          <Text>
            If you would like to contact us via writing, you may email to us at:
          </Text>
          <Text>clarifytutors@gmail.com</Text>
        </Content>
      )}
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  background-image: url(${Background});
  background-color: #fff;
`;

const Content = styled.div`
  display: flex;
  padding: 50px;
  flex-direction: column;
  gap: 20px;
  font-family: "Raleway", sans-serif;
  max-width: 1000px;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0.7);
  @media (max-width: 500px) {
    padding: 70px 25px;
  }
`;

const Title = styled.p`
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 40px;
  color: #000000;
  margin-bottom: 50px;
  align-self: center;
`;

const SubTitle = styled.p`
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 22px;
  color: #000000;
  align-self: flex-start;
`;

const Text = styled.p`
  text-align: justify;
  text-justify: inter-word;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #000000;
`;

const List = styled.ul`
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #000000;
`;
