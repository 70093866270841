import styled from "styled-components";
import tracks from "../../../../assets/images/background/home-tracks-main.svg";
import RailwayItem from "./RailwayItem";
import blueTrack from "../../../../assets/images/background/home-track-mobile-blue.svg";
import trackIntersect from "../../../../assets/images/background/home-track-mobile-intersect.svg";
import trackMerge from "../../../../assets/images/background/home-track-mobile-merge.svg";
import redTrack from "../../../../assets/images/background/home-track-mobile-red.svg";
import yellowTrack from "../../../../assets/images/background/home-track-mobile-yellow.svg";

type HomeRailwayProps = {
  windowWidth: number;
};

export default function HomeRailway(props: HomeRailwayProps) {
  return (
    <PageContainer>
      <ItemHolder>
        <MeetClarify>
          <RailwayItem
            heading={"Meet **Clarify Tutoring**."}
            text={`Every gemstone has their shine. Clarify Tutoring will help cut,
           refine and shape to perfection so you can **transform a subdued 
           shimmer into a brilliant sheen.** We aim to guide you through 
           your ATAR journey with supplementary services and resources crafted 
           by our qualified team of tutors. With relevant skills, techniques 
           and experience at our disposal, we're fully committed to helping you 
           achieve your very best.`}
            icon={""}
          />
        </MeetClarify>
        <Help>
          <p>Let Us </p>
          <p>
            <b> Help You Reach Your Destination</b>
          </p>
        </Help>
        <Services>
          <RailwayItem
            heading={"**Services**."}
            text={`Become a stellar student with our range of services that cater 
        to your various needs. We follow a simple 3-step process to help you go
         above and beyond: **Clarify**, **Refine** and **Apply**.`}
            icon={"graph"}
            hover={"#435E7B"}
          />
        </Services>

        <Mobile show={props.windowWidth <= 875}>
          <MobileHelp>
            <p>Let Us </p>
            <p>
              <b> Help You Reach Your Destination</b>
            </p>
          </MobileHelp>
          <MobileServices>
            <RailwayItem
              heading={"**Services**."}
              text={`Become a stellar student with our range of services that cater 
        to your various needs. We follow a simple 3-step process to help you go
         above and beyond: **Clarify**, **Refine** and **Apply**.`}
              icon={"graph"}
              hover={"#15375B"}
            />
          </MobileServices>
        </Mobile>

        <Chat>
          <RailwayItem
            heading={"**Clarify Chat**."}
            text={`Ready, aim and fire. With our 24/7 built-in chat service, connect
         with top tutors who'll make sure you don't miss any of your shots. Try 
         our features for **FREE** to see why we have a 100% satisfaction 
         guarantee.`}
            icon={"chat"}
            hover={"#C1D8F3"}
          />
        </Chat>
        <Tutoring>
          <RailwayItem
            heading={"**Tutoring**."}
            text={`Reach full throttle with our accomplished tutors so you can 
            blastoff towards success. Our tutors are recent graduates, equipped 
            with 99+ ATARs, 90+ subject scores and 200+ hours of tutoring 
            experience. Your senior years only happen once, so we're ready to 
            help you make them the best.
            `}
            icon={"teacher"}
            hover={"#FFB6B6"}
          />
        </Tutoring>
        <Assignment>
          <RailwayItem
            heading={"**Assignment Feedback**."}
            text={`Ambiguous feedback is frustrating. That's why we've developed a 
        detailed and criteria-specific feedback and marking service. Don't 
        waste time wondering how you could improve. Let us do that for you.`}
            icon={"note"}
            hover={"#FFDDAA"}
          />
        </Assignment>
      </ItemHolder>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100%;
  min-height: 2000px;
  height: 2000px;
  z-index: 3;
  background: url(${tracks});
  background-repeat: no-repeat;
  background-position: 50% 25%;
  background-size: 2000px;
  background-color: #fff7ef;
  display: flex;
  justify-content: center;
  cursor: default;

  position: sticky;
  top: calc(-2000px + 100vh);

  @media (max-width: 1000px) {
    top: calc(-1800px + 100vh);
    background-size: 1875px;
    background-position: -450px -230px;
    min-height: 1800px;
    height: 1800px;
  }
  @media (max-width: 875px) {
    position: relative;
    top: 0;
    background-image: none;
    min-height: auto;
    height: auto;
  }
`;

const ItemHolder = styled.div`
  width: 1000px;
  position: relative;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const MeetClarify = styled.div`
  z-index: 1;
  position: absolute;
  left: 14%;
  top: 150px;
  width: 500px;

  @media (max-width: 1000px) {
    width: 400px;
    left: 150px;
    top: 100px;
  }
  @media (max-width: 875px) {
    position: sticky;
    position: -webkit-sticky;
    background-color: #fff7ef;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
    background-image: url(${blueTrack});
  }
  @media (max-width: 600px) {
    padding: 50px;
    padding-left: 65px;
  }
`;

const Help = styled.div`
  z-index: 2;
  font-family: "Raleway", sans-serif;
  font-size: 30px;
  color: #000000;
  width: 250px;
  position: absolute;
  top: 550px;
  left: 70%;

  & > p {
    margin: 0;
    width: 250px;
  }

  @media (max-width: 1000px) {
    left: 625px;
    top: 450px;
  }
  @media (max-width: 875px) {
    display: none;
  }
`;

const Services = styled.div`
  z-index: 3;
  position: absolute;
  top: 750px;
  left: 40%;
  width: 500px;

  @media (max-width: 1000px) {
    top: 650px;
    left: 430px;
    width: 400px;
  }
  @media (max-width: 875px) {
    display: none;
  }
`;

const Chat = styled.div`
  z-index: 4;
  position: absolute;
  top: 1155px;
  left: 51%;
  width: 475px;

  @media (max-width: 1000px) {
    top: 1045px;
    left: 490px;
    width: 370px;
  }
  @media (max-width: 875px) {
    position: sticky;
    position: -webkit-sticky;
    background-color: #fff7ef;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
    background-image: url(${blueTrack});
  }
  @media (max-width: 600px) {
    padding: 50px;
    padding-left: 65px;
  }
`;

const Tutoring = styled.div`
  z-index: 5;
  position: absolute;
  top: 1560px;
  left: 51%;
  width: 475px;

  @media (max-width: 1000px) {
    top: 1420px;
    left: 490px;
    width: 370px;
  }
  @media (max-width: 875px) {
    position: sticky;
    position: -webkit-sticky;
    background-color: #fff7ef;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
    background-image: url(${redTrack});
  }
  @media (max-width: 600px) {
    padding: 50px;
    padding-left: 65px;
  }
`;

const Assignment = styled.div`
  z-index: 6;
  position: absolute;
  top: 1365px;
  left: 12%;
  width: 200px;

  @media (max-width: 1000px) {
    top: 1230px;
    left: 145px;
    width: 195px;
  }
  @media (max-width: 875px) {
    position: sticky;
    position: -webkit-sticky;
    background-color: #fff7ef;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
    background-image: url(${yellowTrack});
  }
  @media (max-width: 600px) {
    padding: 50px;
    padding-left: 65px;
  }
`;

type MobileProps = {
  show: boolean;
};

const Mobile = styled.div`
  display: ${(props: MobileProps) => (props.show ? "block" : "none")};
  position: sticky;
  position: -webkit-sticky;
  top: -100vh;
  z-index: 2;
`;

const MobileHelp = styled.div`
  z-index: 2;
  position: relative;
  font-family: "Raleway", sans-serif;
  font-size: 30px;
  color: #000000;
  background-color: #fff7ef;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 100px;
  background-image: url(${trackIntersect});
  & > p {
    margin: 0;
    width: auto;
  }
  @media (max-width: 775px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 600px) {
    padding: 50px;
    padding-left: 65px;
  }
`;

const MobileServices = styled.div`
  z-index: 3;
  position: relative;
  font-family: "Raleway", sans-serif;
  font-size: 30px;
  color: #000000;
  background-color: #fff7ef;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px;
  background-image: url(${trackMerge});

  @media (max-width: 600px) {
    padding: 50px;
    padding-left: 65px;
  }
`;
