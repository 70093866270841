import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import styled from "styled-components";
import logo from "../../../assets/images/clarify/logo_with_text.svg";
import { isValidEmail } from "../../../scripts/isValidEmail";
import SecondaryButton from "../../utilities/button/SecondaryButton";
import Spinner from "../../utilities/Spinner";
import Textbox from "../../utilities/textbox/Textbox";
import BackButton from "../../utilities/button/BackButton";

type ResetPasswordProps = {
  openLogin: () => void;
};

export default function ResetPassword(props: ResetPasswordProps) {
  const [emailData, setEmailData] = useState<string>("");
  const [isError, setIsError] = useState<"default" | "error" | "success">(
    "default"
  );
  const [checkingForValidity, setCheckingForValidity] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const auth = getAuth();
  const handleType = (value: string) => {
    setEmailData(value);
  };

  const resetValidityCheck = () => {
    return emailData && isValidEmail(emailData);
  };

  const sendResetPasswordEmail = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setCheckingForValidity(true);

    if (resetValidityCheck()) {
      setIsLoading(true);
      setIsError("default");
      sendPasswordResetEmail(auth, emailData)
        .then(() => {
          setCheckingForValidity(false);
          setIsLoading(false);
          setIsError("success");
        })
        .catch((error) => {
          setCheckingForValidity(false);
          setIsLoading(false);
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log("code: " + errorCode + " message: " + errorMessage);
          setIsError("error");
        });
    } else {
      // take care of validity fail
    }
  };

  return (
    <ResetPasswordHolder>
      <BackButton onClick={() => props.openLogin()} />
      <ResetPasswordBox>
        <img src={logo} alt={"logo"} width={"225px"} />
        <Title>
          Reset Password <Spinner loading={isLoading} color={"red"} size={15} />
        </Title>
        <form>
          <div
            onClick={() => setCheckingForValidity(false)}
            style={{ width: "100%" }}
          >
            <Textbox
              placeholder={"email"}
              color={"#6AA6CC4D"}
              outline={"#B8D6E8"}
              value={emailData}
              onChange={(e) => handleType(e.target?.value)}
              id="reset-password-email"
              autocomplete="username"
              name="email"
              required
              type="email"
              handlingValidation={checkingForValidity}
              width={"100%"}
            />
          </div>
          <Subtext isError={isError}>
            {isError === "default"
              ? "A link to reset your password will been sent to the provided email address"
              : isError === "error"
              ? "Error, please check your details and try again"
              : "Success! a link has been sent to your email"}
          </Subtext>

          <SecondaryButton
            name={isError === "success" ? "Resend" : "Reset"}
            color={"#FF9B8D"}
            hover={"#f09185"}
            textColor="#15375B"
            width="160px"
            type={"submit"}
            onClick={sendResetPasswordEmail}
          />
        </form>
      </ResetPasswordBox>
    </ResetPasswordHolder>
  );
}

const ResetPasswordHolder = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
`;

const ResetPasswordBox = styled.div`
  background-color: #ffffff;
  width: 450px;
  border-radius: 35px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 20px rgba(54, 110, 192, 0.25);
  padding: 50px 70px;
  gap: 25px;

  transition: height ease 150ms;

  & > form {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
  }

  & > img {
    user-select: none;
  }

  @media (max-width: 550px) {
    position: absolute;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    height: 100% !important;
    padding: 50px 30px;
  }
`;

type SubtextProps = {
  isError: "default" | "error" | "success";
};

const Subtext = styled.p`
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${(props: SubtextProps) =>
    props.isError === "error"
      ? "red"
      : props.isError === "success"
      ? "green"
      : "#5d646c"};
  cursor: default;
  user-select: none;
  text-align: center;
`;

const Title = styled.div`
  display: flex;
  height: 33px;
  align-items: center;
  gap: 25px;

  font-family: "Raleway", sans-serif;
  font-size: 28px;
  margin: 0;
  font-weight: bold;
  color: #15375b;
  cursor: default;
  user-select: none;
  align-self: flex-start;
`;
