import styled from "styled-components";
import background from "../../../../assets/images/background/blue-mountains.svg";
import { ScreenSubHeading, ScreenSubText } from "../../../utilities/ScreenText";
import QuoteItem from "./QuoteItem";
import Slider from "react-slick";
import "./carousel.css";

export default function TestimonialSection() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <TestimonialContainer>
      <Title>Testimonials</Title>
      <Subheading>Hear what our students think about us.</Subheading>
      <CarouselHolder>
        <Slider {...settings}>
          <ItemHolder>
            <QuoteItem
              circleColor={"#6AA6CC"}
              initials={"Dhruv A"}
              text={
                "I've always struggled when it comes to hard concepts in physics, chemistry and maths. Chethin and Clarify broke down the barriers to truly grasping a difficult concept.  The team at clarify are extremely gifted at being able to explain a concept in a way that a toddler can understand it.  It's gotten to the point where I wish Chethin was my teacher at school! Would recommend Chethin and clarify tutoring in a heartbeat!"
              }
            />
          </ItemHolder>
          <ItemHolder>
            <QuoteItem
              circleColor={"#FF8270"}
              initials={"Georgie L"}
              text={
                "Milind's tutoring contributed significantly to my success in year 12. Milind had a deep understanding of all topics as well as the ISMG requirements. He explained each concept in multiple, detailed ways that were both interesting and effective. We efficiently covered a lot of content in short amounts of time, which was extremely helpful leading up to externals. After each session I felt confident and self-assured - often more so than I did in class. I'm very grateful for Milind's help and I continue to recommend him to my friends."
              }
            />
          </ItemHolder>
          <ItemHolder>
            <QuoteItem
              circleColor={"#FFC770"}
              initials={"Eden T"}
              text={
                "Clarify tutoring is amazing, they were able to help me when others couldn't and they provided a service that was easily accessible, reliable and helpful, I honestly don't know how my questions would've been answered without clarify tutoring, I Can't thank them enough for helping me get through especially my externals"
              }
            />
          </ItemHolder>
          <ItemHolder>
            <QuoteItem
              circleColor={"#FF8270"}
              initials={"Belinda Z"}
              text={
                "I love Clarify tutoring! When I needed last minute help binging for my end of year English exam, they replied so quickly with the right answers. The best thing was that it was all free as well. The absolute GOAT 💪"
              }
            />
          </ItemHolder>
          <ItemHolder>
            <QuoteItem
              circleColor={"#245785"}
              initials={"Daham D"}
              text={
                "Chethin from Clarify Tutoring is an excellent tutor who is friendly, understanding, and supportive. Chethin's detailed yet simple ways of explaining hard concepts in Specialist Maths has made me enjoy and excel at a subject which I used to hate. His feedback on assignments are invaluable and clear problem solving explanations are amazing. Would highly recommend him to those who want to ace Specialist."
              }
            />
          </ItemHolder>
          <ItemHolder>
            <QuoteItem
              circleColor={"#FF8270"}
              initials={"Maya T"}
              text={
                "Khoa is a knowledgeable, approachable and understanding tutor. Going to Khoa for tutoring was honestly one of the best decisions I made to ensure my success in the new atar system. Khoa had a deep understanding of the ISMG for each assignment. He made sure what I wrote in the assignment was hitting criteria and that there was no unnecessary information included. I strongly recommend Khoa to anyone who is seeking assistance with assignments and school content."
              }
            />
          </ItemHolder>
          <ItemHolder>
            <QuoteItem
              circleColor={"#FFC770"}
              initials={"Bella F"}
              text={
                "Clarify Tutoring provides free, accessible and quick support! With their support, I achieved the highest mark I've ever gotten on my math PSMT. Highly recommend!"
              }
            />
          </ItemHolder>
        </Slider>
      </CarouselHolder>
    </TestimonialContainer>
  );
}

const TestimonialContainer = styled.div`
  position: relative;
  width: 100%;
  height: 850px;
  display: flex;
  background: url(${background});
  background-position: center top;
  background-size: cover;
  flex-direction: column;
  padding: 100px 100px;
  gap: 15px;
  cursor: default;
  z-index: 4;

  @media (max-width: 1000px) {
    padding: 100px 60px;
  }
  @media (max-width: 900px) {
    padding: 100px 50px;
  }
  @media (max-width: 600px) {
    padding: 80px 35px;
  }
  @media (max-width: 500px) {
    height: 950px;
    padding: 80px 25px;
  }
  @media (max-width: 400px) {
    padding: 80px 10px;
  }
`;

const Title = styled(ScreenSubHeading)`
  @media (max-width: 500px) {
    margin-left: 10px;
  }
  @media (max-width: 400px) {
    margin-left: 25px;
  }
`;

const Subheading = styled(ScreenSubText)`
  @media (max-width: 500px) {
    margin-left: 10px;
  }
  @media (max-width: 400px) {
    margin-left: 25px;
  }
`;
const CarouselHolder = styled.div`
  width: 100%;
  margin-top: 70px;
`;

const ItemHolder = styled.div`
  padding: 50px;
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    padding: 30px;
    padding-top: 50px;
  }
  @media (max-width: 400px) {
    padding: 50px 10px;
  }
`;
