import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ScreenSubText } from "../../utilities/ScreenText";

export default function ChatToTutoring() {
  const history = useHistory();
  return (
    <PageContainer>
      <Content>
        <ScreenSubText>
          Try our basic features for absolutely <b>FREE</b>, with no strings
          attached.
        </ScreenSubText>
        <ScreenSubText>
          Upgrade to a Pro Plan for <b>FREE</b> by signing up to our weekly
          tutoring sessions.{" "}
          <span
            style={{
              textDecoration: "underline",
              color: "#F46A57",
              cursor: "pointer",
            }}
            onClick={() => history.push("/tutoring")}
          >
            Learn More.
          </span>
        </ScreenSubText>
      </Content>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100%;
  padding: 0 0 100px 0;
  display: flex;
  justify-content: center;
  cursor: default;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  width: 50%;
  min-width: 250px;
`;
