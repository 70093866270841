import { collection, query, where } from "firebase/firestore";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useEffect } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import styled from "styled-components";
import AdminAssignments from "../components/admin/download/AdminAssignments";
import AdminNav from "../components/admin/AdminNav";
import AdminUsers from "../components/admin/admin-panel/AdminUsers";
import { AssignmentData, TutorPayData, UserData } from "../data/databaseTypes";
import AdminTutorPay from "../components/admin/admin-panel/tutor-pay/AdminTutorPay";

type AdminPanelScreenProps = {
  scrollerContainer: OverlayScrollbarsComponent | null;
};

export default function AdminPanelScreen(props: AdminPanelScreenProps) {
  // Make the mac os like scroll not work in this screen
  useEffect(() => {
    props.scrollerContainer?.osInstance()?.scroll(0, 0);
    props.scrollerContainer
      ?.osInstance()
      ?.options("overflowBehavior.y", "hidden");

    return () =>
      props.scrollerContainer
        ?.osInstance()
        ?.options("overflowBehavior.y", "scroll");
  }, [props.scrollerContainer]);

  const firestore = useFirestore();

  const usersCollection = collection(firestore, "users");
  const assignmentsCollection = collection(firestore, "assignments");
  const tutorPayCollection = collection(firestore, "tutor_pay");

  const studentsQuery = query(usersCollection, where("type", "==", "student"));
  const parentsQuery = query(usersCollection, where("type", "==", "parent"));
  const employeesQuery = query(
    usersCollection,
    where("type", "in", ["tutor", "admin"])
  );

  const assigmentsQuery = query(assignmentsCollection);
  const payRequestsQuery = query(tutorPayCollection);

  const { data: dbStudents } = useFirestoreCollectionData(studentsQuery);
  const { data: dbParents } = useFirestoreCollectionData(parentsQuery);
  const { data: dbEmployees } = useFirestoreCollectionData(employeesQuery);

  const { data: dbAssignments } = useFirestoreCollectionData(assigmentsQuery, {
    idField: "id",
  });
  const { data: dbPayRequests } = useFirestoreCollectionData(payRequestsQuery, {
    idField: "id",
  });

  const students = dbStudents as UserData[];
  const parents = dbParents as UserData[];
  const employees = dbEmployees as UserData[];

  const assignments = dbAssignments as AssignmentData[];
  const payRequests = dbPayRequests as TutorPayData[];

  return (
    <PageContainer>
      <AdminNav page={"Admin"} />
      <AdminTutorPay employees={employees} section={"pending"} payRequests={payRequests} />
      <AdminTutorPay employees={employees} section={"invoicing"} payRequests={payRequests} />
      <AdminAssignments assignments={assignments} students={students} />
      <AdminUsers students={students} parents={parents} employees={employees} />
      <AdminTutorPay employees={employees} section={"success"} payRequests={payRequests} />
    </PageContainer>
  );
}

const PageContainer = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #fff;
  overflow-y: auto;
`;
