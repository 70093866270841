import styled from "styled-components";
import ProductInfoComponent from "./ProductInfoComponent";

export default function ProductInfoSection() {
  return (
    <ProductInfoSectionStyled>
      <ProductInfoComponent />
    </ProductInfoSectionStyled>
  );
}

const ProductInfoSectionStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  cursor: default;
  @media (max-width: 500px) {
    padding: 0;
  }
`;
