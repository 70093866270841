import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ScreenSubHeading, ScreenSubText } from "../../utilities/ScreenText";

export default function ServicesOptionsSection() {
  const history = useHistory();

  return (
    <ServicesOptionsSectionStyled>
      <ScreenSubHeading>Learn More About Our Services</ScreenSubHeading>
      <OptionHolder>
        <Option>
          <Circle color="#B8D8FB" onClick={() => history.push("/clarifychat")}>
            CLARIFY <br /> CHAT
          </Circle>
          <ScreenSubText style={{ textAlign: "center" }}>
            Ready, aim and fire. With our 24/7 built-in chat service, connect
            with top tutors who'll make sure you don't miss any of your shots.
            Try our features for <b>FREE</b> to see why we have a 100%
            satisfaction guarantee.
          </ScreenSubText>
        </Option>
        <Option>
          <Circle color="#FFB6B6" onClick={() => history.push("/tutoring")}>
            TUTORING
          </Circle>
          <ScreenSubText style={{ textAlign: "center" }}>
            Reach full throttle with our accomplished tutors so you can blastoff
            towards success. Our tutors are recent graduates, equipped with 99+
            ATARs, 90+ subject scores and 200+ hours of tutoring experience.
            Your senior years only happen once, so we're ready to help you make
            them the best.
          </ScreenSubText>
        </Option>
        <Option>
          <Circle color="#FFDDAA" onClick={() => history.push("/assignments")}>
            ASSIGNMENTS
          </Circle>
          <ScreenSubText style={{ textAlign: "center" }}>
            Ambiguous feedback is frustrating. That's why we've developed a
            detailed and criteria-specific feedback and marking service. Don't
            waste time wondering how you could improve. Let us do that for you.
          </ScreenSubText>
        </Option>
      </OptionHolder>
    </ServicesOptionsSectionStyled>
  );
}

const ServicesOptionsSectionStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 100px 100px 100px;
  gap: 75px;
  cursor: default;

  @media (max-width: 1000px) {
    padding: 20px 60px 100px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px 100px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px 35px 100px 35px;
  }
`;

const OptionHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: space-around;
  gap: 100px;
`;

const Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  width: 275px;
`;

type CircleProps = {
  color: string;
};

const Circle = styled.div`
  width: 225px;
  height: 225px;
  border-radius: 50%;
  background-color: ${(props: CircleProps) => props.color};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: ease 300ms;

  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 25px;
  text-align: center;
  user-select: none;

  &:hover {
    transform: scale(1.05);
  }
`;
