import styled from "styled-components";
import Dot from "../../utilities/Dot";
import { ScreenHeading, ScreenSubText } from "../../utilities/ScreenText";

export default function ChatPricingLanding() {
  return (
    <ChatPricingLandingContainer>
      <Content>
        <span>
          <ScreenHeading>
            CLARIFY
            <span style={{ whiteSpace: "nowrap" }}>
              {" "}
              CHAT
              <Dot color={"#FFC770"} />
            </span>
          </ScreenHeading>
        </span>
        <ScreenSubText>
          We all have those burning questions that keep us restless. With our
          flagship Chat, we'll smoothen out those wrinkles so you can stop
          tossing and turning at night. Try our basic features for <b>FREE.</b>
        </ScreenSubText>
      </Content>
    </ChatPricingLandingContainer>
  );
}

const ChatPricingLandingContainer = styled.div`
  width: 100%;
  height: 80vh;
  position: relative;
  min-height: 600px;
  display: flex;
  align-items: center;
  padding: 20px 100px;

  @media (max-width: 1000px) {
    padding: 20px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px;
  }
`;

const Content = styled.div`
  padding-top: 150px;
  cursor: default;
  width: 80%;
  gap: 25px;

  @media (max-width: 800px) {
    width: 95%;
  }
  @media (max-width: 650px) {
    & > span > div {
      font-size: 60px;
    }
  }
  @media (max-width: 600px) {
    width: 460px;
  }
  @media (max-width: 550px) {
    & > span > div {
      font-size: 54px;
    }
  }
  @media (max-width: 500px) {
    width: 380px;
    & > span > div {
      font-size: 48px;
    }
  }
  @media (max-width: 400px) {
    width: 320px;
    & > span > div {
      font-size: 36px;
    }
  }
  @media (max-width: 350px) {
    & > span > div {
      font-size: 32px;
    }
  }
  @media (max-width: 320px) {
    & > span > div {
      font-size: 30px;
    }
  }
  @media (max-width: 300px) {
    & > span > p {
      font-size: 26px;
    }
  }
`;
