import { collection, doc, getDoc, query, where } from "firebase/firestore";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import styled from "styled-components";
import AdminNav from "../components/admin/AdminNav";
import TutorEditProfile from "../components/admin/tutor-panel/TutorEditProfile";
import TutorPastRequestSection from "../components/admin/tutor-panel/pay-request/TutorPastRequestSection";
import TutorRequestSection from "../components/admin/tutor-panel/pay-request/TutorRequestSection";
import {
  AssignmentData,
  TutorData,
  TutorPayData,
  UserData,
} from "../data/databaseTypes";
import { RootState } from "../redux/store";
import AdminAssignments from "../components/admin/download/AdminAssignments";

type TutorPanelScreenProps = {
  scrollerContainer: OverlayScrollbarsComponent | null;
};

export default function TutorPanelScreen(props: TutorPanelScreenProps) {
  const firestore = useFirestore();
  const userId = useSelector((state: RootState) => state.userData.uid);
  const [tutorData, setTutorData] = useState<TutorData | false>(false);

  // Make the mac os like scroll not work in this screen
  useEffect(() => {
    props.scrollerContainer?.osInstance()?.scroll(0, 0);
    props.scrollerContainer
      ?.osInstance()
      ?.options("overflowBehavior.y", "hidden");

    return () =>
      props.scrollerContainer
        ?.osInstance()
        ?.options("overflowBehavior.y", "scroll");
  }, [props.scrollerContainer]);

  useEffect(() => {
    getDoc(doc(firestore, "tutors", userId))
      .then((tutor) => {
        if (tutor.exists()) {
          setTutorData(tutor.data() as TutorData);
        }
      })
      .catch((error) => console.log(error));
  }, [firestore, userId]);

  const usersCollection = collection(firestore, "users");
  const tutorPayCollection = collection(firestore, "tutor_pay");
  const assignmentsCollection = collection(firestore, "assignments");

  const studentsQuery = query(usersCollection, where("type", "==", "student"));
  const parentsQuery = query(usersCollection, where("type", "==", "parent"));
  const payRequestsQuery = query(
    tutorPayCollection,
    where("uid", "==", userId)
  );
  const assigmentsQuery = query(assignmentsCollection);

  const { data: dbStudents } = useFirestoreCollectionData(studentsQuery);
  const { data: dbParents } = useFirestoreCollectionData(parentsQuery);
  const { data: dbPayRequests } = useFirestoreCollectionData(payRequestsQuery, {
    idField: "id",
  });
  const { data: dbAssignments } = useFirestoreCollectionData(assigmentsQuery, {
    idField: "id",
  });

  const students = dbStudents as UserData[];
  const parents = dbParents as UserData[];
  const payRequests = dbPayRequests as TutorPayData[];
  const assignments = dbAssignments as AssignmentData[];

  return (
    <PageContainer>
      <AdminNav page={"Tutor"} />
      {tutorData ? (
        <>
          <TutorRequestSection
            studentsParents={students.concat(parents)}
            tutorData={tutorData}
          />
          <TutorPastRequestSection
            tutorData={tutorData}
            students={students}
            payRequests={payRequests}
          />
          <AdminAssignments assignments={assignments} students={students} />
          <TutorEditProfile tutorData={tutorData} />
        </>
      ) : (
        <div style={{ height: "calc(100vh - 100px)" }}>
          Loading... (tutor portal) if it's stuck on this screen try again and
          check connection. If it's urgent talk to Chethin directly.
        </div>
      )}
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100%;
  background-color: #fff;
  height: 100vh;
  overflow-y: auto;
`;
