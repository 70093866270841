export type UserData = {
  uid: string;
  email: string;
  firstName: string;
  lastName: string;
  profileImage: string;
  joined: any;
  type: "student" | "parent" | "tutor" | "admin";
};

export const emptyUser: UserData = {
  uid: "-1",
  email: "",
  firstName: "",
  lastName: "",
  profileImage: "",
  joined: 0,
  type: "student",
};

export type MessageData = {
  chatroomId: string;
  senderId: string;
  type: "text" | "image" | "pdf" | "tutoring-request";
  data: string;
  sent: any;
};

export type ChatroomData = {
  id: string;
  name: string;
  userIds: string[];
  userNames: string[];
  userImages: string[];
  lastActive: any[];
  type: string;
};

export const emptyChatroom = {
  id: "-1",
  name: "Loading...",
  userIds: [],
  userNames: [],
  userImages: [],
  lastActive: [],
  type: "empty",
};

export type TutorData = {
  uid: string;
  firstName: string;
  lastName: string;
  profileImage: string;
  type: "admin" | "tutor";
  role: string;
  description: string[];
  subject: Subject[];
  availability: number;
  achievement: string[];
  atar: string;
  shown: boolean;
  students: string[];
};

export const emptyTutor: TutorData = {
  uid: "-1",
  firstName: "",
  lastName: "",
  profileImage: "",
  type: "tutor",
  role: "role",
  description: ["description"],
  subject: [],
  availability: 0,
  achievement: ["achievement"],
  atar: "empty",
  shown: false,
  students: [],
};

export type PageData = {
  page: string;
  sellable: SellableProduct[];
  downloadable: DownloadLink[];
  subjects: Subject[];
};

export type DownloadLink = {
  name: string;
  link: string;
};

export type SellableProduct = {
  id: string;
  price:
    | {
        value: number;
        top: string;
        bottom: string;
      }
    | undefined;
  subtext: string;
  details: SellableProductDetail[];
  button: string;
};

export type SellableProductDetail = {
  detail: string;
  type: "good" | "bad";
};

export type Subject = {
  subjectCode: string;
  displayName: string;
};

export type AssignmentData = {
  filePaths: string[];
  status: "unmarked" | "marked";
  uploaded: any;
  userId: string;
  id: string;
};

export type TutorPayData = {
  id: string;
  uid: string;
  studentName: string;
  studentEmail: string;
  studentId: string;
  duration: {
    hours: number;
    minutes: number;
  };
  date: any;
  notes: string;
  status: "pending" | "success" | "invoicing" | "cancelled";
};
