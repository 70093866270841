import {
  FirebaseAppProvider,
  FirestoreProvider,
  AuthProvider,
  useFirebaseApp,
} from "reactfire";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

import InnerApp from "./InnerApp";

const firebaseConfig = {
  apiKey: "AIzaSyBjfRvukzBhQPVlPIW00sW_W8ybvAeH3Z0",
  authDomain: "clarify-tutoring.firebaseapp.com",
  projectId: "clarify-tutoring",
  storageBucket: "clarify-tutoring.appspot.com",
  messagingSenderId: "604393411547",
  appId: "1:604393411547:web:577c7d8203d6ee27f7cdfe",
  measurementId: "G-7DFMJL7YYK",
};

function App() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <AppWrapper />
    </FirebaseAppProvider>
  );
}

function AppWrapper() {
  const app = useFirebaseApp();
  const firestoreInstance = getFirestore(app);
  const auth = getAuth(app);
  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestoreInstance}>
        <InnerApp />
      </FirestoreProvider>
    </AuthProvider>
  );
}

export default App;
