import { doc, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { useFirestore } from "reactfire";
import styled from "styled-components";
import { TutorData } from "../../../data/databaseTypes";
import Button from "../../utilities/button/Button";
import { Line } from "../../utilities/Line";

type TutorEditProfileProps = {
  tutorData: TutorData;
};

export default function TutorEditProfile(props: TutorEditProfileProps) {
  const firestore = useFirestore();
  type NewTutorData = {
    description: string[];
    availability: number;
    achievement: string[];
  };

  const [newTutorData, setNewTutorData] = useState<NewTutorData>({
    description: props.tutorData.description,
    availability: props.tutorData.availability,
    achievement: props.tutorData.achievement,
  });

  const handleAvailibilityChange = (value: number) => {
    setNewTutorData((oldData) => {
      const newData: NewTutorData = { ...oldData };
      newData.availability = value;
      return newData;
    });
  };

  type tutorChangeField = "achievement" | "description";

  const handleTextChange = (
    key: tutorChangeField,
    value: string,
    idx: number
  ) => {
    setNewTutorData((oldData) => {
      const newData: NewTutorData = { ...oldData };
      if (key === "achievement") {
        newData.achievement[idx] = value;
      } else if (key === "description") {
        newData.description[idx] = value;
      }
      return newData;
    });
  };

  const removeText = (key: tutorChangeField, idx: number) => {
    setNewTutorData((oldData) => {
      const newData: NewTutorData = { ...oldData };
      if (key === "achievement") {
        newData.achievement = [...newData.achievement];
        newData.achievement.splice(idx, 1);
      } else {
        newData.description = [...newData.description];
        newData.description.splice(idx, 1);
      }
      return newData;
    });
  };

  const addTextBox = (key: tutorChangeField, size: number) => {
    setNewTutorData((oldData) => {
      const newData: NewTutorData = { ...oldData };
      if (key === "achievement") {
        newData.achievement = newData.achievement.concat([
          `Achivement ${size + 1}`,
        ]);
      } else {
        newData.description = newData.description.concat([
          `Paragraph ${size + 1}`,
        ]);
      }
      return newData;
    });
  };

  const saveChanges = () => {
    const tutorDoc = doc(firestore, "tutors", props.tutorData.uid);
    updateDoc(tutorDoc, {
      availability: newTutorData.availability,
      achievement: newTutorData.achievement,
      description: newTutorData.description,
    })
      .then(() => {
        console.log("Successfully saved new tutor details!");
      })
      .catch((error) => console.error(error));
  };

  return (
    <Container>
      <b>Personal details</b>
      Hi {props.tutorData.firstName}, make sure to click save before leaving!
      <div style={{ alignSelf: "center" }}>
        <Button
          name={"Save Changes"}
          textColor="black"
          width="180px"
          onClick={saveChanges}
        />
      </div>
      <InputSection>
        <p>Availibilty: </p>
        <input
          value={newTutorData.availability}
          onChange={(e) => handleAvailibilityChange(parseInt(e.target?.value))}
          type="number"
          min="0"
          max="30"
        />
        <p>How many students can you take in?</p>
        <p>
          Having availibility of 0 makes your profile grey out, if you want it
          removed entirly talk to Chehtin directly
        </p>
      </InputSection>
      <Line color="black" />
      {/* Achivement section */}
      <InputSection>
        <p>Achievements: </p>
        <VerticalInputSection>
          {newTutorData.achievement.map((achievement: string, idx: number) => (
            <InputSection key={idx}>
              <textarea
                value={achievement}
                onChange={(e) =>
                  handleTextChange("achievement", e.target?.value, idx)
                }
              />
              <button onClick={() => removeText("achievement", idx)}>
                remove
              </button>
            </InputSection>
          ))}

          <button
            onClick={() =>
              addTextBox("achievement", newTutorData.achievement.length)
            }
          >
            Add achievement
          </button>
        </VerticalInputSection>
      </InputSection>
      <Line color="black" />
      {/* Description section */}
      <InputSection>
        <p>Description: </p>
        <VerticalInputSection>
          {newTutorData.description.map((desc: string, idx: number) => (
            <InputSection key={idx}>
              <textarea
                style={{ height: "200px", width: "500px" }}
                value={desc}
                onChange={(e) =>
                  handleTextChange("description", e.target?.value, idx)
                }
              />
              <button onClick={() => removeText("description", idx)}>
                remove
              </button>
            </InputSection>
          ))}

          <button
            onClick={() =>
              addTextBox("description", newTutorData.description.length)
            }
          >
            Add paragraph
          </button>
        </VerticalInputSection>
        <VerticalInputSection>
          <p>Each box represents a paragraph</p>
          <p>
            Try to keep the text within each box, if it exceeds it's probably
            too long
          </p>
          <p>Don't put in multiple spaces between words in a section</p>
        </VerticalInputSection>
      </InputSection>
      <Line color="black" />
      If you want to edit something that is not available here i.e subjects or
      ATAR, talk to Chethin directly.
      <div style={{ alignSelf: "center", margin: "20px" }}>
        <Button
          name={"Save Changes"}
          textColor="black"
          width="180px"
          onClick={saveChanges}
        />
      </div>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 15px;

  & textarea {
    resize: none;
    height: 50px;
  }
  & p {
    margin: 0;
  }
`;

const InputSection = styled.div`
  display: flex;
  gap: 15px;
`;

const VerticalInputSection = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
`;
