import styled from "styled-components";
import Dot from "../../utilities/Dot";
import { ScreenHeading, ScreenSubText } from "../../utilities/ScreenText";

export default function ServicesLanding() {
  return (
    <ProductLandingContainer>
      <Content>
        <span>
          <ScreenHeading>
            {" "}
            SERVICES
            <Dot color={"#71BCFF"} />
          </ScreenHeading>
        </span>
        <ScreenSubText>
          We offer a range of services and products for students with different
          levels of need.
        </ScreenSubText>
      </Content>
    </ProductLandingContainer>
  );
}

const ProductLandingContainer = styled.div`
  width: 100%;
  height: 80vh;
  position: relative;
  min-height: 600px;
  display: flex;
  align-items: center;
  padding: 20px 100px;
  cursor: default;

  @media (max-width: 1000px) {
    padding: 20px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px;
  }
`;

const Content = styled.div`
  padding-top: 150px;
  width: 80%;
  gap: 25px;

  @media (max-width: 800px) {
    width: 95%;
  }
  @media (max-width: 600px) {
    width: 460px;
  }
  @media (max-width: 500px) {
    width: 370px;
  }
  @media (max-width: 400px) {
    width: 310px;
  }
  @media (max-width: 350px) {
    width: 300px;
  }
`;
