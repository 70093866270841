import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";

import { closePopup } from "../redux/accountPopupSlice";
import { signOut, getAuth } from "firebase/auth";
import { addUser, removeUser } from "../redux/userDataSlice";
import SidePanel from "../components/popups/account_popup/SidePanel";
import { doc, updateDoc } from "firebase/firestore";
import { useFirestore } from "reactfire";
import { useEffect, useState } from "react";
import MainPanel from "../components/popups/account_popup/MainPanel";
import { CloseButton } from "../components/utilities/button/CloseButton";
import closeIcon from "../assets/images/utilities/close.svg";

export default function AccountScreen() {
  const auth = getAuth();

  const dispatch = useDispatch();

  const accountPopupData = useSelector(
    (state: RootState) => state.accountPopupData
  );
  const userData = useSelector((state: RootState) => state.userData);

  const open = accountPopupData.open;
  const firestore = useFirestore();

  const [checkingForValidity, setCheckingForValidity] = useState(false);

  const accountCloseValidityCheck = () => {
    return (
      newUserData.firstName && newUserData.lastName && newUserData.profileImage
    );
  };

  const setCheckingForValidityToFalse = () => {
    setCheckingForValidity(false);
  };

  const close = () => {
    // check validity
    setCheckingForValidity(true);
    if (accountCloseValidityCheck()) {
      saveChanges();
      dispatch(closePopup());
    }
  };

  type NewUserData = {
    firstName: string;
    lastName: string;
    profileImage: string;
  };

  const [newUserData, setNewUserData] = useState<NewUserData>({
    firstName: userData.firstName,
    lastName: userData.lastName,
    profileImage: userData.profileImage,
  });

  type userChangeField = "firstName" | "lastName" | "profileImage";

  const handleChange = (key: userChangeField, value: string) => {
    setNewUserData((oldData) => {
      const newData: NewUserData = { ...oldData };
      newData[key] = value;
      return newData;
    });
  };

  useEffect(() => {
    setNewUserData({
      firstName: userData.firstName,
      lastName: userData.lastName,
      profileImage: userData.profileImage,
    });
  }, [userData, open]);

  // sign out from auth then remove all user data
  const logout = () => {
    signOut(auth)
      .then(() => {
        dispatch(removeUser());
      })
      .catch((error) => console.log(error));
    dispatch(closePopup());
  };

  const saveChanges = () => {
    const userDoc = doc(firestore, "users", userData.uid);
    updateDoc(userDoc, {
      firstName: newUserData.firstName,
      lastName: newUserData.lastName,
      profileImage: newUserData.profileImage,
    })
      .then(() => {
        const updatedUser = { ...userData };
        updatedUser.firstName = newUserData.firstName;
        updatedUser.lastName = newUserData.lastName;
        updatedUser.profileImage = newUserData.profileImage;

        dispatch(addUser(updatedUser));
      })
      .catch((error) => console.error(error));
  };

  return (
    <DarkBackground
      style={{
        opacity: open ? 1 : 0,
        pointerEvents: open ? "initial" : "none",
      }}
    >
      <PageContainer style={{ top: open ? 0 : "100vh" }}>
        <AccountPopupContainer>
          <SidePanel
            newUserFirstName={newUserData.firstName}
            logout={logout}
            handleChange={handleChange}
            newUserProfileImage={newUserData.profileImage}
          />
          <MainPanel
            logout={logout}
            newUserData={newUserData}
            handleChange={handleChange}
            close={close}
            checkingForValidity={checkingForValidity}
            setCheckForValidityFalse={setCheckingForValidityToFalse}
          />
          <CloseButton onClick={close}>
            <img src={closeIcon} alt="Close Button" />
          </CloseButton>
        </AccountPopupContainer>
      </PageContainer>
    </DarkBackground>
  );
}

const DarkBackground = styled.div`
  position: fixed;
  z-index: 100;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.3s ease;

  @media (max-width: 800px) {
    background-color: transparent;
  }
`;

const PageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.3s cubic-bezier(0, 0, 0.39, 0.98);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AccountPopupContainer = styled.div`
  max-width: 1100px;
  width: 70%;
  min-width: 750px;
  height: 80%;
  max-height: 700px;
  background-color: #fff;
  border-radius: 35px;
  box-shadow: 0 4px 20px 5px rgba(0, 0, 0, 0.25);
  display: flex;
  transition: opacity 200ms linear;
  align-items: center;
  position: relative;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 800px) {
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    min-width: 0;
    border-radius: 0;
  }

  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
