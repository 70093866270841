import styled from "styled-components";
import NavBar from "../components/navbar/NavBar";
import Background from "../assets/images/background/background404.svg";
import Button from "../components/utilities/button/Button";
import { useHistory } from "react-router-dom";

export default function Error404() {
  const history = useHistory();
  return (
    <PageContainer>
      <NavBar scroll={false} />
      <Content>
        <div>404</div>
        <span>oops... something went wrong!</span>
        <p>we can't seem to find the page you're looking for</p>
        <Button
          name={"Go back home"}
          color={"#71BCFF"}
          hover={"#69b2f1"}
          width={"200px"}
          onClick={() => history.push("/")}
        />
      </Content>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  background-image: url(${Background});
  background-color: #fff;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: "Raleway", sans-serif;
  text-align: center;
  color: #15375b;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -30%);

  & > div {
    font-family: "Cabin", sans-serif;
    font-weight: 700;
    font-size: 150px;
    color: #6aa6cc;
  }
  & > span {
    font-weight: 700;
    font-size: 40px;
  }
  & > p {
    font-weight: 400;
    font-size: 20px;
  }

  @media (max-width: 500px) {
    transform: translate(-50%, -40%);
    gap: 15px;
    & > div {
      font-size: 120px;
    }
    & > span {
      font-size: 30px;
    }
    & > p {
      font-size: 18px;
    }
  }
`;
