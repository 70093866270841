import styled from "styled-components";
import Spinner from "../../utilities/Spinner";

type WelcomeSectionProps = {
  isLoading: boolean;
}

export default function WelcomeSection(props: WelcomeSectionProps) {
  return (
    <WelcomeBox>
      <Welcome>Welcome!</Welcome>
      <SubText>
        Get access to live tutoring and study resources including our{" "}
        <b>Free Chat Function</b>
      </SubText>
      <Spinner loading={props.isLoading} color={"#245785"}/>
    </WelcomeBox>
  );
}

const WelcomeBox = styled.div`
  color: #15375b;
  font-family: "Cabin", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  width: 50%;
  gap: 5px;
`;

const Welcome = styled.p`
  font-size: 70px;
  font-weight: bold;
  margin: 0;

  @media (max-width: 550px) {
    padding: 0px 0 0 5px;
    font-size: 50px;
  }
`;

const SubText = styled.p`
  font-size: 20px;
  margin: 0;
  padding: 5px 0px;
  text-align: center;
  max-width: 400px;
  margin-bottom: 10px;

  @media (max-width: 550px) {
    font-size: 18px;
    padding: 0;
  }
`;
