import LandingSection from "../components/screen_components/home/LandingSection";
import InfoSection from "../components/screen_components/home/info_section/InfoSection";
import FooterSection from "../components/footer/Footer";
import styled from "styled-components";
import SunBackground from "../assets/images/background/sunset-background.svg";
import NavBar from "../components/navbar/NavBar";

// import ChatBubble from "../components/utilities/chat_bubble/ChatBubble";
import HomeRailway from "../components/screen_components/home/railway/HomeRailway";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useEffect } from "react";
import TestimonialSection from "../components/screen_components/home/testimonial/TestimonialSection";

type HomeScreenProps = {
  isScroll: boolean;
  windowHeight: number;
  windowWidth: number;
  scrollerContainer: OverlayScrollbarsComponent | null;
};

export default function HomeScreen(props: HomeScreenProps) {
  useEffect(() => {
    props.scrollerContainer?.osInstance()?.scroll(0, 0);
  }, [props.scrollerContainer]);
  return (
    <PageContainer>
      <NavBar scroll={props.isScroll} />
      <Background windowHeight={props.windowHeight}>
        <LandingSection scrollerContainer={props.scrollerContainer} />
        <InfoSection windowWidth={props.windowWidth}/>
      </Background>
      <HomeRailway windowWidth={props.windowWidth} />
      <TestimonialSection />
      {/* <ChatBubble /> */}
      <FooterSection background={"#C1D8F3"} />
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

type BackgroundProps = {
  windowHeight: number;
};

const Background = styled.div`
  position: sticky;
  position: -webkit-sticky;
  width: 100%;
  top: ${(props: BackgroundProps) =>
    props.windowHeight < 666 ? `calc(-1466px + 100vh)` : `-800px`};

  background-color: white;
  min-height: ${(props: BackgroundProps) =>
    props.windowHeight < 666 ? "calc(666px + 800px)" : "100vh + 800px"};
  background-size: cover;
  background-position: center top;
  background-image: url(${SunBackground});

  @media (max-width: 910px) {
    min-height: ${(props: BackgroundProps) =>
      props.windowHeight < 666 ? "calc(666px + 1300px)" : "100vh + 1300px"};
    top: ${(props: BackgroundProps) =>
      props.windowHeight < 666 ? `calc(-1866px + 100vh)` : `-1200px`};
  }
  @media (max-width: 619px) {
    position: static;
    min-height: ${(props: BackgroundProps) =>
      props.windowHeight < 666 ? "calc(666px + 1700px)" : "100vh + 1700px"};
  }
`;
