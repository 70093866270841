import { useEffect, useState } from "react";
import styled from "styled-components";
import { CloseButton } from "../../../utilities/button/CloseButton";
import closeIcon from "../../../../assets/images/utilities/close.svg";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { closePopup } from "../../../../redux/assignmentUploadSlice";
import AssignmentUploadPanel from "./AssignmentUploadPanel";
import Button from "../../../utilities/button/Button";
import CheckBox from "../../../utilities/CheckBox";

import {
  collection,
  addDoc,
  serverTimestamp,
  updateDoc,
  doc,
} from "firebase/firestore";
import { useFirestore } from "reactfire";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { useHistory } from "react-router-dom";

export default function AssignmentUploadPopup() {
  const dispatch = useDispatch();
  const history = useHistory();
  const firestore = useFirestore();
  const userId = useSelector((state: RootState) => state.userData.uid);

  const [filesUploaded, setFilesUploaded] = useState<string[]>([]);
  const [docCreated, setDocCreated] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const [chosenFilesArr, setChosenFilesArr] = useState<File[]>([]);

  const assignmentUploadData = useSelector(
    (state: RootState) => state.assignmentUploadData
  );

  const handleFileChange = (files: File[]) => {
    setCompleted(false);
    setChosenFilesArr(files);
  };

  const uploadFiles = () => {
    if (completed) return;
    if (!uploading) {
      if (userId !== "-1" && chosenFilesArr.length > 0) {
        setUploading(true);
        setDocCreated(false);
        setFilesUploaded([]);
        addDoc(collection(firestore, "assignments"), {
          status: "unmarked",
          userId,
          uploaded: serverTimestamp(),
          filePaths: [],
        }).then((docRef) => {
          const assignmentId = docRef.id;
          const filePaths = [];

          const storage = getStorage();

          for (const [index, file] of chosenFilesArr.entries()) {
            const filePath = `assignments/${assignmentId}_${index}_${file.name}`;
            filePaths.push(filePath);

            const fileRef = ref(storage, filePath);

            uploadBytes(fileRef, file).then(() => {
              setFilesUploaded((f) => {
                return [...f, file.name];
              });
            });
          }
          updateDoc(doc(firestore, "assignments", assignmentId), {
            filePaths,
          }).then(() => {
            setDocCreated(true);
          });
        });
      }
    }
  };

  useEffect(() => {
    if (
      uploading &&
      filesUploaded.length === chosenFilesArr.length &&
      docCreated
    ) {
      // Upload Complete
      setCompleted(true);
      setChosenFilesArr([]);
      setFilesUploaded([]);
      setUploading(false);
    }
  }, [filesUploaded, docCreated, uploading, chosenFilesArr, dispatch]);

  const open = assignmentUploadData.open;

  const close = () => {
    if (!uploading) dispatch(closePopup());
    setCompleted(false);
  };

  const [isTandC, setIsTandC] = useState(false);
  const [isTutorCheck, setIsTutorCheck] = useState(false);

  const handleTandC = (value: boolean) => {
    setIsTandC(value);
  };
  const hanndleTutorCheck = (value: boolean) => {
    setIsTutorCheck(value);
  };

  return (
    <DarkBackground
      style={{
        opacity: open ? 1 : 0,
        pointerEvents: open ? "initial" : "none",
      }}
    >
      <PageContainer style={{ top: open ? 0 : "100vw" }}>
        <AssignmentUploadPopupContainer>
          <AssignmentUploadPanel
            handleChange={handleFileChange}
            chosenFilesArr={chosenFilesArr}
            filesUploaded={filesUploaded}
            uploading={uploading}
            completed={completed}
          />
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <TAndC>
              <CheckBox handleChange={handleTandC} />
              <p>
                I have read and agree to Clarify's{" "}
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => history.push("/terms")}
                >
                  Terms of Service Agreement
                </span>
              </p>
            </TAndC>
            <TAndC>
              <CheckBox handleChange={hanndleTutorCheck} />
              <p>
                I have talked to a tutor, and they have asked me to send my
                documents through.
              </p>
            </TAndC>
          </div>

          <Button
            name={"Upload"}
            color={"#FFDDAA"}
            hover={"#fbd7a0"}
            textColor="#000"
            width="200px"
            noShadow
            onClick={() => {
              uploadFiles();
            }}
            disabled={!isTandC || !isTutorCheck}
          />
          <CloseButton onClick={close}>
            <img src={closeIcon} alt="Close Button" />
          </CloseButton>
        </AssignmentUploadPopupContainer>
      </PageContainer>
    </DarkBackground>
  );
}

const DarkBackground = styled.div`
  position: fixed;
  z-index: 100;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.3s ease;

  @media (max-width: 800px) {
    background-color: transparent;
  }
`;

const PageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.3s cubic-bezier(0, 0, 0.39, 0.98);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
`;

const AssignmentUploadPopupContainer = styled.div`
  max-width: 1100px;
  width: 70%;
  min-width: 750px;
  height: 80%;
  max-height: 720px;
  height: 675px;
  background-color: #fff;
  border-radius: 35px;
  box-shadow: 0 4px 20px 5px rgba(0, 0, 0, 0.25);
  display: flex;
  transition: opacity 200ms linear;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;
  align-items: center;
  padding: 50px 100px;
  position: relative;
  cursor: default;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 800px) {
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    min-width: 0;
    border-radius: 0;
  }

  @media (max-width: 550px) {
    padding: 70px 50px;
  }
`;

const TAndC = styled.span`
  display: flex;
  align-items: center;
  gap: 15px;
  font-family: "Raleway", sans-serif;
  margin: 0;
`;
