import styled from "styled-components";

type TutorInfoComponentProps = {
  title: {
    normal: string;
    bold: string;
  };
  main: string;
};

export default function TutorInfoComponent(props: TutorInfoComponentProps) {
  return (
    <TutortInfoBox>
      <TitleCircle>
        <p>
          {props.title.normal} <b>{props.title.bold}</b>
        </p>
      </TitleCircle>
      <MainCircle>
        <p>{props.main}</p>
      </MainCircle>
    </TutortInfoBox>
  );
}

const TutortInfoBox = styled.div`
  position: relative;
  width: 80%;
  height: 375px;
  padding: 20px;
  cursor: default;

  @media (max-width: 1150px) {
    width: 85%;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
  @media (max-width: 800px) {
    height: 500px;
  }
  @media (max-width: 650px) {
    height: 600px;
  }
  @media (max-width: 600px) {
    height: 700px;
  }
  @media (max-width: 500px) {
    height: 700px;
  }
`;

const TitleCircle = styled.div`
  background-color: #ffdbdb;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  padding: 25px;

  position: absolute;
  z-index: 3;

  & > p {
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    text-align: center;
    color: #000000;
  }
  @media (max-width: 750px) {
    left: 10px;
  }
  @media (max-width: 650px) {
    left: 20px;
  }
  @media (max-width: 600px) {
    left: -10px;
    top: 0;
  }
  @media (max-width: 450px) {
    left: -10px;
  }
`;

const MainCircle = styled.div`
  height: 250px;
  width: 80%;
  padding: 50px 120px;
  border-radius: 500px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  top: 25%;
  left: 100px;
  position: absolute;
  z-index: 2;

  & > p {
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    color: #000000;
  }
  @media (max-width: 900px) {
    height: 300px;
  }
  @media (max-width: 800px) {
    height: 350px;
  }
  @media (max-width: 700px) {
    height: 400px;
    padding: 50px 100px;
  }
  @media (max-width: 650px) {
    height: 450px;
    left: 70px;
    width: 90%;
  }
  @media (max-width: 600px) {
    left: 5%;
    top: 110px;
    padding: 50px 60px;
    height: 500px;
    width: 95%;
  }
  @media (max-width: 550px) {
    min-width: 100%;
    left: 0;
  }
  @media (max-width: 500px) {
    width: 110%;
    height: 500px;
    width: 500px;
    left: 0;
  }
  @media (max-width: 450px) {
    padding: 50px 100px;
    left: -5%;
  }

  @media (max-width: 400px) {
    padding: 40px 30%;
    left: -19%;
    height: 550px;
    width: 550px;
    min-width: 120%;
  }
  @media (max-width: 370px) {
    height: 600px;
    width: 600px;
    left: -30%;
    padding: 40px 40%;
  }
  @media (max-width: 350px) {
    left: -40%;
    padding: 40px 50%;
  }
  @media (max-width: 320px) {
    padding: 40px 57%;
    left: -45%;
  }
  @media (max-width: 300px) {
    padding: 40px 65%;
    left: -53%;
  }
`;
