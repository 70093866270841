import styled from "styled-components";
import Product from "../../utilities/product/Product";
import { SellableProduct } from "../../../data/databaseTypes";
import { ScreenSubHeading, ScreenSubText } from "../../utilities/ScreenText";

type ChatProductSectionProps = {
  sellable: false | SellableProduct[];
};

export default function ChatProductSection(props: ChatProductSectionProps) {
  return (
    <ProductSection>
      <ScreenSubHeading>You deserve fast responses</ScreenSubHeading>
      <ScreenSubText>
        Talk to QLD's best in the tutoring industry, at lightning speeds so you
        can journey safely to your success, without bumps.
      </ScreenSubText>
      <ProductContainer>
        <Product
          product={props.sellable && props.sellable[3]} //change to 0
          buttonColor={"#B8D8FB"}
          buttonHover={"#accaeb"}
          bgColor={"#F7FBFF"}
          minHeight={"585px"}
        />
        <Product
          product={props.sellable && props.sellable[4]} //change to 1
          buttonColor={"#B8D8FB"}
          buttonHover={"#accaeb"}
          bgColor={"#F7FBFF"}
          minHeight={"585px"}
        />
      </ProductContainer>
    </ProductSection>
  );
}

const ProductSection = styled.div`
  width: 100%;
  padding: 20px 100px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  cursor: default;

  @media (max-width: 1000px) {
    padding: 20px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px;
  }
`;

const ProductContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 200px;
  padding: 60px 100px 120px 100px;

  @media (max-width: 1150px) {
    gap: 150px;
  }
  @media (max-width: 1100px) {
    gap: 100px;
  }
  @media (max-width: 1050px) {
    gap: 75px;
    padding: 60px 50px;
  }
  @media (max-width: 500px) {
    padding: 60px 10px;
  }
  @media (max-width: 370px) {
    padding: 60px 0;
  }
`;
