import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../redux/store";
import { Line } from "../../utilities/Line";
import SecondaryButton from "../../utilities/button/SecondaryButton";
import Textbox from "../../utilities/textbox/Textbox";
import SignOutButton from "./SignOutButton";
import { useHistory } from "react-router-dom";
import { openError } from "../../../redux/errorPopupSlice";

type MainPanelProps = {
  logout: () => void;
  newUserData: {
    firstName: string;
    lastName: string;
  };
  handleChange: (
    key: "firstName" | "lastName" | "profileImage",
    value: string
  ) => void;
  close: () => void;
  checkingForValidity: boolean;
  setCheckForValidityFalse: () => void;
};

export default function MainPanel(props: MainPanelProps) {
  const userData = useSelector((state: RootState) => state.userData);
  const history = useHistory();
  const dispatch = useDispatch();

  const goToAdminPanel = () => {
    props.close();
    history.push("/admin");
  };

  const goToTutorPanel = () => {
    props.close();
    history.push("/tutor-panel");
  };

  return (
    <MainPanelStyled>
      <Content>
        <TopSection>
          <Heading>Profile</Heading>
          <UserType>{userData.type}</UserType>
          <div onClick={props.logout}>
            <SignOutButton />
          </div>
        </TopSection>
        <Line color="#15375B" />
        <InteractivePanel>
          <div>
            <SubHeading>Account</SubHeading>
            <TextBoxContainer onClick={props.setCheckForValidityFalse}>
              <Textbox
                placeholder={"First name"}
                value={props.newUserData.firstName}
                onChange={(e) =>
                  props.handleChange("firstName", e.target?.value)
                }
                id="fname"
                name="fname"
                autocomplete="given-name"
                type="text"
                required
                handlingValidation={props.checkingForValidity}
              />
              <Textbox
                placeholder={"Last name"}
                value={props.newUserData.lastName}
                onChange={(e) =>
                  props.handleChange("lastName", e.target?.value)
                }
                id="lname"
                name="lname"
                autocomplete="family-name"
                type="text"
                required
                handlingValidation={props.checkingForValidity}
              />
            </TextBoxContainer>
          </div>
          <div>
            <SubHeading>Details</SubHeading>
            <Detail>
              <EmailBox>
                Email
                <span>{userData.email}</span>
              </EmailBox>
              <AccountButtons>
                <SecondaryButton
                  name={"Reset Password"}
                  color={"#FF9B8D"}
                  hover={"#f09185"}
                  size={"16px"}
                  width={"155px"}
                  height={"35px"}
                  textColor={"#15375B"}
                  onClick={() =>
                    dispatch(
                      openError(
                        "Currently this feature is not available, if you wish to reset your password sign out and go through forgot my password to get it reset. Alternatively, email us at clarifytutors@gmail.com with a password reset request :)"
                      )
                    )
                  }
                />
                {userData.type === "admin" || userData.type === "tutor" ? (
                  <SecondaryButton
                    onClick={goToTutorPanel}
                    name={"Tutor Panel"}
                    color={"#D2E4F0"}
                    hover={"#c0daeb"}
                    textColor={"#15375B"}
                    size={"16px"}
                    width={"155px"}
                    height={"35px"}
                  />
                ) : (
                  ""
                )}
                {userData.type === "admin" ? (
                  <SecondaryButton
                    onClick={goToAdminPanel}
                    name={"Admin Panel"}
                    color={"#3D5165"}
                    hover={"#314253"}
                    textColor={"#FFF"}
                    size={"16px"}
                    width={"155px"}
                    height={"35px"}
                  />
                ) : (
                  ""
                )}
              </AccountButtons>
            </Detail>
          </div>
        </InteractivePanel>
      </Content>
    </MainPanelStyled>
  );
}

const MainPanelStyled = styled.div`
  width: calc(100% - 275px);
  height: 100%;
  display: flex;
  padding: 50px 70px 50px 50px;
  overflow: scroll;
  user-select: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1100px) {
    width: calc(100% - 200px);
  }
  @media (max-width: 550px) {
    width: 100%;
    padding: 10px 30px;
  }
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;

  & > div {
    margin-bottom: 10px;
  }
`;

const TopSection = styled.div`
  position: relative;
  & > div {
    display: none;
  }

  @media (max-width: 550px) {
    & > div {
      display: block;
      position: absolute;
      right: 0;
      top: 25%;
    }
  }
`;

const Heading = styled.p`
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  color: #15375b;
  margin: 0;
  padding: 0;
  pointer-events: none;
`;

const InteractivePanel = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const UserType = styled.p`
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #707a85;
  margin: 0;
  margin-left: 3px;
  pointer-events: none;
  text-transform: capitalize;
`;

const SubHeading = styled.p`
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  pointer-events: none;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #15375b;
`;

const TextBoxContainer = styled.form`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 25px;
  margin-top: 10px;

  @media (max-width: 700px) {
    gap: 10px;
  }
  @media (max-width: 650px) {
    flex-direction: column;
    padding: 0 10px 0 0;
  }
`;

const Detail = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
  gap: 20px;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const EmailBox = styled.div`
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 15px;
  color: #707a85;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;

  & span {
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #5d646c;
    margin-top: 10px;
    margin-left: 5px;
  }
`;

const AccountButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 60px;
`;
