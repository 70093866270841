import dayjs from "dayjs";
import { doc, updateDoc } from "firebase/firestore";
import { useFirestore } from "reactfire";
import styled from "styled-components";
import { TutorPayData } from "../../../../data/databaseTypes";

type RequestItemProps = {
  payRequest: TutorPayData;
};

export default function RequestItem(props: RequestItemProps) {
  const date = new Date(props.payRequest.date.toDate().valueOf());

  const firestore = useFirestore();
  const payRequestDoc = doc(firestore, "tutor_pay", props.payRequest.id);

  const cancelRequest = () => {
    updateDoc(payRequestDoc, { status: "cancelled" });
  };

  return (
    <RequestItemStyled>
      <p>{props.payRequest.status}</p>
      <p>{props.payRequest.studentName}</p>
      <p>{dayjs(date).format("D MMMM YYYY")}</p>
      <p>
        {props.payRequest.duration.hours > 1
          ? `${props.payRequest.duration.hours}hrs`
          : `${props.payRequest.duration.hours}hr`}{" "}
        {props.payRequest.duration.minutes
          ? `${props.payRequest.duration.minutes}mins`
          : ""}
      </p>
      <p>{props.payRequest.notes ? props.payRequest.notes : "no notes"}</p>
      {props.payRequest.status === "pending" ? (
        <button onClick={cancelRequest}>Cancel</button>
      ) : (
        ""
      )}
    </RequestItemStyled>
  );
}

const RequestItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  border: solid black 1px;
  gap: 2px;
  padding: 5px;
`;
