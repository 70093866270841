import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { openError } from "../../../../redux/errorPopupSlice";
import { RootState } from "../../../../redux/store";
import { subjectColor } from "../../../../scripts/subject";
import TutorChatBubble from "../../../utilities/chat_bubble/TutorChatBubble";

// import { useFirestore } from "reactfire";
// import { startDirectMessage } from "../../../../data/database";
// import { openChat, selectChatroom } from "../../../../redux/chatDataSlice";
// import { closePopup } from "../../../../redux/tutorProfilePopupSlice";

export default function MainPanel() {
  const tutorProfilePopupData = useSelector(
    (state: RootState) => state.tutorProfilePopupData
  );

  const dispatch = useDispatch();

  // const firestore = useFirestore();
  // const user = useSelector((state: RootState) => state.userData);

  const startChat = () => {
    dispatch(
      openError(
        "Thanks for your interest! Chatting to an individual tutor is not implemented yet. In the meantime, contact us on messenger through the messenger button on the bottom right"
      )
    );

    // startDirectMessage(firestore, user, tutorProfilePopupData.tutor)
    //   .then((chatroomId) => {
    //     dispatch(selectChatroom(chatroomId));
    //     dispatch(closePopup());
    //     dispatch(openChat());
    //   })
    //   .catch((err) => console.error("Error creating chatroom: ", err));
  };

  const unavailable = tutorProfilePopupData.tutor.availability === 0;

  return (
    <MainPanelStyled>
      <TopSection>
        <StyledName>
          {tutorProfilePopupData.tutor.firstName}{" "}
          {tutorProfilePopupData.tutor.lastName}
        </StyledName>
        <Role>{tutorProfilePopupData.tutor.role}</Role>
        <SubjectBox>
          {tutorProfilePopupData.tutor.subject &&
            tutorProfilePopupData.tutor.subject.length > 0 &&
            tutorProfilePopupData.tutor.subject.map((subject) => (
              <Subject
                color={subjectColor(subject.subjectCode)}
                key={subject.subjectCode}
              >
                {subject.displayName}
              </Subject>
            ))}
        </SubjectBox>
        <ChatButtonHolder>
          <div
            onClick={!unavailable ? startChat : () => {}}
            style={unavailable ? { filter: "grayscale(95%)" } : {}}
          >
            <TutorChatBubble size={"50px"} unavailable={unavailable} />
          </div>
          <p>Chat with {tutorProfilePopupData.tutor.firstName}</p>
        </ChatButtonHolder>
      </TopSection>
      <MidSection>
        <CircleContainer>
          <Circle color={"#71BCFF"}>{tutorProfilePopupData.tutor.atar}</Circle>
          ATAR
        </CircleContainer>
        <CircleContainer>
          <Circle color={"#ceefac"}>
            {tutorProfilePopupData.tutor.availability}
          </Circle>
          Availabilities
        </CircleContainer>
      </MidSection>
      <Content>
        <Subheading>ABOUT</Subheading>
        {tutorProfilePopupData.tutor.description.map((desc) => (
          <div key={desc} style={{ marginBottom: "15px" }}>
            <Text>{desc}</Text>
          </div>
        ))}
        <Subheading>ACHIEVEMENTS</Subheading>
        <div>
          {tutorProfilePopupData.tutor.achievement.map((elem) => (
            <Text key={elem}>{elem}</Text>
          ))}
        </div>
      </Content>
    </MainPanelStyled>
  );
}

const MainPanelStyled = styled.div`
  width: calc(100% - 275px);
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px 70px 50px 50px;

  @media (max-width: 1100px) {
    width: calc(100% - 200px);
  }
  @media (max-width: 550px) {
    width: 100%;
    padding: 10px 30px;
  }
`;

const MidSection = styled.div`
  display: none;
  gap: 20px;
  width: 100%;
  justify-content: center;
  @media (max-width: 550px) {
    display: flex;
  }
`;

const CircleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 86px;

  font-family: "Cabin", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #000000;
  user-select: none;
`;

type CircleProps = {
  color: string;
};

const Circle = styled.div`
  border-radius: 50%;
  background: ${(props: CircleProps) => props.color};
  box-shadow: 0px 0px 10px rgba(206, 239, 172, 0.25);
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Cabin", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #000000;
  user-select: none;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: scroll;
  padding-right: 10px;
  padding-bottom: 10px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b8bcc8;
    border-radius: 10px;
  }

  & > div {
    margin-bottom: 10px;
  }

  @media (max-width: 550px) {
    overflow: visible;
    margin-top: 10px;
  }
`;

const TopSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 30px;
`;

const StyledName = styled.p`
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  color: #15375b;
`;

const Role = styled.p`
  margin: 0;
  font-family: "Cabin", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: #6aa6cc;
`;

const SubjectBox = styled.div`
  display: flex;
  width: 70%;
  gap: 5px;
  flex-wrap: wrap;
  margin-top: 20px;
`;

type SubjectProps = {
  color: string;
};

const Subject = styled.div`
  font-family: "Cabin", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 2px 10px;
  user-select: none;
  color: #000000;
  border-radius: 5px;

  background-color: ${(props: SubjectProps) => props.color};
`;

const ChatButtonHolder = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  top: 20%;
  align-items: center;

  @media (max-width: 800px) {
    visibility: hidden;
  }

  & > p {
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    margin-top: 5px;
    text-align: center;
    color: #15375b;
    user-select: none;
  }
`;

const Subheading = styled.p`
  margin: 0;
  font-family: "Cabin", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 5px;
  color: #000000;
`;

const Text = styled.p`
  margin: 0;
  font-family: "Cabin", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #5d646c;
`;
