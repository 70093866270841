import styled from "styled-components";
import dividerUp from "../../../assets/images/background/pricing-lines-top.svg";
import dividerDown from "../../../assets/images/background/pricing-lines-bottom.svg";

type PricingDividerProps = {
  type: "up" | "down";
};

export default function PricingDivider(props: PricingDividerProps) {
  return <Container type={props.type}></Container>;
}

type ContainerProps = {
  type: "up" | "down";
};

const Container = styled.div`
  background-image: url(
    ${(props: ContainerProps) =>
    props.type === "up" ? dividerUp : dividerDown});
  background-size: cover;
  background-position: center top;
  width: 100%;
  height: 500px;
  cursor: default;

  @media (min-width: 1800px) {
    height: 700px;
  }
  @media (min-width: 2500px) {
    height: 850px;
  }
  @media (min-width: 3000px) {
    height: 1000px;
  }
`;
