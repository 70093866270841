import styled from "styled-components";
import { TutorData, TutorPayData, UserData } from "../../../../data/databaseTypes";
import { Line } from "../../../utilities/Line";
import RequestItem from "./RequestItem";

type TutorPastRequestSectionProps = {
  tutorData: TutorData;
  students: UserData[];
  payRequests: TutorPayData[];
};

export default function TutorPastRequestSection(
  props: TutorPastRequestSectionProps
) {
  const sortPayRequests = (
    payRequestA: TutorPayData,
    payRequestB: TutorPayData
  ) => payRequestB.date - payRequestA.date;

  const sortedRequests =
    props.payRequests && props.payRequests.sort(sortPayRequests);

  return (
    <Container>
      <b>Past pay requests</b>
      <RequestHolder>
        {sortedRequests.map((payRequest) => (
          <RequestItem
            key={payRequest.id}
            payRequest={payRequest}
          ></RequestItem>
        ))}
      </RequestHolder>
      <Line color="black" />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 15px;
`;

const RequestHolder = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px;
`;
