export const subjectColor = (subject: string) => {
  switch (subject) {
    case "methods":
      return "#FECF88";
    case "specialist":
      return "#95E3E8";
    case "physics":
      return "#F9EF9C";
    case "chemistry":
      return "#FFCFCF";
    case "biology":
      return "#C5FE7C";
    case "english":
      return "#CA95FF";
    case "digital":
      return "#A7D5FF";
    case "philosophy":
      return "#7A98B7";
    case "englishLit":
      return "#D2DCEA";
    case "englishExt":
      return "#EDA1E1";
  }
  return "";
};