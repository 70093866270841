import { useEffect, useState } from "react";
import styled from "styled-components";
import { AssignmentData, UserData } from "../../../data/databaseTypes";
import Assignment from "./Assignment";

type AdminAssignmentsProps = {
  assignments: AssignmentData[];
  students: UserData[];
};

export default function AdminAssignments(props: AdminAssignmentsProps) {
  const sortAssignment = (
    assignmentA: AssignmentData,
    assignmentB: AssignmentData
  ) => {
    return assignmentB.uploaded - assignmentA.uploaded;
  };

  const [showMarked, setShowMarked] = useState(false);
  const [sortedAssignments, setSortedAssignments] = useState<AssignmentData[]>(
    []
  );

  const handleChange = () => {
    setShowMarked(!showMarked);
  };

  useEffect(() => {
    if (!props.assignments) return;
    if (showMarked)
      setSortedAssignments(props.assignments.sort(sortAssignment));
    else
      setSortedAssignments(
        props.assignments
          .filter((assignment) => assignment.status !== "marked")
          .sort(sortAssignment)
      );
  }, [showMarked, props.assignments]);

  // find student name given stuent ID
  const findStudentName = (userID: string) => {
    if (!props.students) return "unknown";
    const student = props.students.filter((student) => student.uid === userID);
    if (student.length === 1)
      return `${student[0].firstName} ${student[0].lastName}`;
    return "unknown";
  };

  return (
    <Section>
      <b>Assignments</b>

      <form>
        <input
          type="checkbox"
          id={"show_assignments"}
          checked={showMarked}
          onChange={handleChange}
        />
        <label htmlFor="show_assignments">
          {" "}
          {showMarked ? "don't show" : "show"} marked
        </label>
      </form>

      <AssignmentList>
        {sortedAssignments &&
          sortedAssignments.map((assignment) => (
            <Assignment
              key={assignment.userId + assignment.uploaded}
              filePaths={assignment.filePaths}
              status={assignment.status}
              uploaded={assignment.uploaded}
              userId={assignment.userId}
              id={assignment.id}
              name={findStudentName(assignment.userId)}
            ></Assignment>
          ))}
      </AssignmentList>
    </Section>
  );
}

const Section = styled.div`
  width: 100%;
  padding: 20px;
`;

const AssignmentList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  gap: 5px;
`;
