import { Chats } from "phosphor-react";
import styled from "styled-components";

type FaqItemProps = {
  circleColor: string;
  question: string;
  answer: string;
};

export default function FaqItem(props: FaqItemProps) {
  return (
    <FaqContainer>
      <FaqCircle circleColor={props.circleColor}>
        <Chats size={40} color={"white"} />
      </FaqCircle>
      <Question>{props.question}</Question>
      <Answer>{props.answer}</Answer>
    </FaqContainer>
  );
}

const FaqContainer = styled.div`
  display: flex;
  position: relative;
  background-color: #fff;
  padding: 25px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  border-radius: 50px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  padding: 50px 150px;

  @media (max-width: 1000px) {
    padding: 70px;
  }
  @media (max-width: 550px) {
    padding: 70px 50px;
  }
  @media (max-width: 370px) {
    padding: 70px 30px;
  }
`;

type FaqCircleProps = {
  circleColor: string;
};

const FaqCircle = styled.div`
  height: 80px;
  width: 80px;
  top: -40px;
  left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${(props: FaqCircleProps) => props.circleColor};
  position: absolute;

  @media (max-width: 550px) {
    left: -10px;
    top: -30px;
  }
`;

const Question = styled.p`
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 22px;
`;

const Answer = styled.p`
  font-family: "Raleway", sans-serif;
  font-size: 17px;
`;
