import { useEffect, useRef, useState } from "react";
import { useFirestore, useFirestoreCollectionData, useUser } from "reactfire";
import { useDispatch, useSelector } from "react-redux";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";
import { RootState } from "./redux/store";

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import ChatScreen from "./screens/ChatScreen";
import TutoringScreen from "./screens/TutoringScreen";
import ProductScreen from "./screens/ProductScreen";
import HomeScreen from "./screens/HomeScreen";
import SignInScreen from "./screens/SignInScreen";
import AboutUsScreen from "./screens/AboutUsScreen";
import { addUser, removeUser } from "./redux/userDataSlice";
import { UserData } from "./data/databaseTypes";
import AccountScreen from "./screens/AccountScreen";
import ChatPricingScreen from "./screens/ChatPricingScreen";
import { setChatroomUnreads } from "./redux/chatDataSlice";
import ServicesScreen from "./screens/ServicesScreen";
import PricingScreen from "./screens/PricingScreen";
import AdminPanelScreen from "./screens/AdminPanelScreen";
import TutorPanelScreen from "./screens/TutorPanelScreen";
import styled from "styled-components";
import { useLax } from "use-lax";
import { elements } from "./components/screen_components/home/info_section/lax-elements";
import dayjs from "dayjs";
import "overlayscrollbars/css/OverlayScrollbars.css";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import ErrorPopup from "./components/popups/error/ErrorPopup";
import Error404 from "./screens/Error404";
import FooterInfoScreen from "./screens/FooterInfoScreen";

export default function InnerApp() {
  //data
  const { data: user } = useUser();
  const userData = useSelector((state: RootState) => state.userData);
  const dispatch = useDispatch();
  const firestore = useFirestore();

  // Get chatrooms that the user is a part of
  const chatroomQuery = query(
    collection(firestore, "chatrooms"),
    where("userIds", "array-contains", userData.uid)
  );
  const { data: chatrooms } = useFirestoreCollectionData(chatroomQuery, {
    idField: "id",
  });

  // On data change, check for any new messages
  useEffect(() => {
    if (chatrooms && user && userData.uid) {
      chatrooms.forEach((chatroom) => {
        const lastActive =
          chatroom.lastActive[
            chatroom.userIds.findIndex((id: string) => id === userData.uid)
          ];
        if (lastActive && chatroom.id) {
          const lastActiveModified = dayjs(lastActive.toDate()).add(1000, "ms");
          const messageQuery = query(
            collection(firestore, "messages"),
            where("chatroomId", "==", chatroom.id),
            where("sent", ">", lastActiveModified.toDate()),
            limit(10)
          );
          getDocs(messageQuery)
            .then((snapshot) => {
              // Filter out messages sent by user (since they will obviously have been read)
              const filteredUnreads = snapshot.docs.filter(
                (doc) => doc.data().senderId !== userData.uid
              );
              dispatch(
                setChatroomUnreads({
                  chatroomId: chatroom.id,
                  unreads: filteredUnreads.length,
                })
              );
            })
            .catch((e) => {
              /* Read errors will occur when creating new chatrooms, but can be ignored */
            });
        }
      });
    }
  }, [chatrooms, firestore, dispatch, userData.uid, user]);

  useEffect(() => {
    if (userData.uid === "-1" && user) {
      getDoc(doc(firestore, "users", user.uid))
        .then((data) => {
          if (data.exists()) {
            const tempData = data.data() as UserData;
            tempData.joined = tempData.joined.toDate().valueOf();
            dispatch(addUser(tempData));
          } else {
            dispatch(removeUser());
          }
        })
        .catch((error) => console.log(error));
    }
  });

  // Guard components
  type GuardedRouteProps = {
    path: string;
    children: any;
  };

  const GuardedAdminRoute = (props: GuardedRouteProps) => {
    return (
      <Route
        path={props.path}
        render={() =>
          userData.type === "admin" ? props.children : <Redirect to={"/"} />
        }
      />
    );
  };

  const GuardedTutorRoute = (props: GuardedRouteProps) => {
    return (
      <Route
        path={props.path}
        render={() =>
          userData.type === "tutor" || userData.type === "admin" ? (
            props.children
          ) : (
            <Redirect to={"/"} />
          )
        }
      />
    );
  };

  // UI
  const pageRef = useRef<OverlayScrollbarsComponent>(null);
  const [scroll, setScroll] = useState(0);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  /* on mount scroll to top add scroll listner 
    remove modal open
    apply resize listeners
  */
  useEffect(() => {
    document.body.classList.remove("modal-open");
    const page = pageRef.current;
    if (page) {
      window.addEventListener("resize", controlHeightAndWidth);
    }
    return () => {
      if (page) {
        window.removeEventListener("resize", controlHeightAndWidth);
      }
    };
  }, []);

  const controlHeightAndWidth = () => {
    let height = window.innerHeight;
    setWindowHeight(height);
    let width = window.innerWidth;
    setWindowWidth(width);
  };

  // lax setup
  useLax({
    drivers: [
      {
        name: "scrollY",
        getValueFn: () => laxGetScroll(),
      },
    ],
    elements: elements,
  });

  const laxGetScroll = () => {
    let top = 0;
    if (pageRef.current && pageRef.current.osInstance())
      top = pageRef.current.osInstance()?.scroll().position.y || 0;
    return top;
  };

  return (
    <ScrollView
      ref={pageRef}
      options={{
        callbacks: {
          onScroll: (e) => {
            if (pageRef.current && pageRef.current.osInstance())
              setScroll(pageRef.current.osInstance()?.scroll().position.y || 0);
          },
        },
        scrollbars: { autoHide: "scroll" },
        overflowBehavior: { x: "hidden", y: "scroll" },
      }}
    >
      <Router>
        <div className="App">
          <ChatScreen />
          <AccountScreen />
          <ErrorPopup />
          <Switch>
            <Route exact path="/">
              <HomeScreen
                isScroll={scroll >= 25}
                windowHeight={windowHeight}
                windowWidth={windowWidth}
                scrollerContainer={pageRef.current}
              />
            </Route>
            <Route path="/signin">
              <SignInScreen />
            </Route>
            <Route path="/tutoring">
              <TutoringScreen
                isScroll={scroll >= 25}
                scrollerContainer={pageRef.current}
              />
            </Route>
            <Route path="/assignments">
              <ProductScreen
                isScroll={scroll >= 25}
                scrollerContainer={pageRef.current}
              />
            </Route>
            <Route path="/aboutus">
              <AboutUsScreen
                isScroll={scroll >= 25}
                scrollerContainer={pageRef.current}
              />
            </Route>
            <Route path="/services">
              <ServicesScreen
                isScroll={scroll >= 25}
                scrollerContainer={pageRef.current}
              />
            </Route>
            <Route path="/clarifychat">
              <ChatPricingScreen
                isScroll={scroll >= 25}
                scrollerContainer={pageRef.current}
              />
            </Route>
            <Route path="/pricing">
              <PricingScreen
                isScroll={scroll >= 25}
                scrollerContainer={pageRef.current}
              />
            </Route>
            <GuardedAdminRoute
              path={"/admin"}
              children={
                <AdminPanelScreen scrollerContainer={pageRef.current} />
              }
            />
            <GuardedTutorRoute
              path={"/tutor-panel"}
              children={
                <TutorPanelScreen scrollerContainer={pageRef.current} />
              }
            />
            <Route path="/terms">
              <FooterInfoScreen scrollerContainer={pageRef.current} type={"terms"}/>
            </Route>
            <Route path="/privacy">
              <FooterInfoScreen scrollerContainer={pageRef.current} type={"privacy"}/>
            </Route>
            <Route path="/contactus">
              <FooterInfoScreen scrollerContainer={pageRef.current} type={"contactus"}/>
            </Route>
            <Route>
              <Error404 />
            </Route>
          </Switch>
        </div>
      </Router>
    </ScrollView>
  );
}

const ScrollView = styled(OverlayScrollbarsComponent)`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  overflow-y: hidden;
`;
