import styled from "styled-components";
import track from "../../../assets/images/background/blue-train-tracks.svg";
import trackSmall from "../../../assets/images/background/blue-train-track-small.svg";
import ServicesInfoItem from "./ServicesInfoItem";

export default function ServicesInfoSection() {
  return (
    <Wrapper>
      <InfoContainer>
        <ServicesInfoItem
          name={"CLARIFY"}
          text={"concepts with our 24/7 chat function"}
        />
        <ServicesInfoItem
          name={"REFINE"}
          text={"Internal Assessments (IAs) with our detailed feedback"}
        />
        <ServicesInfoItem
          name={"APPLY"}
          text={"knowledge and understanding through our tutoring sessions"}
        />
      </InfoContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  cursor: default;
  height: 500px;
  background: url(${track});
  background-position: -150px 0;
  background-repeat: no-repeat;
  position: relative;

  @media (max-width: 1000px) {
    background-size: 2500px;
    background-position: -75px 0;
    height: 400px;
  }
  @media (max-width: 800px) {
    background: url(${trackSmall});
    background-position: -150px 0;
    height: 850px;
    background-repeat: no-repeat;
  }
  @media (max-width: 425px) {
    background-position: -200px 0;
  }
`;

const InfoContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100% - 175px);
  height: 100%;
  display: flex;
  justify-content: space-around;

  @media (max-width: 800px) {
    flex-direction: column;
    width: 100%;
    height: 60%;
    top: 160px;
  }
  @media (max-width: 320px) {
    top: 200px;
  }
`;
