import styled from "styled-components";
import ChatMessage from "./ChatMessage";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGrinBeam,
  faPaperPlane,
  faSmileBeam,
} from "@fortawesome/free-solid-svg-icons";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import {
  addDoc,
  collection,
  limit,
  orderBy,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import dayjs from "dayjs";
import { ChatroomData, MessageData } from "../../../data/databaseTypes";
import { markActiveNow } from "../../../data/database";
import Picker, { IEmojiData } from "emoji-picker-react";
import "./custom-emoji-picker-styles.css";
import { closeEmoji, openEmoji } from "../../../redux/chatDataSlice";

type ChatMainPanelProps = {
  chatroom: ChatroomData;
  closeChat: any;
};

export default function ChatMainPanel(props: ChatMainPanelProps) {
  const [inputData, setInputData] = useState("");
  const chatRef = useRef<HTMLDivElement>(null);
  const textBoxref = useRef<HTMLInputElement>(null);

  const emojiPickerOpen = useSelector(
    (state: RootState) => state.chatData.emojiOpen
  );

  const userData = useSelector((state: RootState) => state.userData);
  const userId = userData.uid;

  const chatOpen = useSelector((state: RootState) => state.chatData.open);
  const [oldChatOpen, setOldChatOpen] = useState<boolean>(false);

  const [oldChatroomId, setOldChatroomId] = useState<string>(props.chatroom.id);

  const [messageCache, setMessageCache] = useState<any[] | null>(null);

  const chatroomId = props.chatroom.id;

  const dispatch = useDispatch();

  let chatroomName = props.chatroom.name;
  if (props.chatroom.type === "direct") {
    const otherName = props.chatroom.userNames.find(
      (name, index) => props.chatroom.userIds[index] !== userId
    );
    if (otherName) chatroomName = otherName;
  }

  const otherActives = props.chatroom.lastActive
    .filter((a, index) => props.chatroom.userIds[index] !== userId)
    .map((a) => (a ? a.toDate() : new Date(0)));

  const firestore = useFirestore();
  const messagesCollection = collection(firestore, "messages");
  const messagesQuery = query(
    messagesCollection,
    where("chatroomId", "==", chatroomId),
    orderBy("sent", "desc"),
    limit(20)
  );

  const { data: messages, status } = useFirestoreCollectionData(messagesQuery, {
    idField: "id",
  });

  useEffect(() => {
    props.closeChat.current = () => {
      setOldChatOpen(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Update last read if:
    // 1. Chat opened
    // 2. Switched to chatroom
    // 3. Message received

    const markAsRead = (cache: boolean) => {
      setMessageCache(cache ? messages : null);
      markActiveNow(firestore, userId, props.chatroom);
      setOldChatOpen(chatOpen);
      setOldChatroomId(props.chatroom.id);
    };

    const chatOpened = chatOpen !== oldChatOpen && chatOpen;
    const chatroomSwitched = props.chatroom.id !== oldChatroomId;
    if (status !== "success") {
      setMessageCache(null);
    } else if (chatOpened || chatroomSwitched) {
      // 1 & 2
      markAsRead(!chatroomSwitched);

      if (chatOpened) console.log("Marking Sent - Chat Opened");
      else console.log("Marking Sent - Chatroom Switched");
      // Catch attempt to mark read on load
    } else if (messages && !messageCache) {
      setMessageCache(messages);
    } else if (chatOpen && userId && messages && messageCache) {
      // 3
      const messagesChanged =
        JSON.stringify(messages) !== JSON.stringify(messageCache);

      if (messagesChanged) {
        if (chatRef.current) {
          chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }

        let boundedIndex = 0;

        if (messageCache.length > 0) {
          const lastCachedMessageId = messageCache[0].id;
          const newMessageIndex = messages.findIndex(
            (m) => m.id === lastCachedMessageId
          );
          boundedIndex = Math.max(
            0,
            Math.min(messages.length, newMessageIndex)
          );
        }

        const newMessages = messages.slice(0, boundedIndex);
        const messagesWereReceived = newMessages
          .map((m) => m.senderId)
          .some((sId) => sId !== userId);

        if (messagesWereReceived) {
          console.log("Marking Sent - Message Received");
          markAsRead(true);
        }

        textBoxref.current?.focus();
      }
    }
  }, [
    chatOpen,
    oldChatOpen,
    messages,
    messageCache,
    userId,
    props.chatroom,
    firestore,
    oldChatroomId,
    status,
  ]);

  const chatUsers = props.chatroom.userIds.map((u, index) => {
    const currentImage = props.chatroom.userImages[index];
    return {
      id: u,
      name: props.chatroom.userNames[index],
      image: currentImage.includes("unsplash")
        ? currentImage + "?w=300"
        : currentImage,
    };
  });

  const sendMessage = () => {
    if (inputData.trim().length !== 0) {
      setInputData("");
      const messageData: MessageData = {
        type: "text",
        chatroomId,
        senderId: userId,
        sent: serverTimestamp(),
        data: inputData,
      };
      addDoc(messagesCollection, messageData).catch((error) =>
        console.error(error)
      );
    }
  };

  const formattedMessages = messages ? [...messages] : [];

  const mostRecentSentIndex = formattedMessages.findIndex(
    (m) => m.senderId === userId
  );

  const onEmojiClick = (e: React.MouseEvent, emojiObject: IEmojiData) => {
    setInputData((prevInput) => prevInput + emojiObject.emoji);
  };

  return (
    <MainChatBackground>
      <ChatWrapper ref={chatRef} onClick={() => textBoxref.current?.focus()}>
        {formattedMessages.map((message, index) => {
          const chatUser = chatUsers.find((u) => message.senderId === u.id);

          if (!chatUser) return <div key={message.id}></div>;

          return (
            <ChatMessage
              key={message.id}
              name={chatUser.name}
              type={message.senderId === userId ? "sent" : "received"}
              firstInGroup={
                index === formattedMessages.length - 1 ||
                formattedMessages[index + 1].senderId !== message.senderId ||
                messagesFarApart(
                  message as MessageData,
                  formattedMessages[index + 1] as MessageData
                )
              }
              lastInGroup={
                index === 0 ||
                formattedMessages[index - 1].senderId !== message.senderId ||
                messagesFarApart(
                  message as MessageData,
                  formattedMessages[index - 1] as MessageData
                )
              }
              message={message.data}
              image={chatUser.image}
              time={message.sent ? message.sent.toDate() : new Date(0)}
              seen={
                index === mostRecentSentIndex &&
                otherActives.some((active) =>
                  dayjs(active)
                    .add(1000, "ms")
                    .isAfter(
                      dayjs(message.sent ? message.sent.toDate() : new Date())
                    )
                )
              }
            />
          );
        })}
        {formattedMessages.length === 0 && status === "success" && (
          <h3>You haven't sent any messages to {chatroomName}</h3>
        )}
      </ChatWrapper>
      <SendWrapper>
        <TextFieldWrapper>
          <SendText
            placeholder={`Type a message to ${chatroomName}...`}
            value={inputData}
            onChange={(e) => setInputData(e.target.value)}
            ref={textBoxref}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                sendMessage();
              }
            }}
          />
          <EmojiButton
            onClick={() => {
              emojiPickerOpen ? dispatch(closeEmoji()) : dispatch(openEmoji());
            }}
          >
            {!emojiPickerOpen ? (
              <FontAwesomeIcon icon={faSmileBeam} />
            ) : (
              <FontAwesomeIcon icon={faGrinBeam} />
            )}
          </EmojiButton>
          {emojiPickerOpen && (
            <div className={"custom-emoji-picjer-styles"}>
              <Picker
                pickerStyle={{
                  width: "300px",
                  top: "-380px",
                  left: "0",
                  borderRadius: "15px",
                  fontStyle: "Cabin, sans-serif",
                }}
                onEmojiClick={onEmojiClick}
                disableSearchBar
              />
            </div>
          )}
        </TextFieldWrapper>

        <SendButton onClick={sendMessage}>
          <FontAwesomeIcon icon={faPaperPlane} />
        </SendButton>
      </SendWrapper>
    </MainChatBackground>
  );
}

const messagesFarApart = (messageOne: MessageData, messageTwo: MessageData) => {
  if (!messageOne.sent || !messageTwo.sent) return true;

  const minutesBetween = dayjs(messageOne.sent.toDate()).diff(
    messageTwo.sent.toDate(),
    "minutes"
  );
  return Math.abs(minutesBetween) > 3;
};

const MainChatBackground = styled.div`
  flex-grow: 4;
  border-radius: 0 25px 25px 0;
  background-color: #eaf2f4;
  height: 100%;
  position: relative;
`;

const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 0 10px 0 20px;
  margin: 50px 60px 20px 20px;
  height: calc(100% - 150px);
  overflow: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b8bcc8;
    border-radius: 3px;
  }
`;

const SendWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 5%;
  position: relative;
`;

const TextFieldWrapper = styled.div`
  margin-right: 10px;
  width: 100%;
  max-height: 55px;
  position: relative;
`;

const SendText = styled.input`
  font-size: 16px;
  width: 100%;
  padding: 5px 20px;
  border-radius: 20px;
  border: none;
  outline: 0;
  height: 55px;
  color: #585858;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  font-family: "Cabin", sans-serif;

  &::placeholder {
    font-size: 16px;
    color: rgba(167, 167, 167, 1);
    font-family: "Cabin", sans-serif;
  }
`;

const EmojiButton = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 27%;
  right: 20px;
  font-size: 20px;
  cursor: pointer;
  color: rgba(167, 167, 167, 1);
`;

const SendButton = styled.div`
  height: 50px;
  width: 50px;
  background-color: #71bcff;
  color: white;
  font-size: 20px;
  padding-right: 3px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
