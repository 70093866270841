import { CloudArrowUp, CloudCheck } from "phosphor-react";
import styled from "styled-components";
import { FileUploader } from "react-drag-drop-files";
import AssignmentFileComponent from "./AssignmentFileComponent";

type AssignmentUploadPanelProps = {
  chosenFilesArr: File[];
  handleChange: any;
  filesUploaded: string[];
  uploading: boolean;
  completed: boolean;
};

export default function AssignmentUploadPanel(
  props: AssignmentUploadPanelProps
) {
  const fileTypes = ["JPEG", "JPG", "PNG", "PDF", "DOCX"];

  const handleFiles = (files: FileList) => {
    const newFileArr = Array.from(files);
    const combinedFileArr = props.chosenFilesArr.concat(newFileArr);
    props.handleChange(combinedFileArr);
  };

  const removeFile = (idx: number) => {
    const newFileArr = [...props.chosenFilesArr];
    newFileArr.splice(idx, 1);
    props.handleChange(newFileArr);
  };

  return (
    <Panel>
      <FileUploader
        name={"Assignment Upload"}
        multiple={true}
        label="Upload or drop a file right here"
        hoverTitle=" "
        types={fileTypes}
        handleChange={handleFiles}
        maxSize={30}
        onSizeError={() => alert("30MB max file size limit")}
        onTypeError={() => alert("Clarify accepts PDF, PNG, JPG, JPEG, DOCX")}
      >
        <Docs>
          {props.chosenFilesArr &&
            props.chosenFilesArr.length > 0 &&
            props.chosenFilesArr.map((f, idx) => (
              <AssignmentFileComponent
                key={idx}
                idx={idx}
                name={f.name}
                status={
                  props.filesUploaded.includes(f.name)
                    ? "completed"
                    : props.uploading
                    ? "uploading"
                    : false
                }
                removeSelf={removeFile}
                type={
                  f.name.toLowerCase().includes("docx")
                    ? "DOC"
                    : f.name.toLowerCase().includes("pdf")
                    ? "PDF"
                    : f.name.toLowerCase().includes("jpg")
                    ? "IMAGE"
                    : f.name.toLowerCase().includes("png")
                    ? "IMAGE"
                    : f.name.toLowerCase().includes("jpeg")
                    ? "IMAGE"
                    : "FILE"
                }
              />
            ))}
        </Docs>
        {props.chosenFilesArr.length === 0 && (
          <Content>
            {!props.completed ? (
              <>
                <CloudArrowUp size={84} color={"#272727"} />
                <p>Choose a file or drag it here</p>
                <p>
                  Try uploading all supporting documents a docx file, task
                  sheet, stimulus etc
                </p>
              </>
            ) : (
              <>
                <CloudCheck size={84} color={"#272727"} />
                <p>All files successfully uploaded</p>
                <p>
                  Make sure to let a tutor know, so we can review your documents
                  and send over an invoice
                </p>
              </>
            )}
          </Content>
        )}
      </FileUploader>
    </Panel>
  );
}

const Panel = styled.div`
  width: 100%;
  height: 80%;
  max-height: 415px;
  min-height: 210px;
  background: rgba(255, 247, 235, 0.3);
  border: 2px dashed #ffc770;
  border-radius: 18px;
  display: flex;
  flex-direction: row;

  & > label {
    width: 100%;
    height: 100%;
    border-radius: 18px;
  }
  & > label > div {
    border-radius: 17px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 250px;
  text-align: center;
  position: absolute;
  filter: opacity(0.7);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & > p {
    font-family: "Cabin", sans-serif;
  }
`;

const Docs = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 50px 25px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 50px;
`;
