import styled from "styled-components";
import { ScreenSubHeading } from "../../utilities/ScreenText";
import FaqItem from "../../utilities/FaqItem";

export default function PricingFAQ() {
  return (
    <PricingFAQStyled>
      <ScreenSubHeading style={{ alignSelf: "flex-start" }}>
        Frequently Asked Questions
      </ScreenSubHeading>
      <FAQsContainer>
        <FaqItem
          circleColor={"#FFC770"}
          question={
            "Who will be marking and providing feedback on my assignments?"
          }
          answer={
            "Our dedicated team of Clarify Tutors are here to mark and provide feedback on your assignments. We ensure that the subject you need marking on has tutors which obtained a high subject score in that subject. On top of this, the marks we distribute are cross-checked with other tutors to verify we are giving the most accurate feedback possible."
          }
        />
        <FaqItem
          circleColor={"#245785"}
          question={"How do we pay for this service?"}
          answer={
            "Once our tutors have confirmed we can offer assignment feedback, we will send you an invoice to pay for the service. The payment is done securely via Stripe. Once we receive confirmation of the payment, our tutors will give feedback and mark your assignments within 72 hours."
          }
        />
        <FaqItem
          circleColor={"#6AA6CC"}
          question={"What do I need to supply to the tutors?"}
          answer={
            "To assist the tutors with providing feedback, please provide all documents that you feel are necessary. This would include your assignment, task sheets and any other information that is important for the assessment marker to know. Word Documents are preferred for assignment feedback for tutors as a means to easily give feedback via comments and tracked changes."
          }
        />
        <FaqItem
          circleColor={"#FF8270"}
          question={"When will I get my assignment feedback and marking back?"}
          answer={
            "We endeavour to mark your assignments and provide feedback ASAP. All assignments have a 72-hour guarantee of being returned to the student. If you would like to receive urgent feedback, please contact us regarding this."
          }
        />
      </FAQsContainer>
    </PricingFAQStyled>
  );
}

const PricingFAQStyled = styled.div`
  width: 100%;
  cursor: default;
  background-color: #ffffff;
  padding: 20px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  @media (max-width: 1000px) {
    padding: 20px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px;
  }
`;

const FAQsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 70px;
  margin: 70px 0;
`;
