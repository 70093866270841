import styled from "styled-components";
import ChatBubbleImg from "../../../assets/images/utilities/chat_bubble.svg";

type TutorChatBubbleProps = {
  size?: string;
  unavailable?: boolean;
};

export default function TutorChatBubble(props: TutorChatBubbleProps) {
  return (
    <ChatBubbleContainer size={props.size} unavailable={props.unavailable}>
      <img src={ChatBubbleImg} alt="chat_bubble" />
    </ChatBubbleContainer>
  );
}

type ChatBubbleContainerProps = {
  size?: string;
  unavailable?: boolean;
};

const ChatBubbleContainer = styled.div`
  width: ${(props: ChatBubbleContainerProps) =>
    props.size ? props.size : "40px"};
  height: ${(props: ChatBubbleContainerProps) =>
    props.size ? props.size : "40px"};
  border-radius: 50%;
  background-color: #ff8270;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props: ChatBubbleContainerProps) =>
    !props.unavailable ? "pointer" : "default"};
  transition: 300ms ease;
  user-select: none;

  & img {
    width: 70%;
  }

  &:hover {
    background-color: ${(props: ChatBubbleContainerProps) =>
      !props.unavailable ? "#f57b69" : "#ff8270"};
  }
`;
