import SecondaryButton from "../../utilities/button/SecondaryButton";

export default function SignOutButton() {
  return (
    <SecondaryButton
      name={"Sign out"}
      color={"#FFC770"}
      hover={"#f1bd69"}
      textColor={"#15375B"}
      width={"150px"}
      height={"40px"}
    />
  );
}