import styled from "styled-components";
import { ScreenSubHeading, ScreenSubText } from "../../utilities/ScreenText";
import RedCrossIcon from "../../utilities/icons/RedCrossIcon";
import GreenCheckIcon from "../../utilities/icons/GreenCheckIcon";

export default function ProductWhyUsSection() {
  return (
    <ProductWhyUsStyled>
      <ScreenSubHeading>Why us?</ScreenSubHeading>
      <ScreenSubText>The feedback you usually receive from school:</ScreenSubText>
      <Limitations>
        <LimitationItem>
          <RedCrossIcon />
          <ScreenSubText>Limited</ScreenSubText>
        </LimitationItem>
        <LimitationItem>
          <RedCrossIcon />
          <ScreenSubText>Zero score indication</ScreenSubText>
        </LimitationItem>
        <LimitationItem>
          <RedCrossIcon />
          <ScreenSubText>Little to no suggestions</ScreenSubText>
        </LimitationItem>
        <LimitationItem>
          <RedCrossIcon />
          <ScreenSubText>Sometimes ambiguous</ScreenSubText>
        </LimitationItem>
      </Limitations>
      <ScreenSubText>
        We were once in your shoes and know that ambiguous feedback is
        frustrating. That's why we've developed a detailed and criteria-specific
        feedback marking service. Our feedback is unique as we accompany each
        assignment we review with a{" "}
        <b>10 minute consultation video or chat session</b> to clarify all your
        concerns. We ensure that feedback is up to the highest quality by cross
        checking our students' work with multiple tutors.
      </ScreenSubText>
      <Item
        style={{
          marginTop: "10px",
          alignSelf: "center",
          maxWidth: "90%",
        }}
      >
        <GreenCheckIcon />
        <ScreenSubText>
          Give us a chat to find out more or upload now with a 3 day turnaround
        </ScreenSubText>
      </Item>
    </ProductWhyUsStyled>
  );
}

const ProductWhyUsStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 100px 100px 100px;
  gap: 30px;
  cursor: default;

  @media (max-width: 1000px) {
    padding: 20px 60px 100px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px 100px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px 35px 100px 35px;
  }
`;

const Limitations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
  margin: 10px 0;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

const LimitationItem = styled(Item)`
  width: 200px;
`;
