import { createSlice } from "@reduxjs/toolkit";

type accountPopupSliceType = {
  open: boolean;
  imageSelectorOpen: boolean;
};

const initialState: accountPopupSliceType = {
  open: false,
  imageSelectorOpen: false,
};

export const accountPopupSlice = createSlice({
  name: "accountPopupData",
  initialState,
  reducers: {
    openPopup: (state) => {
      document.body.classList.add("modal-open");
      state.open = true;
    },
    closePopup: (state) => {
      document.body.classList.remove("modal-open");
      state.open = false;
      state.imageSelectorOpen = false;
    },
    openImageSelector: (state) => {
      state.imageSelectorOpen = true;
    },
    closeImageSelector: (state) => {
      state.imageSelectorOpen = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { openPopup, closePopup, openImageSelector, closeImageSelector } =
  accountPopupSlice.actions;

export default accountPopupSlice.reducer;
