import styled from "styled-components";
import { TutorData } from "../../../data/databaseTypes";
import TutorChatBubble from "../../utilities/chat_bubble/TutorChatBubble";
import ProfileImage from "../../utilities/ProfileImage";
import { useDispatch } from "react-redux";
import { openPopup } from "../../../redux/tutorProfilePopupSlice";
import { subjectColor } from "../../../scripts/subject";
import { openError } from "../../../redux/errorPopupSlice";

// import { useSelector } from "react-redux";
// import { useFirestore } from "reactfire";
// import { RootState } from "../../../redux/store";
// import { startDirectMessage } from "../../../data/database";
// import { openChat, selectChatroom } from "../../../redux/chatDataSlice";

type TutorProfileComponentProps = {
  tutorInfo: TutorData;
  unavailable?: boolean;
};

export default function TutorProfileComponent(
  props: TutorProfileComponentProps
) {
  // const firestore = useFirestore();
  // const user = useSelector((state: RootState) => state.userData);

  const dispatch = useDispatch();

  const openTutorProfile = () => {
    dispatch(openPopup(props.tutorInfo));
  };

  const startChat = () => {
    dispatch(
      openError(
        "Thanks for your interest! Chatting to a individual tutor is not implemented yet. In the meantime, contact us on messenger through the messenger button on the bottom right"
      )
    );

    // startDirectMessage(firestore, user, props.tutorInfo)
    //   .then((chatroomId) => {
    //     dispatch(selectChatroom(chatroomId));
    //     dispatch(openChat());
    //   })
    //   .catch((err) => console.error("Error creating chatroom: ", err));
  };

  return (
    <ProfileComponent>
      <TopSection>
        <ImageSection unavailable={props.unavailable}>
          <ProfileImage
            size={150}
            url={props.tutorInfo.profileImage}
            onClick={openTutorProfile}
          />
          <span onClick={!props.unavailable ? startChat : () => {}}>
            <TutorChatBubble unavailable={props.unavailable} />
          </span>
        </ImageSection>
        <StyledName>
          {props.tutorInfo.firstName} {props.tutorInfo.lastName.charAt(0)}
        </StyledName>
        <StyledSubject unavailable={props.unavailable}>
          {!props.unavailable ? props.tutorInfo.role : "Unavailable"}
        </StyledSubject>
      </TopSection>
      <StyledDescription>
        {props.tutorInfo.description[0].length <= 175
          ? props.tutorInfo.description
          : props.tutorInfo.description[0].substring(0, 170) + "..."}
      </StyledDescription>
      <SubjectBox>
        {props.tutorInfo.subject &&
          props.tutorInfo.subject.length > 0 &&
          props.tutorInfo.subject.map((subject) => (
            <Subject
              color={subjectColor(subject.subjectCode)}
              key={subject.subjectCode}
            >
              {subject.displayName}
            </Subject>
          ))}
      </SubjectBox>
    </ProfileComponent>
  );
}

const ProfileComponent = styled.div`
  position: relative;
  display: flex;
  padding: 20px 10px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 250px;
  cursor: default;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

type ImageSectionProps = {
  unavailable?: boolean;
};

const ImageSection = styled.div`
  position: relative;

  & > span {
    position: absolute;
    bottom: 0;
    right: -8px;
  }
  filter: ${(props: ImageSectionProps) =>
    props.unavailable ? "grayscale(95%)" : ""};
`;

const StyledName = styled.p`
  font-family: "Cabin", sans-serif;
  font-weight: bold;
  font-size: 26px;
  text-align: center;
  margin: 0;
  font-style: normal;
  color: #272725;
`;

type StyledSubjectProps = {
  unavailable?: boolean;
};

const StyledSubject = styled.p`
  font-family: "Cabin", sans-serif;
  font-size: 20px;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  color: ${(props: StyledSubjectProps) =>
    props.unavailable ? "#FF8270" : "#6aa6cc"};
`;

const StyledDescription = styled.p`
  font-family: "Cabin", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #5d646c;
  margin: 0;
`;

const SubjectBox = styled.div`
  display: flex;
  width: 90%;
  gap: 5px;
  flex-wrap: wrap;
  margin-top: 10px;
`;

type SubjectProps = {
  color: string;
};

const Subject = styled.div`
  font-family: "Cabin", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 2px 10px;
  user-select: none;
  color: #000000;
  border-radius: 5px;

  background-color: ${(props: SubjectProps) => props.color};
`;
