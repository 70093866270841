import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styled from "styled-components";

type StepperProps = {
  text?: string;
  color: string;
  hover: string;
  default: number;
  min: number;
  max: number;
  handleChange: (value: number) => void;
};

export default function Stepper(props: StepperProps) {
  const [value, setValue] = useState(props.default);

  const reduce = () => {
    const newValue = value - 1;
    if (value !== props.min) {
      setValue(newValue);
      props.handleChange(newValue);
    }
  };

  const increase = () => {
    const newValue = value + 1;
    if (value !== props.max) {
      setValue(newValue);
      props.handleChange(newValue);
    }
  };

  return (
    <StepperContainer>
      <StepperButton
        color={props.color}
        hover={props.hover}
        onClick={reduce}
        style={{ left: 0 }}
      >
        <FontAwesomeIcon icon={faMinusCircle} />
      </StepperButton>
      <StepperText>
        {value} {props.text}{value > 1 ? "s" : ""}
      </StepperText>
      <StepperButton
        color={props.color}
        hover={props.hover}
        onClick={increase}
        style={{ right: 0 }}
      >
        <FontAwesomeIcon icon={faPlusCircle} />
      </StepperButton>
    </StepperContainer>
  );
}

const StepperContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 200px;
  height: 30px;
  justify-content: center;
`;

type StepperButtonProps = {
  color: string;
  hover: string;
};

const StepperButton = styled.div`
  cursor: pointer;
  font-size: 30px;
  color: ${(props: StepperButtonProps) => props.color};
  transition: 300ms ease;
  position: absolute;

  &:hover {
    color: ${(props: StepperButtonProps) => props.hover};
  }
`;

const StepperText = styled.div`
  margin: 0 40px;
  user-select: none;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #000000b2;
`;
