import styled from "styled-components";
import { Subject } from "../../../data/databaseTypes";
import { ScreenSubHeading, ScreenSubText } from "../../utilities/ScreenText";
import Spinner from "../../utilities/Spinner";
import SubjectListItem from "../../utilities/SubjectListItem";

type ProductSubjectSectionProps = {
  subjects: false | Subject[];
};

export default function ProductSubjectSection(
  props: ProductSubjectSectionProps
) {
  const sortSubjects = (subjectA: Subject, subjectB: Subject) => {
    if (subjectA.displayName < subjectB.displayName) return -1;
    if (subjectA.displayName > subjectB.displayName) return 1;
    return 0;
  };

  const sortedSubjects = props.subjects && props.subjects.sort(sortSubjects);

  const sectionOne =
    sortedSubjects &&
    sortedSubjects.slice(0, Math.ceil(sortedSubjects.length / 2));

  const sectionTwo =
    sortedSubjects &&
    sortedSubjects.slice(Math.ceil(sortedSubjects.length / 2));

  return (
    <ProductSubjectSectionStyled>
      <ScreenSubHeading>Subjects We Currently Offer Feedback</ScreenSubHeading>
      <SubjectContainer>
        {!sectionOne && !sectionTwo ? (
          <LoadingHolder>
            <Spinner loading={true} size={40} color={"#FFDDAA"} />
          </LoadingHolder>
        ) : (
          <>
            <SubjectList>
              {sectionOne &&
                sectionOne.map((subject: Subject) => (
                  <SubjectListItem
                    subject={subject.subjectCode}
                    displayName={subject.displayName}
                    key={subject.subjectCode}
                  />
                ))}
            </SubjectList>
            <SubjectList>
              {sectionTwo &&
                sectionTwo.map((subject: Subject) => (
                  <SubjectListItem
                    subject={subject.subjectCode}
                    displayName={subject.displayName}
                    key={subject.subjectCode}
                  />
                ))}
            </SubjectList>
          </>
        )}
      </SubjectContainer>
      <Text>...and More Subjects Coming Soon!</Text>
    </ProductSubjectSectionStyled>
  );
}

const ProductSubjectSectionStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 100px 100px 100px;
  gap: 15px;
  cursor: default;

  @media (max-width: 1000px) {
    padding: 20px 60px 100px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px 100px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px 35px 100px 35px;
  }
`;

const SubjectContainer = styled.div`
  display: flex;
  gap: 25px 250px;
  margin: 30px 0;
  padding: 0 10px;
  flex-wrap: wrap;
  min-height: 250px;

  @media (max-width: 1200px) {
    gap: 25px 150px;
  }
`;

const SubjectList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Text = styled(ScreenSubText)`
  align-self: center;
  text-align: center;
`;

const LoadingHolder = styled.div`
  align-self: center;
  margin: 0 50%;
`;
