import styled from "styled-components";
import Dot from "../../utilities/Dot";
import { ScreenHeading, ScreenSubText } from "../../utilities/ScreenText";

export default function AboutUsLanding() {
  return (
    <AboutUsLandingContainer>
      <Content>
        <span>
          <ScreenHeading>
            ABOUT
            <span style={{ whiteSpace: "nowrap" }}>
              {" "}
              US
              <Dot color={"#FFC770"} />
              <Dot color={"#FF9933"} />
              <Dot color={"#FF8270"} />
            </span>
          </ScreenHeading>
        </span>
        <ScreenSubText>
          Clarify Tutoring is a new e-learning platform aiming to deliver high
          quality preparation material and services for Year 12 QCE Students.
        </ScreenSubText>
      </Content>
    </AboutUsLandingContainer>
  );
}

const AboutUsLandingContainer = styled.div`
  width: 100%;
  height: 1050px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px 100px;
  min-height: 600px;

  @media (max-width: 1000px) {
    padding: 20px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px;
  }
`;

const Content = styled.div`
  width: 80%;
  gap: 25px;
  padding-bottom: 200px;
  cursor: default;

  @media (max-width: 800px) {
    width: 95%;
  }
  @media (max-width: 600px) {
    width: 460px;
  }
  @media (max-width: 500px) {
    width: 370px;
    min-width: 338px;
  }
  @media (max-width: 400px) {
    width: 310px;
    min-width: 290px;
  }
  @media (max-width: 350px) {
    width: auto;
    min-width: auto;
    & > span > div {
      font-size: 37px;
    }
  }
  @media (max-width: 320px) {
    & > span > div {
      font-size: 33px;
    }
  }
  @media (max-width: 300px) {
    & > span > div {
      font-size: 30px;
    }
  }
`;
