import styled from "styled-components";

type InfoItemProps = {
  shape: string;
  heading: string;
  subtext: string;
  className: string;
};

export default function InfoItem(props: InfoItemProps) {
  return (
    <InfoItemStyled className={props.className}>
      <img src={props.shape} alt={props.shape} />
      <InfoHeading>{props.heading}</InfoHeading>
      <ItemSubHeading>{props.subtext}</ItemSubHeading>
    </InfoItemStyled>
  );
}

const InfoItemStyled = styled.div`
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Raleway", sans-serif;
  width: 250px;
  gap: 10px;

  & img {
    width: 200px;
    height: 250px;
    margin-bottom: 20px;
    user-select: none;
  }
`;

const InfoHeading = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  margin: 0;
`;

const ItemSubHeading = styled.p`
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  margin: 0;
  text-align: center;
`;
