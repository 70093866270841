import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "./burgerAnimations.css";

type BurgerMenuProps = {
  bgColor: string;
  toggle: boolean;
};

export default function BurgerMenu(props: BurgerMenuProps) {
  const [openState, setOpenState] = useState(2);
  const toggleRef = useRef(props.toggle);

  const changeAnimation = useCallback(() => {
    if (openState === 0) {
      setOpenState(1);
    } else {
      setOpenState(0);
    }
  }, [openState]);

  useEffect(() => {
    if (props.toggle !== toggleRef.current) {
      changeAnimation();
      toggleRef.current = props.toggle;
    }
  }, [props.toggle, changeAnimation]);

  const topAnimationOpen = {
    animation: "top-open 500ms cubic-bezier(0.65, -0.72, 0.27, 1.56) forwards",
  };

  const topAnimationClose = {
    animation: "top-close 500ms cubic-bezier(0.65, -0.72, 0.27, 1.56) forwards",
  };

  const MiddleAnimationOpen = {
    opacity: "0",
  };

  const MiddleAnimationClose = {
    opacity: "1",
  };

  const BottomAnimationOpen = {
    animation:
      "bottom-open 500ms cubic-bezier(0.65, -0.72, 0.27, 1.56) forwards",
  };

  const BottomAnimationClose = {
    animation:
      "bottom-close 500ms cubic-bezier(0.65, -0.72, 0.27, 1.56) forwards",
  };

  return (
    <MenuButton bgColor={props.bgColor} onClick={changeAnimation}>
      <Top
        style={
          !(openState === 2)
            ? openState === 0
              ? topAnimationOpen
              : topAnimationClose
            : {}
        }
      />
      <Middle
        style={
          !(openState === 2)
            ? openState === 0
              ? MiddleAnimationOpen
              : MiddleAnimationClose
            : {}
        }
      />
      <Bottom
        style={
          !(openState === 2)
            ? openState === 0
              ? BottomAnimationOpen
              : BottomAnimationClose
            : {}
        }
      />
    </MenuButton>
  );
}

type MenuButtonProps = {
  bgColor: string;
};

const MenuButton = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${(props: MenuButtonProps) => props.bgColor};
  display: flex;
  cursor: pointer;
  box-shadow: 0px 4px 20px rgba(54, 110, 192, 0.25);
  transition: inherit;
`;

const Middle = styled.div`
  width: 25px;
  height: 4px;
  position: absolute;
  background-color:#272725;
  border-radius: 5px;
  transition: all 500ms cubic-bezier(1, -0.48, 0.22, 1.56);
`;

const Top = styled(Middle)`
  transform: translateY(-8px);
`;

const Bottom = styled(Middle)`
  transform: translateY(8px);
`;
