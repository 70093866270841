import styled from "styled-components";

type DotProps = {
  color: string;
};

export default function Dot(props: DotProps) {
  return <DotComponent color={props.color} />;
}

const DotComponent = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
  background-color: ${(props: DotProps) => props.color};
  @media (max-width: 400px) {
    width: 12px;
    height: 12px;
  }
`;
