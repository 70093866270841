import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import styled from "styled-components";
import ProfileImage from "../../utilities/ProfileImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import SignOutButton from "./SignOutButton";
import ProfileImageSelection from "./ProfileImageSelection";
import {
  closeImageSelector,
  openImageSelector,
} from "../../../redux/accountPopupSlice";

type SidePanelProps = {
  newUserFirstName: string;
  newUserProfileImage: string;
  logout: () => void;
  handleChange: (
    key: "firstName" | "lastName" | "profileImage",
    value: string
  ) => void;
};

export default function SidePanel(props: SidePanelProps) {
  const userData = useSelector((state: RootState) => state.userData);
  const dayJoined = new Date(userData.joined);

  const dispatch = useDispatch();

  const profileImageSelectorOpen = useSelector(
    (state: RootState) => state.accountPopupData.imageSelectorOpen
  );

  const toggleProfileImageSelection = () => {
    if (profileImageSelectorOpen) dispatch(closeImageSelector());
    else dispatch(openImageSelector());
  };

  return (
    <SidePanelstyled>
      <ProfileSection>
        <div>
          <ProfileImage url={props.newUserProfileImage} size={100} />
          <span onClick={toggleProfileImageSelection}>
            <FontAwesomeIcon icon={faPen} />
            <ProfileImageSelection
              shown={profileImageSelectorOpen}
              handleChange={props.handleChange}
            />
          </span>
        </div>
        <p>Hi {props.newUserFirstName}!</p>
        <span>
          <p>Member since {dayjs(dayJoined).format("D MMMM YYYY")}</p>
        </span>
      </ProfileSection>
      <SignOutButtonHolder onClick={props.logout}>
        <SignOutButton />
      </SignOutButtonHolder>
    </SidePanelstyled>
  );
}

const SidePanelstyled = styled.div`
  height: 100%;
  width: 275px;
  background-color: #f3f3f3;
  border-radius: 35px 0 0 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 5px;
  justify-content: space-between;
  overflow: scroll;
  z-index: 3;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1100px) {
    width: 200px;
  }
  @media (max-width: 800px) {
    border-radius: 0;
  }
  @media (max-width: 550px) {
    min-height: 140px;
    height: 20%;
    width: 100%;
    flex-direction: row;
    padding: 0;
    background-color: #fff;
    padding: 30px 30px 0 30px;
    overflow: visible;
  }
`;

const ProfileSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  color: #15375b;

  & > p {
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    margin: 0;
    padding: 0;
    text-align: center;
    pointer-events: none;
    user-select: none;
  }

  & > span {
    padding: 0 20px;
  }

  & > span > p {
    font-family: "Raleway", sans-serif;
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    text-align: center;
    color: #15375b;
  }

  & > div {
    position: relative;
  }

  & > div > span {
    font-size: 12px;
    right: -5px;
    top: 85px;
    position: absolute;
    cursor: pointer;
  }

  @media (max-width: 550px) {
    & > p {
      display: none;
    }
    & > span {
      display: none;
    }
  }
`;

const SignOutButtonHolder = styled.div`
  @media (max-width: 550px) {
    display: none;
  }
`;
