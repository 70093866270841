import styled from "styled-components";
import Cofounders from "../../../assets/images/pictures/cofounders.jpeg";
import SLQ from "../../../assets/images/pictures/slq.jpeg";
import { ScreenSubText } from "../../utilities/ScreenText";

export default function AboutUsPhotoSection() {
  return (
    <AboutUsPhotoSectionStyled>
      <InfoBlock type="left">
        <Image image={Cofounders} />
        <Content>
          <ScreenSubText style={{ maxWidth: "400px" }}>
            Our Co-Founders graduated in 2020, where the odds were stacked
            against us. A lack of resources complimenting the new QCE system and
            uncertainty within the world left us with nothing but each other. We
            wanted to change that by creating free resources and super
            accessible services.
          </ScreenSubText>
        </Content>
      </InfoBlock>
      <InfoBlock type="right">
        <Image image={SLQ} />
        <Content>
          <ScreenSubText style={{ maxWidth: "400px" }}>
            In Year 12, we formed a study group at SLQ, helping each other out
            with content understanding and assignment feedback. It was quite
            apparent that our teamwork outside the classroom led to our
            individual success.
          </ScreenSubText>
        </Content>
      </InfoBlock>
    </AboutUsPhotoSectionStyled>
  );
}

const AboutUsPhotoSectionStyled = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px 100px;
  flex-direction: column;
  gap: 200px;
  cursor: default;

  @media (max-width: 1000px) {
    padding: 20px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px;
  }
`;

type InfoBlockProps = {
  type: "left" | "right";
};

const InfoBlock = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: ${(props: InfoBlockProps) =>
    props.type === "left" ? "row" : "row-reverse"};
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }
  @media (max-width: 600px) {
    height: 600px;
  }
  @media (max-width: 500px) {
    height: 500px;
    padding: 0;
  }
  @media (max-width: 370px) {
  }
`;

type ImageProps = {
  image: string;
};

const Image = styled.div`
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  min-width: 550px;
  min-height: 400px;
  background: url(${(props: ImageProps) => props.image});
  background-size: cover;
  background-position: center 40%;

  @media (max-width: 1100px) {
    min-width: 400px;
    min-height: 300px;
  }
  @media (max-width: 900px) {
    min-height: 500px;
  }
  @media (max-width: 800px) {
    min-height: 400px;
  }
  @media (max-width: 600px) {
    min-height: auto;
    min-width: auto;
    width: 100%;
    height: 350px;
  }
  @media (max-width: 500px) {
    height: 250px;
  }
  @media (max-width: 370px) {
    height: 200px;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 50px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    padding: 100px 50px;
  }
  @media (max-width: 600px) {
    padding: 50px 20px;
    height: 200px;
  }
  @media (max-width: 370px) {
    height: 300px;
  }
`;
