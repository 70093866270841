import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
//firebase
import { emptyUser, UserData } from "../../data/databaseTypes";
import { useFirestore, useUser } from "reactfire";
import { doc, getDoc } from "@firebase/firestore";
import { getAuth, signOut } from "@firebase/auth";
import { userImages } from "../../data/userImages";
//design
import Logo from "../utilities/Logo";
import ProfileImage from "../utilities/ProfileImage";
import BurgerMenu from "./BurgerMenu";
import clarify from "../../assets/images/clarify/clarify_text_main.svg";
import "./body-no-scroll.css";
import { Line } from "../utilities/Line";
//redux
import { useSelector, useDispatch } from "react-redux";
import { removeUser, addUser } from "../../redux/userDataSlice";
import { RootState } from "../../redux/store";

import { openPopup } from "../../redux/accountPopupSlice";
import NavBarDropDown from "./NavBarDropDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { updateProfile } from "firebase/auth";
import Spinner from "../utilities/Spinner";

type NavBarProps = {
  scroll: boolean;
};

export default function NavBar(props: NavBarProps) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [mobileShadeToggle, setMobileShadeToggle] = useState(true);
  const [navDropDownShown, setNavDropDownShown] = useState(false);
  const BurgerRef = useRef(null);

  // firestore
  const { data: user } = useUser();
  const firestore = useFirestore();
  const auth = getAuth();

  // redux
  const userData = useSelector((state: RootState) => state.userData);
  const dispatch = useDispatch();

  // retrieves data if not exists
  useEffect(() => {
    let tempData: UserData = emptyUser;
    if (userData.uid === "-1" && user) {
      getDoc(doc(firestore, "users", user.uid))
        .then((data) => {
          if (data.exists()) {
            tempData = data.data() as UserData;
            tempData.joined = tempData.joined.toDate().valueOf();
            dispatch(addUser(tempData));
          } else {
            dispatch(removeUser());
          }
        })
        .catch((error) => console.log(error));
    }
    // Make auth display name same as user's name
    if (auth.currentUser) {
      updateProfile(auth.currentUser, {
        displayName: `${userData.firstName} ${userData.lastName}`,
        photoURL: "",
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [user, userData, firestore, dispatch, auth.currentUser]);

  // sign out from auth then remove all user data
  const logOut = () => {
    signOut(auth)
      .then(() => {
        dispatch(removeUser());
      })
      .catch((error) => console.log(error));
  };

  // Open mobile navbar
  const openMenu = () => {
    if (menuOpen) {
      setMenuOpen(false);
      document.body.classList.remove("modal-open");
    } else {
      setMenuOpen(true);
      document.body.classList.add("modal-open");
    }
  };

  // Open profile popup component
  const openAccount = () => {
    dispatch(openPopup());
  };

  // simulate a clicked burger
  const clickBurger = () => {
    setMobileShadeToggle(!mobileShadeToggle);
    openMenu();
  };

  return (
    <NavBarStyle style={props.scroll ? scrolledNavbar : {}}>
      {/* Mobile navbar */}
      <MobileNavBarWrapper show={menuOpen ? "flex" : "hidden"}>
        <MobileNavShade
          show={menuOpen ? "visible" : "hidden"}
          onClick={clickBurger}
        />
        <MobileNav show={menuOpen ? "0px" : "-100%"}>
          {!user ? (
            <img src={clarify} alt="Clarify" />
          ) : user && userData.uid === "-1" ? (
            <LoadingProfileContainer size={props.scroll ? 60 : 86}>
              <Spinner color={"#709bbd"} loading={true} />
            </LoadingProfileContainer>
          ) : (
            <div style={{ pointerEvents: "all" }}>
              <ProfileImage
                onClick={openAccount}
                size={86}
                url={
                  userData.profileImage ? userData.profileImage : userImages[0]
                }
              />
            </div>
          )}
          <Line color="#747474" />
          <MobileLinkBox>
            <StyledLink to="/">Home</StyledLink>
            <StyledLink to="/services">Services</StyledLink>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginLeft: "20px",
                alignItems: "flex-start",
              }}
            >
              <StyledLink to="/clarifychat">Clarify Chat</StyledLink>
              <StyledLink to="/tutoring">Tutoring</StyledLink>
              <StyledLink to="/assignments">Assignments</StyledLink>
            </div>
            <StyledLink to="/pricing">Pricing</StyledLink>
            <StyledLink to="/aboutus">About Us</StyledLink>
          </MobileLinkBox>

          <MobileLinkBox>
            {!user ? (
              <StyledLink to="/signin">Log in</StyledLink>
            ) : (
              <SignOut onClick={logOut}>Sign out</SignOut>
            )}
          </MobileLinkBox>
        </MobileNav>
      </MobileNavBarWrapper>
      {/* desktop nav bar */}
      <NavSection>
        <DropDownLinkContainer
          onMouseEnter={() => setNavDropDownShown(true)}
          onMouseLeave={() => setNavDropDownShown(false)}
        >
          <div style={{ width: "80%" }}>
            <StyledLink to="/services" style={{ zIndex: "1" }}>
              Services
            </StyledLink>
          </div>
          {/* arrow container */}
          <span style={navDropDownShown ? { transform: "rotate(180deg)" } : {}}>
            <FontAwesomeIcon icon={faChevronDown} />
          </span>
          {/* hover area */}
          <div
            onClick={() => setNavDropDownShown(!navDropDownShown)}
            style={{
              position: "absolute",
              top: "0",
              width: "112px",
              height: "36px",
              cursor: "pointer",
              zIndex: "0",
              border: "solid transparent 1px",
              pointerEvents: "all",
            }}
          />
          <NavBarDropDown shown={navDropDownShown} />
        </DropDownLinkContainer>
        <StyledLink to="/pricing">Pricing</StyledLink>
        <div></div>
      </NavSection>
      <LogoLink to="/" style={{ pointerEvents: "all" }}>
        <Logo isText={props.scroll ? false : true} />
      </LogoLink>
      <NavSection>
        <div></div>
        <StyledLink to="/aboutus">About Us</StyledLink>
        <div
          style={{
            width: "86px",
            display: "flex",
            justifyContent: "center",
            pointerEvents: "all",
          }}
        >
          {!user ? (
            <StyledLink to="/signin">Log in</StyledLink>
          ) : user && userData.uid === "-1" ? (
            <LoadingProfileContainer size={props.scroll ? 60 : 86}>
              <Spinner
                color={"#709bbd"}
                loading={true}
                size={props.scroll ? 30 : 45}
              />
            </LoadingProfileContainer>
          ) : (
            <ProfileImage
              onClick={openAccount}
              size={props.scroll ? 60 : 86}
              url={
                userData.profileImage ? userData.profileImage : userImages[0]
              }
            />
          )}
        </div>
      </NavSection>

      <BurgerReferenceElm ref={BurgerRef}>
        <BurgerWrapper onClick={openMenu}>
          <BurgerMenu
            bgColor={props.scroll || menuOpen ? "#FFF" : "transparent"}
            toggle={mobileShadeToggle}
          />
        </BurgerWrapper>
      </BurgerReferenceElm>
    </NavBarStyle>
  );
}

const NavBarStyle = styled.div`
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  width: 100%;
  height: 170px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
  color: #272725;
  max-height: 200px;
  transition: 300ms linear;
  position: fixed;
  pointer-events: none;
  top: 0;
  z-index: 50;
  padding: 0 100px;

  @media (max-width: 1000px) {
    padding: 0 60px;
  }
  @media (max-width: 900px) {
    padding: 0 50px;
  }
  @media (max-width: 500px) {
    padding: 0 35px;
  }
`;

const NavSection = styled.div`
  background-color: rgba(0, 0, 0, 0);
  flex: 1 0 0;
  display: flex;
  padding: 0;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 700px) {
    display: none;
  }
`;

// Mobile version of navbar

type MobileNavShadeOrWrapperProps = {
  show: string;
};

type MobileNavProps = {
  show: string;
};

const MobileNavBarWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  visibility: ${(props: MobileNavShadeOrWrapperProps) => props.show};
`;

const MobileNavShade = styled.div`
  width: 100%;
  height: 100%;
  visibility: ${(props: MobileNavShadeOrWrapperProps) => props.show};
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  transition: 100ms ease;
`;

const MobileNav = styled.div`
  background-color: #f8f8f8;
  width: 75%;
  min-height: 100vh;
  transform: translateX(${(props: MobileNavProps) => props.show});
  transition: 300ms ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  padding: 35px;

  & img {
    width: 120px;
  }
`;

const MobileLinkBox = styled.div`
  margin-top: 15px;
  margin-bottom: 25px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  pointer-events: all;
`;

const BurgerReferenceElm = styled.div`
  right: 15px;
  position: absolute;
  pointer-events: all;
`;

const BurgerWrapper = styled.div`
  display: none;
  border-radius: 50%;
  @media (max-width: 700px) {
    display: flex;
  }
`;

// Elements of Navbar

const DropDownLinkContainer = styled.div`
  position: relative;
  width: 112px;

  & > span {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 25%;
    font-size: 14px;
    transition: ease 300ms;
    transform-origin: center 45%;
    margin-left: 10px;
    color: #272725b3;
  }
`;

const StyledLink = styled(Link)`
  color: #272725b3;
  font-family: "Raleway", sans-serif;
  font-size: 22px;
  text-decoration: none;
  margin: 0;
  user-select: none;
  position: relative;
  pointer-events: all;

  &::after {
    content: "";
    width: 0;
    margin-top: 1px;
    height: 2px;
    border-radius: 20px;
    display: block;
    transition: width ease 200ms;
    background: #272725b3;
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }
`;

const SignOut = styled.p`
  color: #ff5454;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  text-decoration: none;
  margin: 0;
  cursor: pointer;
`;

const LogoLink = styled(Link)`
  color: rgba(21, 55, 91, 0.5);
  text-decoration: none;
  margin: 0;
  margin-top: 5px;
`;

type StyledProfileImageProps = {
  size: number;
};

const LoadingProfileContainer = styled.div`
  border-radius: 100%;
  min-width: ${(props: StyledProfileImageProps) => `${props.size}px`};
  min-height: ${(props: StyledProfileImageProps) => `${props.size}px`};
  width: ${(props: StyledProfileImageProps) => `${props.size}px`};
  height: ${(props: StyledProfileImageProps) => `${props.size}px`};
  border: 5px solid #709bbdcc;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #6bcfbf 300%);
  backdrop-filter: blur(4px);

  display: flex;
  justify-content: center;
  align-items: center;
`;

// dynamic styles
const scrolledNavbar = {
  backgroundColor: "rgba(255, 255, 255, 0.99)",
  boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.25)",
  height: "100px",
};
