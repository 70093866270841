import styled from "styled-components";
import { ScreenSubHeading, ScreenSubText } from "../../utilities/ScreenText";

export default function ChatInfoSection() {
  return (
    <ChatInfoContainer>
      <ScreenSubHeading>
        We all have <span style={{ color: "#F46A57" }}>questions</span>. So why
        not go ahead and <span style={{ color: "#F46A57" }}>ask</span> them?
      </ScreenSubHeading>
      <ScreenSubText>
        Our flagship Clarify Chat is a{" "}
        <span style={{ textDecoration: "underline" }}>
          one-stop destination
        </span>{" "}
        to talk to your favourite tutors. If you're hungry for answers, we're
        ready to hand you a plateful so you can be well-satisfied.
      </ScreenSubText>
      <ScreenSubText>
        Sometimes, it's the{" "}
        <span style={{ textDecoration: "underline" }}>
          answer to a single question
        </span>{" "}
        that helps everything click in place, like the last piece of a jigsaw
        puzzle. From experience, there've been countless times where
        everything's suddenly 'clicked' by having simple questions clarified.
        However, our teachers are too hard to reach, with slow email replies and
        weekends always getting in the way.
      </ScreenSubText>
    </ChatInfoContainer>
  );
}

const ChatInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 100px 100px 100px;
  gap: 25px;
  cursor: default;
  
  @media (max-width: 1000px) {
    padding: 20px 60px 100px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px 100px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px 35px 100px 35px;
  }
`;
