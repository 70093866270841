import { useDispatch } from "react-redux";
import styled from "styled-components";
import { openError } from "../../../redux/errorPopupSlice";
import Button from "../../utilities/button/Button";
import { ScreenSubText } from "../../utilities/ScreenText";

export default function ChatWithUsNowSection() {
  const dispatch = useDispatch();
  return (
    <Wrapper>
      <Content>
        <ScreenSubText>Running short on time?</ScreenSubText>
        <ScreenSubText>
          If you require urgent feedback, please contact us about our{" "}
          <b>Rapid Assignment Tweak (RAT).</b> We'll polish up your work in{" "}
          <b>24 hours!</b>
        </ScreenSubText>
        <Button
          name={"Chat with us now!"}
          color={"#FFC770"}
          hover={"#e9b768"}
          textColor="#000"
          width={"220px"}
          onClick={() =>
            dispatch(
              openError(
                "Thanks for your interest! Currently, we are working on our chat feature to ensure it operates smoothly for new users. In the meantime, contact us on messenger through the messenger button on the bottom right"
              )
            )
          }
        />
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 100px 100px 100px;
  cursor: default;

  @media (max-width: 1000px) {
    padding: 20px 60px 100px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px 100px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px 35px 100px 35px;
  }
`;

const Content = styled.div`
  display: flex;
  width: 45%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;

  & p {
    text-align: center;
  }
  @media (max-width: 900px) {
    width: 85%;
  }
  @media (max-width: 450px) {
    width: 100%;
    & p {
      font-size: 18px;
    }
  }
`;
