import styled from "styled-components";

type LineProps = {
    color: string;
};

export const Line = styled.div`
  width: 100%;
  min-height: 2px;
  border-radius: 5px;
  background-color: ${(props: LineProps) => props.color} ;
`;

