import { getStorage, ref } from "firebase/storage";
import { useStorageDownloadURL } from "reactfire";
import styled from "styled-components";

type AssignmentDownloadProps = {
  filePath: string;
};

export default function AssignmentDownload(props: AssignmentDownloadProps) {
  const storage = getStorage();
  const assignmentRef = ref(storage, `${props.filePath}`);

  const { data: downloadURL } = useStorageDownloadURL(assignmentRef);
  return (
    <Link href={downloadURL} target="_blank" rel="noreferrer">
      Download
    </Link>
  );
}

const Link = styled.a`
  max-width: 80px;
`;
