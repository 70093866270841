export const errorCodeToText = (errorCode: string) => {
  switch (errorCode) {
    case "auth/email-already-in-use": {
      return "Email is already in use";
    }
    case "auth/invalid-email": {
      return "The email provided is invalid";
    }
    case "auth/user-not-found": {
      return "User does not exist";
    }
    case "auth/operation-not-allowed": {
      return "The operation is not allowed. Try again";
    }
    case "auth/weak-password": {
      return "The password is too weak";
    }
    case "auth/user-disabled": {
      return "Account is disabled";
    }
    case "auth/wrong-password": {
      return "Incorrect Password";
    }
    default: {
      return "";
    }
  }
};
