import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ChatroomData } from "../../../data/databaseTypes";
import { closeChat } from "../../../redux/chatDataSlice";
import { RootState } from "../../../redux/store";
import ChatUserButton from "./ChatUserButton";
import QuickChatButton from "./QuickChatButton";

type ChatUserPanelProps = {
  chatrooms: ChatroomData[];
};

export default function ChatUserPanel(props: ChatUserPanelProps) {
  const selectedChatroom = useSelector(
    (state: RootState) => state.chatData.selectedChatroom
  );
  const user = useSelector((state: RootState) => state.userData);

  const unreadMessages = useSelector(
    (state: RootState) => state.chatData.unreads
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const findTutors = () => {
    history.push("/tutoring");
    dispatch(closeChat());
  };

  return (
    <SidePanelBackground>
      <QuickChatButton />
      <MessageWrapper>
        {props.chatrooms &&
          props.chatrooms.map((chatroom, index) => (
            <ChatUserButton
              key={chatroom.id}
              index={index}
              chatroom={chatroom}
              user={user}
              unreadMessages={
                unreadMessages.find((u) => u.chatroomId === chatroom.id)
                  ?.unreads || 0
              }
              isSelected={selectedChatroom === chatroom.id}
            />
          ))}
      </MessageWrapper>
      <FindTutorsButton onClick={findTutors}>Find Tutors</FindTutorsButton>
    </SidePanelBackground>
  );
}

const SidePanelBackground = styled.div`
  width: 300px;
  border-radius: 35px 0 0 35px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const MessageWrapper = styled.div`
  display: flex;
  margin: 0 -15px 0 -20px;
  padding: 0 15px 0 20px;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b8bcc8;
    border-radius: 5px;
  }
`;

const FindTutorsButton = styled.div`
  border-radius: 10px;
  margin-top: 10px;
  color: rgba(21, 55, 91, 0.4);
  background-color: #eeeeee;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  font-style: italic;
  font-family: "Cabin", sans-serif;
  cursor: pointer;
  transition: 0.2s ease;
  &:hover {
    background-color: #e6e6e6;
  }
`;
