import styled from "styled-components";
import { DownloadLink } from "../../../data/databaseTypes";
import Spinner from "../../utilities/Spinner";

type ProductDownloadLinkSectionProps = {
  downloadable: false | DownloadLink[];
};

export default function ProductDownloadLinkSection(
  props: ProductDownloadLinkSectionProps
) {
  return (
    <ProductDownloadLinkSectionStyled>
      {props.downloadable ? (
        props.downloadable.map((value: DownloadLink) => (
          <Link
            key={value.name}
            href={value.link}
            download="assignment"
            target="_blank"
          >
            {value.name}
          </Link>
        ))
      ) : (
        <Spinner loading={true} size={40} color={"#FFDDAA"} />
      )}
    </ProductDownloadLinkSectionStyled>
  );
}

const ProductDownloadLinkSectionStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  padding: 50px 100px;
  min-height: 142px;
  cursor: default;

  @media (max-width: 1000px) {
    padding: 20px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px;
  }
`;

const Link = styled.a`
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  color: #6aa6cc;
  cursor: pointer;

  &:visited {
    color: #5e96b9;
  }
  &:hover {
    text-decoration-line: underline;
  }
  @media (max-width: 450px) {
    font-size: 18px;
  }
`;
