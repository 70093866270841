import styled from "styled-components";
import AboutUsLanding from "../components/screen_components/about_us/AboutUsLanding";
import Footer from "../components/footer/Footer";
import NavBar from "../components/navbar/NavBar";
import WavesTop from "../assets/images/background/sunset-waves-top.svg";
import WavesBottom from "../assets/images/background/sunset-waves-bottom.svg";
import AboutUsPhotoSection from "../components/screen_components/about_us/AboutUsPhotoSection";
import AboutUsFinalSection from "../components/screen_components/about_us/AboutUsFinalSection";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useEffect } from "react";
// import ChatBubble from "../components/utilities/chat_bubble/ChatBubble";

type AboutUsScreenProps = {
  isScroll: boolean;
  scrollerContainer: OverlayScrollbarsComponent | null;
};

export default function AboutUsScreen(props: AboutUsScreenProps) {
  useEffect(() => {
    props.scrollerContainer?.osInstance()?.scroll(0, 0);
  }, [props.scrollerContainer]);
  return (
    <PageContainer>
      <Background>
        <NavBar scroll={props.isScroll} />
        <div>
          <AboutUsLanding />
          <AboutUsPhotoSection />
          <AboutUsFinalSection />
        </div>
        {/* <ChatBubble /> */}
        <Footer background={"#FFF2DE"} />
      </Background>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100%;
  background-color: #fff;
  position: relative;
`;

const Background = styled.div`
  width: 100%;
  background-color: #fff;
  background-image: url(${WavesTop}), url(${WavesBottom});
  background-repeat: repeat-x;
  background-position: center top, center 95%;
  display: flex;
  flex-direction: column;
`;
