import { createSlice } from "@reduxjs/toolkit";

type assignmentUploadSliceType = {
  open: boolean;
};

const initialState: assignmentUploadSliceType = {
  open: false,
};

export const assignmentUploadSlice = createSlice({
  name: "assignmentUploadData",
  initialState,
  reducers: {
    openPopup: (state) => {
      document.body.classList.add("modal-open");
      state.open = true;
    },
    closePopup: (state) => {
      document.body.classList.remove("modal-open");
      state.open = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { openPopup, closePopup } = assignmentUploadSlice.actions;

export default assignmentUploadSlice.reducer;
