import styled from "styled-components";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

type DropdownProps = {
  options: string[];
  handleType: (value: any) => void;
};

export default function Dropdown(props: DropdownProps) {
  const [open, setOpen] = useState(false);
  const [selected, setSetSelected] = useState(props.options[0]);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const setNewValue = (option: string) => {
    if (selected !== option) {
      setSetSelected(option);
      props.handleType(option);
      toggleOpen();
    }
  };

  return (
    <DropdownContainer>
      <p>Role</p>
      <DropdownButton onClick={toggleOpen}>
        {selected.length > 1
          ? selected.charAt(0).toUpperCase() + selected.slice(1)
          : selected}
        <span style={open ? { transform: "rotate(180deg)" } : {}}>
          <FontAwesomeIcon icon={faChevronDown} />
        </span>
      </DropdownButton>
      <DropdownContent
        style={open ? { visibility: "visible", opacity: "1" } : {}}
      >
        {props.options.map((option: string) => (
          <ContentItem onClick={() => setNewValue(option)} key={option}>
            {option.length > 1
              ? option.charAt(0).toUpperCase() + option.slice(1)
              : option}
          </ContentItem>
        ))}
      </DropdownContent>
    </DropdownContainer>
  );
}

const DropdownContainer = styled.div`
  position: relative;

  & > p {
    position: absolute;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    color: #707a85;
    margin: 0;
    top: 4px;
    left: 12px;
    font-size: 10px;
    pointer-events: none;
    user-select: none;
  }
`;

const DropdownButton = styled.div`
  background-color: #f5f6fa;
  padding: 16px 16px 10px 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  min-width: 100px;
  font-family: "Cabin", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #5d646c;
  transition: ease 300ms;
  height: 47px;
  width: 125px;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > span {
    display: inline-block;
    font-size: 14px;
    transition: ease 300ms;
    transform-origin: center 45%;
  }
`;

const DropdownContent = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 50px;
  background-color: #f9f9f9;
  min-width: 125px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
  transition: opacity ease 200ms;
`;

const ContentItem = styled.div`
  cursor: pointer;
  height: 30px;
  user-select: none;
  font-family: "Cabin", sans-serif;
  color: #5d646c;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color ease 100ms;

  &:hover {
    background-color: #e4e8ef;
  }
`;
