import { ChangeEventHandler, useEffect, useState } from "react";
import styled from "styled-components";
import ToolTip from "./TextboxToolTip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { isValidEmail } from "../../../scripts/isValidEmail";

type TextboxProps = {
  id: string;
  name?: "fname" | "lname" | "email" | "password" | "confirm-password";
  type?: "password" | "input" | "text" | "email";

  value?: string;
  autocomplete?:
    | "username"
    | "given-name"
    | "family-name"
    | "new-password"
    | "current-password";
  placeholder: string;
  info?: string;
  required?: boolean;
  correctPassword?: string;

  width?: string;
  color?: string;
  outline?: string;
  noEye?: boolean;

  onChange?: ChangeEventHandler<HTMLInputElement>;
  handlingValidation?: boolean;
};

export default function Textbox(props: TextboxProps) {
  const [isShowingPassword, setIsShowingPassword] = useState<boolean>(false);
  const [type, setType] = useState<
    "default" | "animated" | "invalid" | "required"
  >(props.value ? "animated" : "default");

  const toggleShowPassword = () => {
    setIsShowingPassword(!isShowingPassword);
  };

  const checkAndRemoveAnimate = () => {
    if (!props.value) {
      setType("default");
    }
  };

  useEffect(() => {
    setType(props.value ? "animated" : "default");
  }, [props.value]);

  useEffect(() => {
    if (props.handlingValidation) {
      if (props.required) {
        if (!props.value) {
          setType("required");
          return;
        }
        if (props.type === "email") {
          if (!isValidEmail(props.value)) {
            setType("invalid");
          }
        }
        if (props.name && props.name === "confirm-password") {
          if (props.value !== props.correctPassword) {
            setType("invalid");
          }
        }
      }
    }
  }, [
    props.handlingValidation,
    props.required,
    props.type,
    props.value,
    props.name,
    props.correctPassword,
  ]);

  return (
    <InputContainer
      width={props.width ? props.width : "100%"}
      className="inputController"
    >
      <TextboxStyled
        color={props.color}
        outline={props.outline}
        value={props.value ? props.value : ""}
        id={props.id}
        name={props.name ? props.name : ""}
        type={!props.type ? "text" : !isShowingPassword ? props.type : "text"}
        required={props.required}
        autoComplete={props.autocomplete ? props.autocomplete : ""}
        onChange={props.onChange}
        onFocus={() => setType("animated")}
        onBlur={checkAndRemoveAnimate}
        error={type === "invalid" || type === "required"}
      />
      <PlaceHolder
        animation={type}
        error={type === "invalid" || type === "required"}
      >
        <p>
          {type === "required"
            ? "Required"
            : type === "invalid"
            ? `Invalid ${
                props.type
                  ? props.type[0].toUpperCase() + props.type.substring(1)
                  : ""
              }`
            : props.placeholder}
        </p>
        <QuestionCircle animation={type}>
          {props.info && <ToolTip text={props.info} />}
        </QuestionCircle>
      </PlaceHolder>

      <Eye
        onClick={toggleShowPassword}
        style={
          !props.noEye && props.type && props.type === "password"
            ? { display: "block" }
            : {}
        }
      >
        <FontAwesomeIcon icon={isShowingPassword ? faEye : faEyeSlash} />
      </Eye>
    </InputContainer>
  );
}

type InputContainerProps = {
  width: string;
};

const InputContainer = styled.div`
  position: relative;
  width: ${(props: InputContainerProps) => props.width};
  margin: 0;
`;

type TextboxStyledProps = {
  color?: string;
  outline?: string;
  error: boolean;
};

const TextboxStyled = styled.input`
  border: none;
  padding: 17px 12px 8px 12px;
  width: 100%;
  background-color: ${(props: TextboxStyledProps) =>
    props.error ? "#FFD9D9" : props.color ? props.color : "#f5f6fa"};
  outline-color: ${(props: TextboxStyledProps) =>
    props.outline ? props.outline : "#e4e8ef"};
  outline-width: 3px;
  border-radius: 10px;
  font-size: 18px;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  color: #5d646c;
  transition: all 0.3s ease;
`;

type PlaceHolderProps = {
  animation: "default" | "animated" | "invalid" | "required";
  error: boolean;
};

const PlaceHolder = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  transition: all 0.15s ease;
  pointer-events: none;
  user-select: none;

  color: ${(props: PlaceHolderProps) => (props.error ? "#714E4E" : "#707a85")};

  font-size: ${(props: PlaceHolderProps) =>
    props.animation === "animated" || props.animation === "invalid"
      ? "10px"
      : "16px"};
  top: ${(props: PlaceHolderProps) =>
    props.animation === "animated" || props.animation === "invalid"
      ? "4px"
      : "14px"};
  left: ${(props: PlaceHolderProps) =>
    props.animation === "animated" || props.animation === "invalid"
      ? "12px"
      : "20px"};

  & p {
    margin: 0;
    padding: 0;
    display: inline;
  }
`;

type QuestionCircleProps = {
  animation: "default" | "animated" | "invalid" | "required";
};

const QuestionCircle = styled.div`
  margin: 0;
  margin-left: 3px;
  padding: 0;
  font-size: 8px;
  display: inline-block;
  cursor: pointer;

  visibility: ${(props: QuestionCircleProps) =>
    props.animation === "animated" ? "visible" : "hidden"};
  pointer-events: ${(props: QuestionCircleProps) =>
    props.animation === "animated" ? "all" : "none"};
`;

const Eye = styled.div`
  display: none;
  position: absolute;
  right: 15px;
  font-size: 14px;
  top: 35%;
  color: #5d646c;
  cursor: pointer;
  width: 17px;
  text-align: center;
`;
