import styled from "styled-components";
import { ScreenSubHeading, ScreenSubText } from "../../utilities/ScreenText";

import Product from "../../utilities/product/Product";
import { SellableProduct } from "../../../data/databaseTypes";
import { useDispatch } from "react-redux";
import { openError } from "../../../redux/errorPopupSlice";

type TutorProductSectionProps = {
  sellable: false | SellableProduct[];
};

export default function TutorProductSection(props: TutorProductSectionProps) {
  const dispatch = useDispatch();
  return (
    <TutorProductSectionStyled>
      <ScreenSubHeading>Pricing and Plans</ScreenSubHeading>
      <ScreenSubText>
        With Clarify, you aren't limited to weekly classes. If you're looking to
        choose the frequency you want, look into our Flexible option!
      </ScreenSubText>
      <TutorProductContainer>
        <Product
          product={props.sellable && props.sellable[0]}
          buttonColor={"#FFB6B6"}
          buttonHover={"#faa8a8"}
          bgColor={"#FFE9E9"}
          minHeight="690px"
          onClick={() =>
            dispatch(
              openError(
                "Thanks for your interest! Currently, we are working on getting website payments setup. In the meantime, contact us on messenger through the messenger button on the bottom right"
              )
            )
          }
        />
      </TutorProductContainer>
    </TutorProductSectionStyled>
  );
}

const TutorProductSectionStyled = styled.div`
  width: 100%;
  padding: 20px 100px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  cursor: default;

  @media (max-width: 1000px) {
    padding: 20px 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 50px;
  }
  @media (max-width: 500px) {
    padding: 35px;
  }
`;

const TutorProductContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 200px;
  padding: 60px 100px 120px 100px;
  margin-top: 20px;

  @media (max-width: 1150px) {
    gap: 150px;
  }
  @media (max-width: 1100px) {
    gap: 100px;
  }
  @media (max-width: 1050px) {
    gap: 75px;
    padding: 60px 50px;
  }
  @media (max-width: 500px) {
    padding: 60px 10px;
  }
  @media (max-width: 370px) {
    padding: 60px 0;
  }
`;
