import styled from "styled-components";
import Footer from "../components/footer/Footer";
import NavBar from "../components/navbar/NavBar";
// import ChatBubble from "../components/utilities/chat_bubble/ChatBubble";
import Waves from "../assets/images/waves/light_blue_waves.svg";
import ServicesLanding from "../components/screen_components/services/ServicesLanding";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { collection, query, where } from "firebase/firestore";
import { PageData, Subject } from "../data/databaseTypes";
import ServicesSubjectSection from "../components/screen_components/services/ServicesSubjectSection";
import ServicesSignUpFreeSection from "../components/screen_components/services/ServicesSignUpFreeSection";
import ServicesOptionsSection from "../components/screen_components/services/ServicesOptionsSection";
import ServicesInfoSection from "../components/screen_components/services/ServicesInfoSection";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useEffect } from "react";

type ServicesScreenProps = {
  isScroll: boolean;
  scrollerContainer: OverlayScrollbarsComponent | null;
};

export default function ServicesScreen(props: ServicesScreenProps) {

  useEffect(() => {
    props.scrollerContainer?.osInstance()?.scroll(0, 0);
  }, [props.scrollerContainer]);
  
  const firestore = useFirestore();
  const productsInfoCollection = collection(firestore, "products_info");

  const servicesQuery = query(
    productsInfoCollection,
    where("page", "==", "services")
  );
  const { data: dbProductInfo } = useFirestoreCollectionData(servicesQuery);

  const servicesInfo = dbProductInfo as PageData[];

  const servicesSubject: false | Subject[] =
    servicesInfo && servicesInfo.length === 1 && servicesInfo[0].subjects;

  return (
    <PageContainer>
      <Background>
        <NavBar scroll={props.isScroll} />
        <div>
          <ServicesLanding />
          <ServicesInfoSection />
          <ServicesSubjectSection subjects={servicesSubject} />
          <ServicesOptionsSection />
          <ServicesSignUpFreeSection />
        </div>
        {/* <ChatBubble /> */}
        <Footer background={"FFF"} />
      </Background>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100%;
  position: relative;
  background-color: #fff;
`;

const Background = styled.div`
  background-color: #fff;
  background-image: url(${Waves});
  background-repeat: repeat-x;
  background-position: center top;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
